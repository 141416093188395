import { combineReducers } from "redux";
import authReducer from "./authReducer";
import modalReducer from "./modalReducer";
import loadingReducer from "./loadingReducer";
import titleReducer from "./titleReducer";
import snackbarReducer from "./snackbarReducer";
import formReducer from "./formReducer";
import unsavedAlertReducer from "./unsavedAlertReducer";
import selectedCountryReducer from "./selectedCountryReducer";

export default combineReducers({
  authDetails: authReducer,
  modal: modalReducer,
  loading: loadingReducer,
  title: titleReducer,
  snackbar: snackbarReducer,
  form: formReducer,
  unsavedAlert: unsavedAlertReducer,
  selectedCountry: selectedCountryReducer,
});
