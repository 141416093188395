import React from "react";

export const DateField = ({ value, ...restProps }) => {
  if (value === "" || value === undefined) {
    return <span {...restProps}>Invalid Date</span>;
  }
  // if the date is a date string
  if (value.length === 8) {
    const date = new Date(
      parseInt(value.substring(0, 4)),
      parseInt(value.substring(4, 6)) - 1, // month is 0-based in JS
      parseInt(value.substring(6, 8))
    );
    return (
      <span {...restProps}>
        {date.toLocaleDateString()}
        <TodayIconField date={date} dateFormat="DATE_ONLY" />
      </span>
    );
  }

  if (new Date(value).toString !== "Invalid Date") {
    const date = new Date(value);
    return (
      <span {...restProps}>
        {date.toLocaleString()}
        <TodayIconField date={date} dateFormat="DATE_AND_TIME" />
      </span>
    );
  }

  const date = parseInt(value, 10);
  const dateTime = new Date(date);

  if (date === 0) {
    return <span {...restProps}>NA</span>;
  }
  return (
    <span {...restProps}>
      {dateTime.toLocaleString()}
      <TodayIconField date={date} dateFormat="DATE_AND_TIME" />
    </span>
  );
};

const TodayIconField = ({ date, dateFormat }) => {
  if (dateFormat === "DATE_ONLY") {
    if (date === "") {
      return null;
    }
    try {
      if (isToday(date)) {
        return (
          <span
            style={{
              color: "red",
              fontSize: "0.7em",
              marginLeft: "5px",
            }}
          >
            TODAY
          </span>
        );
      } else {
        return null;
      }
    } catch (err) {
      return <span>~e~</span>;
    }
  } else if (dateFormat === "DATE_AND_TIME") {
    if (isToday(new Date(date))) {
      // return <TodayIcon style={todayIconStyle}/>;
      return (
        <span
          style={{
            color: "red",
            fontSize: "0.7em",
            marginLeft: "5px",
          }}
        >
          TODAY
        </span>
      );
    } else {
      return null;
    }
  } else {
    return <span>~</span>;
  }
};

const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};
