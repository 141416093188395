import React from "react";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { useSelector } from "react-redux";
import { hideModal } from "../../redux/dispatchers";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "50%",
    padding: 15,
  },
}));

export const ConfirmationModal = () => {
  const classes = useStyles();
  const {
    type,
    visible,
    title,
    content,
    confirm,
    cancel,
    onConfirm,
    onCancel,
  } = useSelector((state) => state.modal);

  return (
    <Dialog
      maxWidth="xs"
      open={visible && type === "confirmationModal"}
      onClose={onCancel}
      classes={{ paper: classes.modal }}
    >
      <Card elevation={0}>
        <div style={{ padding: 10 }}>
          <Typography variant="h6">{title}</Typography>
        </div>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {content}
          </Typography>
        </CardContent>
        <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
          {cancel ? (
            <Button
              className={classes.closeButton}
              variant="outlined"
              color="secondary"
              onClick={
                onCancel
                  ? onCancel
                  : () => {
                      hideModal();
                    }
              }
            >
              {cancel}
            </Button>
          ) : null}
          {confirm ? (
            <Button
              className={classes.confirmButton}
              variant="outlined"
              color="primary"
              onClick={
                onConfirm
                  ? () => {
                      onConfirm();
                    }
                  : () => {
                      hideModal();
                    }
              }
            >
              {confirm}
            </Button>
          ) : null}
        </CardActions>
      </Card>
    </Dialog>
  );
};
