import { MenuItem, TextField } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom"

export const FormModeDropdown = () => {
  const location = useLocation();
  const history = useHistory();

  const modeFromSearchParams = new URLSearchParams(location.search).get("mode") ?? 'view';

  return <TextField size="small" value={modeFromSearchParams} onChange={(e) => {
    const url = new URL(window.location.href);
    url.searchParams.set("mode", e.target.value);
    history.replace(url.pathname + url.search);
  }} select variant="outlined">
    <MenuItem value={"edit"}>Edit Mode</MenuItem>
    <MenuItem value={"view"}>View Mode</MenuItem>
  </TextField>
}