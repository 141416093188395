import { Button, Dialog, DialogTitle } from "@material-ui/core";
import * as React from "react";
import DenseTable from "./simple-table";

const SimpleDialog = (props) => {
  const { open, handleClose, drugData, action, handleSave, handleSubmit } =
    props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Prescription</DialogTitle>
      <DenseTable drugData={drugData} handleClose />
      {action === "close" && (
        <Button
          onClick={handleClose}
          style={{ backgroundColor: "#ff6666", color: "black" }}
        >
          Close
        </Button>
      )}
      {action === "submit" && (
        <Button
          onClick={handleSubmit}
          style={{ backgroundColor: "#99ff99", color: "black" }}
        >
          Confirm and submit
        </Button>
      )}
      {action === "save" && (
        <Button
          onClick={handleSave}
          style={{ backgroundColor: "#99ff99", color: "black" }}
        >
          Confirm and save
        </Button>
      )}
    </Dialog>
  );
};

export default SimpleDialog;
