import { SNACKBAR_SUCCESS, SNACKBAR_ERROR, HIDE_SNACKBAR } from "../types";

const initialState = {
  open: false,
  message: "",
  alertType: "success",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_SUCCESS:
      return {
        open: true,
        message: typeof action.payload.message === "string" ? action.payload.message : "Success",
        alertType: "success",
      };
    case SNACKBAR_ERROR:
      return {
        open: true,
        message: typeof action.payload.message === "string" ? action.payload.message : "Something went wrong.",
        alertType: "error",
      };
    case HIDE_SNACKBAR:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export default reducer;
