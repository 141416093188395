import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { ROLE_CHOICES } from "../../constants/choices";
import { DynamicVisibilityComponent } from "../common/dynamic-visibility-component";
import { getOne, create, update, queryList } from "../../services/api/common";
import { getResetPasswordLink } from "../../services/api/auth";
import Button from "@material-ui/core/Button";
import { Redirect, useHistory } from "react-router-dom";
import {
  setTitle,
  showSuccessSnackbar,
  showErrorSnackbar, showModal,
  hideModal
} from "../../redux/dispatchers";
import CustomTextField from "../common/custom-text-field";
import { authenticate, logoutUser } from "../../services/logic/auth";
import { useSelector } from "react-redux";
import { Grid, makeStyles, MenuItem, TextField, Box } from "@material-ui/core";
import { validateFields } from "../../config/validation/yup";
import { usersValidationSchema } from "../../config/validation/schemas";
import FormWrapper from "../common/form-wrapper";
import TransferOwnershipDialog from "./transfer-ownership-dialog";
import { IconButton, ListItemButton, Popover, List, Typography, InputAdornment } from "@mui/material";
import { Settings } from "@material-ui/icons";
import { Subject } from "../../constants/permissions";
import useDisableForm from "../common/useDisableForm";
import useDisableCountrySelection from "../../hooks/useDisableCountrySelection";
import ClearIcon from '@material-ui/icons/Clear';
import BackButton from "../common/back-button";
import { FormModeDropdown } from "../common/form-mode-dropdown";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  paper: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 5,
    marginRight: 5,
    padding: 50,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    marginBottom: 20,
  },
  button: {
    width: 200,
  },
}));

const initialState = {
  email: "",
  name: "",
  facilityCode: "",
  countryCode: "",
  password: "",
  role: "sc",
  isDisabled: false,
};

export const UserForm = ({ match, isCreate, profileId }) => {

  const id = !!profileId ? profileId : match?.params?.id;

  const classes = useStyles();
  const userId = useSelector((state) => state.authDetails.id);
  const userRole = useSelector((state) => state.authDetails.role);
  const currentUserRole = ROLE_CHOICES.find((choice) => choice.id === userRole);
  const [error, setError] = useState({});
  const [facilities, setFacilities] = useState([]);
  // const [countries, setCountries] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const [canDisable, setCanDisable] = useState(false);
  const [canEnable, setCanEnable] = useState(false);
  const [canTransfer, setCanTransfer] = useState(false);
  const [canResetPassword, setCanResetPassword] = useState(false);

  const [countries, setCountries] = useState([]);
  const history = useHistory();

  // form fields
  const [user, setUser] = useState(initialState);
  const [oldEmail, setOldEmail] = useState("");
  const [showTransferOwnershipDialog, setShowTransferOwnershipDialog] = useState(false);
  const [settingsPopoverAnchorEl, setSettingsPopoverAnchorEl] = useState(null);

  const { disableForm } = useDisableForm(Subject.User, id, isCreate)

  const { hasConflict } = useDisableCountrySelection(user, "countryCode", "facilityCode")

  const updateField = (name, value) => {
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // form submit event
  const onFormSubmit = async () => {
    // if create
    if (isCreate) {
      const [hasError, error] = await validateFields(
        user,
        usersValidationSchema,
        false
      );
      if (!hasConflict && !hasError) {
        create("users", user)
          .then(() => {
            showSuccessSnackbar("Successfully Created User!");
            history.push("/users");
          })
          .catch((e) => {
            showErrorSnackbar(e);
          });
      }
      setError(error);
    } else {
      const [hasError, error] = await validateFields(
        user,
        { ...usersValidationSchema, password: null },
        false
      );
      if (!hasConflict && !hasError) {
        update("users", { ...user, id })
          .then((data) => {
            const user = data.data;
            // update user role and name if updated own id
            if (user.id === userId) {
              if (oldEmail !== user.email) {
                showSuccessSnackbar(
                  "Successfully Updated Profile! Your email has changed, please log in with your latest email."
                );
                logoutUser();
                return;
              } else {
                authenticate();
              }
              showSuccessSnackbar("Successfully Updated Profile!");
              history.push("/");
            }
            // log out user if updated email
            else {
              showSuccessSnackbar("Successfully Updated User!");
              history.push("/users")
            }
          })
          .catch((e) => {
            showErrorSnackbar(e);
          });
      }
      setError(error);
    }
  };

  // on reset password
  const onResetPassword = () => {
    getResetPasswordLink(user.email)
      .then(() => {
        showSuccessSnackbar("An email has been sent. Please check your inbox.");
      })
      .catch((e) => {
        showErrorSnackbar(e.message);
      });
  };

  const onTransferOwnership = () => {
    setShowTransferOwnershipDialog(true);
  }

  const onDisableUser = () => {
    update("users/disable", { id })
      .then((data) => {
        setCanEdit(data.allData.canEditRole && data.allData.canEditCountry && data.allData.canEditFacility)
        setCanDisable(data.allData.canDisable);
        setCanEnable(data.allData.canEnable);
        setCanTransfer(data.allData.canTransfer);
        setCanResetPassword(data.allData.canResetPassword);
        if (!!data.data) {
          let d = data.data;
          if (d.facilityCode === "*") {
            d.facilityCode = null;
          }
          if (d.countryCode === "*") {
            d.countryCode = null;
          }
          setUser({
            email: d.email,
            name: d.name,
            facilityCode: d.facilityCode,
            countryCode: d.countryCode,
            password: d.password,
            role: d.role,
            isDisabled: d.isDisabled,
          });
          setOldEmail(d.email);
          if (!!profileId) setTitle("Profile");
          else setTitle(d.name);
        }
      })
      .then(() => {
        showSuccessSnackbar("Successfully disabled user!");
      })
      .catch((e) => {
        showErrorSnackbar(e);
      });
  }

  const onEnableUser = () => {
    update("users/enable", { id })
      .then((data) => {
        setCanEdit(data.allData.canEditRole && data.allData.canEditCountry && data.allData.canEditFacility)
        setCanDisable(data.allData.canDisable);
        setCanEnable(data.allData.canEnable);
        setCanTransfer(data.allData.canTransfer);
        setCanResetPassword(data.allData.canResetPassword);
        if (!!data.data) {
          let d = data.data;
          if (d.facilityCode === "*") {
            d.facilityCode = null;
          }
          if (d.countryCode === "*") {
            d.countryCode = null;
          }
          setUser({
            email: d.email,
            name: d.name,
            facilityCode: d.facilityCode,
            countryCode: d.countryCode,
            password: d.password,
            role: d.role,
            isDisabled: d.isDisabled,
          });
          setOldEmail(d.email);
          if (!!profileId) setTitle("Profile");
          else setTitle(d.name);
        }
      })
      .then(() => {
        showSuccessSnackbar("Successfully enabled user!");
      })
      .catch((e) => {
        showErrorSnackbar(e);
      });
  }

  // Load data if needed
  useEffect(() => {
    if (!!id) {
      setTitle("Users");
      getOne("users", id)
        .then((data) => {
          setCanEdit(data.allData.canEditRole && data.allData.canEditCountry && data.allData.canEditFacility)
          setCanDisable(data.allData.canDisable);
          setCanEnable(data.allData.canEnable);
          setCanTransfer(data.allData.canTransfer);
          setCanResetPassword(data.allData.canResetPassword);
          if (!!data.data) {
            let d = data.data;
            if (d.facilityCode === "*") {
              d.facilityCode = null;
            }
            if (d.countryCode === "*") {
              d.countryCode = null;
            }
            setUser({
              email: d.email,
              name: d.name,
              facilityCode: d.facilityCode,
              countryCode: d.countryCode,
              password: d.password,
              role: d.role,
              isDisabled: d.isDisabled,
            });
            setOldEmail(d.email);
            if (!!profileId) setTitle("Profile");
            else setTitle(d.name);
          }
        })
        .catch((e) => {
          setUser(initialState);
          showErrorSnackbar(e);
        });
    }
  }, [isCreate, match, profileId, id, disableForm]);

  useEffect(() => {
    // if create, set role
    if (
      isCreate &&
      !!match &&
      !!match.params &&
      !!match.params.role &&
      ROLE_CHOICES.find((r) => r.id === match.params.role)
      // COUNTRY_CHOICES.find((r) => r.id === match.params.countries)
    ) {
      updateField("role", match.params.role);
      setCanEdit(true);
      // updateField("country", match.params.countries);
    }
  }, [isCreate, match])

  const selectedCountry = useSelector((state) => state.selectedCountry.selectedCountry);

  useEffect(() => {
    queryList("facility/options", `countryCode=${selectedCountry}`).then((d) => {
      setFacilities(d.data);
    });
  }, [selectedCountry]);

  useEffect(() => {
    queryList("country/options", `countryCode=${selectedCountry}`).then((d) => {
      setCountries(d.data);
    });
  }, [selectedCountry]);

  const ownProfile = !!profileId || user.id === userId;
  // superadmin (rank 0) allow to create another superadmin
  // other roles below superadmin (rank > 0) are not allowed to create another user of the same role
  let roleChoices = ROLE_CHOICES;
  if (!disableForm) {
    roleChoices = ROLE_CHOICES.filter(choice => choice.rank > (currentUserRole?.rank ?? Infinity) || currentUserRole?.rank === 0);
  }
  if (ownProfile) {
    roleChoices = ROLE_CHOICES.filter(choice => choice.id === userRole)
  }
  const disableCountryField = !!profileId || !canEdit || user.countryCode === null || user.role === "" || user.role !== "ca";
  const disableFacilityField = !!profileId || !canEdit || user.facilityCode === null || user.role === "" || user.role === "ca" || user.role === "sa";
  const disableRoleField = !!profileId || !canEdit;

  useEffect(() => {
    switch (user.role) {
      case "sa":
        setUser((user) => ({ ...user, countryCode: null, facilityCode: null }));
        break;
      case "ca":
        setUser((user) => ({ ...user, facilityCode: null, countryCode: user.countryCode === null ? "" : user.countryCode }));
        break;
      case "sc":
      case "ra":
        const currentFacility = facilities.find((facility) => facility.facilityCode === user.facilityCode);
        let countryCode = null;
        if (currentFacility !== undefined) {
          countryCode = currentFacility.countryCode;
        }
        setUser((user) => ({ ...user, countryCode, facilityCode: user.facilityCode === null ? "" : user.facilityCode }));
        break;
      default:
        setUser((user) => ({ ...user, countryCode: null, facilityCode: null }));
        break;
    }
  }, [user.role, user.facilityCode, user.countryCode, facilities])

  // redirect after creation
  if (!!user.id && isCreate) {
    return <Redirect to={`/users/${user.id}`} />;
  }

  const showUserSettings = canDisable || canEnable || canResetPassword || canTransfer;

  return (
    <div>
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <BackButton />
        <DynamicVisibilityComponent
          component={<Box sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
            <Box>
              {user.isDisabled ? <Typography component={"span"} variant={"caption"} sx={{ color: "white", background: "grey", paddingX: 1, paddingY: 0.25, borderRadius: 2 }}>
                INACTIVE
              </Typography> : <Typography component={"span"} variant={"caption"} sx={{ color: "white", background: "green", paddingX: 1, paddingY: 0.25, borderRadius: 2 }}>
                ACTIVE
              </Typography>}
            </Box>
            {showUserSettings && <Box>
              <IconButton onClick={(e) => setSettingsPopoverAnchorEl(e.currentTarget)}>
                <Settings />
              </IconButton>
            </Box>}
          </Box>}
          isVisible={!isCreate}
        />
        {!isCreate && <FormModeDropdown />}
      </Box>
      <FormWrapper disableForm={disableForm}>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Popover open={!!settingsPopoverAnchorEl} anchorEl={settingsPopoverAnchorEl} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={() => setSettingsPopoverAnchorEl(null)}>
              <List>
                {canResetPassword && <ListItemButton name="resetPassword"
                  onClick={onResetPassword}
                  className={classes.button}
                >
                  Reset Password
                </ListItemButton>}
                {canTransfer && <ListItemButton
                  name="transferOwnership"
                  onClick={onTransferOwnership}
                  className={classes.button}
                >
                  Transfer Ownership
                </ListItemButton>}
                {!user.isDisabled ?
                  (canDisable && <ListItemButton
                    name="disableUser"
                    onClick={onDisableUser}
                    className={classes.button}
                  >
                    Deactivate User
                  </ListItemButton>)
                  : (canEnable && <ListItemButton
                    name="enableUser"
                    onClick={onEnableUser}
                    className={classes.button}
                  >
                    Activate User
                  </ListItemButton>)
                }
              </List>
            </Popover>
            <form className={classes.form} noValidate autoComplete="off">
              <Grid container spacing={3} style={{ marginBottom: 4 }}>
                <Grid item xs={12}>
                  <CustomTextField
                    label="Name"
                    value={user.name}
                    setValue={(v) => updateField("name", v)}
                    error={!!error?.name}
                    helperText={error?.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    variant="outlined"
                    fullWidth
                    label="Role"
                    value={user.role}
                    onChange={(e) => {
                      if (e.target.value === "ca" || e.target.value === "sa") {
                        updateField("facilityCode", "");
                      }
                      if (e.target.value !== "ca") {
                        updateField("countryCode", "");
                      }
                      updateField("role", e.target.value)
                    }}
                    disabled={disableRoleField}
                  >
                    {roleChoices.map((choice) => (
                      <MenuItem key={choice.id} value={choice.id}>
                        {choice.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {user.countryCode !== null && <Grid item xs={12}>
                  <TextField
                    select
                    variant="outlined"
                    fullWidth
                    label="Country"
                    error={!!error?.countryCode}
                    helperText={error?.countryCode}
                    value={user.countryCode}
                    onChange={(e) => {
                      if (!isCreate && e.target.value !== user.countryCode)
                        showModal({
                          type: "confirmationModal",
                          title: "Confirm changes",
                          content: "This would affect user's access to patients, records and requests in the current country. Would you like to proceed with the changes?",
                          confirm: "Proceed",
                          cancel: "Abort",
                          onConfirm: () => {
                            updateField("countryCode", e.target.value)
                            hideModal();
                          }
                        })
                      else
                        updateField("countryCode", e.target.value)
                    }}
                    disabled={disableCountryField}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton onClick={(e) => updateField("countryCode", "")} disabled={disableCountryField}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    }}
                  >
                    {countries.map((country) => (
                      <MenuItem
                        key={country.countryCode}
                        value={country.countryCode}
                      >
                        {country.countryName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>}
                {user.facilityCode !== null && <Grid item xs={12}>
                  <TextField
                    select
                    variant="outlined"
                    fullWidth
                    label="Facility"
                    error={!!error?.facilityCode}
                    helperText={error?.facilityCode}
                    value={user.facilityCode}
                    onChange={(e) => {
                      if (!isCreate && e.target.value !== user.facilityCode)
                        showModal({
                          type: "confirmationModal",
                          title: "Confirm changes",
                          content: "This would affect user's access to patients, records and requests in the current facility. Would you like to proceed with the changes?",
                          confirm: "Proceed",
                          cancel: "Abort",
                          onConfirm: () => {
                            updateField("facilityCode", e.target.value)
                            hideModal();
                          }
                        })
                      else updateField("facilityCode", e.target.value)
                    }}
                    disabled={disableFacilityField}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton onClick={(e) => updateField("facilityCode", "")} disabled={disableFacilityField}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    }}
                  >
                    {facilities.map((facility) => (
                      <MenuItem
                        key={facility.facilityCode}
                        value={facility.facilityCode}
                      >
                        {facility.facilityName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>}
                <Grid item xs={12}>
                  <CustomTextField
                    label="Email"
                    value={user.email}
                    setValue={(v) => updateField("email", v)}
                    error={!!error?.email}
                    helperText={error?.email}
                  />
                </Grid>
                <DynamicVisibilityComponent
                  component={
                    <Grid item xs={12}>
                      <CustomTextField
                        label="Password"
                        value={user.password}
                        setValue={(v) => updateField("password", v)}
                        type="password"
                        error={!!error?.password}
                        helperText={error?.password}
                      />
                    </Grid>
                  }
                  isVisible={isCreate}
                />
                <Grid item xs={12}>
                  <Button
                    name="save"
                    variant="outlined"
                    onClick={onFormSubmit}
                    color="primary"
                    className={classes.button}
                    disabled={hasConflict}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </div>
      </FormWrapper >
      {showTransferOwnershipDialog && <TransferOwnershipDialog open={showTransferOwnershipDialog} handleDismiss={() => {
        setShowTransferOwnershipDialog(false);
      }} email={user.email} />}
    </div>
  );
};
