import { conditionValidationSchema, malaysiaGeneralValidationSchema, globalGeneralValidationSchema, vietnamGeneralValidationSchema, historyCVRFValidationSchema, labsValidationSchema, vietnamDemographicsValidationSchema, drugValidationSchema, globalDemographicsValidationSchema, malaysiaDemographicsValidationSchema } from "./validation/schemas";
import { conditionFormConfig, vietnamDemographicFormConfig, malaysiaGeneralFormConfig, globalGeneralFormConfig, labsConfig, historyCVRFConfig, vietnamGeneralFormConfig, globalDemographicFormConfig, malaysiaDemographicFormConfig } from "./dynamic-config";
import completeDrugDataVietnam from "../constants/drug-data-vn.json";
import completeDrugDataMalaysia from "../constants/drug-data-my.json";
import { create, update } from "../services/api/common";

const patientUpdateFunc = () => (data, id) => {
  if (id !== undefined) {
    data = { ...data, id };
  }
  return update("patients", data);
}

const patientCreateFunc = () => (data) => create("patients", data)

const medicalRecordUpdateFunc = (sectionName) => (data, id) => {
  if (id !== undefined) {
    data = { id, formData: { [sectionName]: data } };
  } else {
    data = { formData: { [sectionName]: data } };
  }
  return update("medicalrecords", data);
}

const medicalRecordCreateFunc = (sectionName) => null;

export const dynamicConfig = {
  global: {
    general: {
      config: globalGeneralFormConfig,
      validation: globalGeneralValidationSchema,
      createFunc: patientCreateFunc(),
      updateFunc: patientUpdateFunc(),
    },
    demographics: {
      config: globalDemographicFormConfig,
      validation: globalDemographicsValidationSchema,
      createFunc: medicalRecordCreateFunc('demographics'),
      updateFunc: medicalRecordUpdateFunc('demographics'),
    },
    condition: {
      config: conditionFormConfig,
      validation: conditionValidationSchema,
      createFunc: medicalRecordCreateFunc('condition'),
      updateFunc: medicalRecordUpdateFunc('condition'),
    },
    history_cvrf: {
      config: historyCVRFConfig,
      validation: historyCVRFValidationSchema,
      createFunc: medicalRecordCreateFunc('history_cvrf'),
      updateFunc: medicalRecordUpdateFunc('history_cvrf')
    },
    labs: {
      config: labsConfig,
      validation: labsValidationSchema,
      createFunc: medicalRecordCreateFunc('labs'),
      updateFunc: medicalRecordUpdateFunc('labs')
    },
    drugs: {
      config: completeDrugDataMalaysia,
      validation: drugValidationSchema(completeDrugDataMalaysia),
      createFunc: medicalRecordCreateFunc('drugs'),
      updateFunc: medicalRecordUpdateFunc('drugs')
    }
  },
  my: {
    general: {
      config: malaysiaGeneralFormConfig,
      validation: malaysiaGeneralValidationSchema,
      createFunc: patientCreateFunc(),
      updateFunc: patientUpdateFunc(),
    },
    demographics: {
      config: malaysiaDemographicFormConfig,
      validation: malaysiaDemographicsValidationSchema,
      createFunc: medicalRecordCreateFunc('demographics'),
      updateFunc: medicalRecordUpdateFunc('demographics'),
    },
    condition: {
      config: conditionFormConfig,
      validation: conditionValidationSchema,
      createFunc: medicalRecordCreateFunc('condition'),
      updateFunc: medicalRecordUpdateFunc('condition'),
    },
    history_cvrf: {
      config: historyCVRFConfig,
      validation: historyCVRFValidationSchema,
      createFunc: medicalRecordCreateFunc('history_cvrf'),
      updateFunc: medicalRecordUpdateFunc('history_cvrf')
    },
    labs: {
      config: labsConfig,
      validation: labsValidationSchema,
      createFunc: medicalRecordCreateFunc('labs'),
      updateFunc: medicalRecordUpdateFunc('labs'),
    },
    drugs: {
      config: completeDrugDataMalaysia,
      validation: drugValidationSchema(completeDrugDataMalaysia),
      createFunc: medicalRecordCreateFunc('drugs'),
      updateFunc: medicalRecordUpdateFunc('drugs')
    }
  },
  vn: {
    general: {
      config: vietnamGeneralFormConfig,
      validation: vietnamGeneralValidationSchema,
      createFunc: patientCreateFunc(),
      updateFunc: patientUpdateFunc(),
    },
    demographics: {
      config: vietnamDemographicFormConfig,
      validation: vietnamDemographicsValidationSchema,
      createFunc: medicalRecordCreateFunc('demographics'),
      updateFunc: medicalRecordUpdateFunc('demographics')
    },
    condition: {
      config: conditionFormConfig,
      validation: conditionValidationSchema,
      createFunc: medicalRecordCreateFunc('condition'),
      updateFunc: medicalRecordUpdateFunc('condition')
    },
    history_cvrf: {
      config: historyCVRFConfig,
      validation: historyCVRFValidationSchema,
      createFunc: medicalRecordCreateFunc('history_cvrf'),
      updateFunc: medicalRecordUpdateFunc('history_cvrf')
    },
    labs: {
      config: labsConfig,
      validation: labsValidationSchema,
      createFunc: medicalRecordCreateFunc('labs'),
      updateFunc: medicalRecordUpdateFunc('labs')
    },
    drugs: {
      config: completeDrugDataVietnam,
      validation: drugValidationSchema(completeDrugDataVietnam),
      createFunc: medicalRecordCreateFunc('drugs'),
      updateFunc: medicalRecordUpdateFunc('drugs')
    }
  },
}
