import { format } from "date-fns";

export function changeDateFormat(date) {
  let value;
  if (!(date instanceof Date)) {
    value = new Date(date);
  } else {
    value = date;
  }
  if (isNaN(value)) return date;
  return format(value, "yyyy-MM-dd");
}

export function getDateObject(date) {
  return new Date(date);
}
