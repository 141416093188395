import { Button, Box } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  getFormFieldsByConfig,
  getStateObjectByConfig,
  setDefaultValues,
  changeStateByPath,
  clearHiddenState,
  setDerivation,
  getStateByPath,
} from "../../utils/formBuilderUtils";
import { validateFields } from "../../config/validation/yup";
import { allChangesSaved } from "../../utils/commonUtils";
import { showSuccessSnackbar, showErrorSnackbar, showModal, hideModal } from "../../redux/dispatchers";
import FormWrapper from "../../components/common/form-wrapper";

const initialState = {};

const GenericForm = ({ formConfig, validationSchema, disableForm, onSaveAction, storedState, completeStateForDerivation, emitCurrentState, currentEditingId, alertUnsaved = false, checkUnsavedChangesMode = "exact", fetchOptionsArgs }) => {
  const [state, setState] = useState(initialState);
  const [error, setError] = useState({});
  const [options, setOptions] = useState({});
  const [hasUnsavedChange, setHasUnsavedChanges] = useState(false);

  const handleInput = (event) => {
    const { name, value } = event.target;
    setState(changeStateByPath(name, state, value));
  };

  const handleBlur = (event, action) => {
    let { name: statePath, value } = event.target;
    if (action) value = action(value);
    setState(changeStateByPath(statePath, state, value));
  };

  const handleClear = (statePath) => {
    const currentValue = getStateByPath(statePath, state);
    if (currentValue !== "") {
      showModal({
        type: "confirmationModal",
        title: `Clear field value`,
        content: `Are you sure you want to clear this field? It might affect other data in the form.`,
        confirm: "Confirm",
        cancel: "Cancel",
        onConfirm: () => {
          setState(changeStateByPath(statePath, state, ""))
          hideModal();
        },
      });
    }
  };

  const handleButtonClick = () => {
    validateFields(
      state,
      validationSchema,
      false
    ).then(([hasError, err]) => {
      if (!hasError) {
        onSaveAction(state, currentEditingId).then((response) => {
          setHasUnsavedChanges(false);
          showSuccessSnackbar("Saved successfully!");
        }).catch((e) => {
          setHasUnsavedChanges(true);
          if (typeof e === "string") {
            showErrorSnackbar(e);
          } else {
            showErrorSnackbar("Your data entry is invalid. Make the changes and save again.");
          }
        });
      } else {
        showErrorSnackbar("Your data entry is invalid. Make the changes and save again.");
      }
      setError(err);
    });
  };

  const resetFormState = () => {
    setState(initialState);
  }

  useEffect(() => {
    setState((state) => {
      let updatedState = getStateObjectByConfig(formConfig, state, storedState);
      updatedState = setDerivation(formConfig, updatedState, completeStateForDerivation);
      updatedState = clearHiddenState(formConfig, updatedState);
      updatedState = setDefaultValues(formConfig, updatedState);
      if (!allChangesSaved(updatedState, state) || !allChangesSaved(state, updatedState)) {
        return updatedState;
      }
      return state;
    })
  }, [state, formConfig, options, storedState, completeStateForDerivation]);

  useEffect(() => {
    if (!disableForm) {
      setHasUnsavedChanges(!(allChangesSaved(state, storedState) && (checkUnsavedChangesMode !== "exact" || allChangesSaved(storedState, state))))
    } else {
      setHasUnsavedChanges(false);
    }
  }, [state, disableForm, storedState, checkUnsavedChangesMode]);

  useEffect(() => {
    const config = [...formConfig];
    const promises = [];
    const keys = [];
    for (var elem of config) {
      if (Array.isArray(elem)) {
        for (var inner of elem) {
          config.push(inner);
        }
      } else if (typeof elem === "object") {
        if (elem.name !== undefined && typeof elem.component?.choices === "function") {
          keys.push(elem.name)
          promises.push(elem.component.choices(fetchOptionsArgs));
        }
      }
    }
    Promise.all(promises).then((resolved) => {
      const fetchedOptions = {};
      for (let i in resolved) {
        fetchedOptions[keys[i]] = resolved[i];
      }
      setOptions(fetchedOptions);
    });
  }, [formConfig, fetchOptionsArgs]);

  useEffect(() => {
    if (typeof emitCurrentState === "function") emitCurrentState(state);
  }, [state, emitCurrentState]);

  return (
    <>
      <FormWrapper disableForm={disableForm} hasUnsavedChanges={alertUnsaved && hasUnsavedChange} resetForm={resetFormState}>
        <FormControl fullWidth sx={{ display: "flex", flexDirection: "column", flexGrow: 1, minHeight: "100%" }}>
          {getFormFieldsByConfig(formConfig, state, options, handleInput, handleBlur, handleClear, error)}
          <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
            <Button
              name="save"
              color="primary"
              variant="outlined"
              onClick={handleButtonClick}
              style={{ width: 200 }}
              disabled={onSaveAction === undefined}
            >
              Save
            </Button>
          </Box>
        </FormControl>
      </FormWrapper>
    </>
  );
};

export default GenericForm;
