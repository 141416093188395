import { queryList } from "../../services/api/common";
export const fetchDataFromDatabase = () => {
  return undefined;
}

export const malaysiaGeneralFormConfig = (isArchive, patientId, countryCode) => [
  [
    {
      name: "patientCode",
      state: fetchDataFromDatabase(),
      label: "Patient's Code",
      component: {
        type: "TextField",
        props: {
          disabled: true,
        },
      },
    },
  ],
  [
    {
      name: "patientName",
      state: "",
      label: "Patient's Name",
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "patientICType",
      state: "new ic",
      label: "",
      component: {
        type: "SelectField",
        choices: [
          { id: "new ic", name: "New IC" },
          { id: "old ic", name: "Old IC" },
          { id: "army/police ic", name: "Army/Police IC" },
          { id: "na", name: "NA" },
        ],
        props: {
          required: true,
        },
      },
    },
    {
      name: "patientIC",
      state: "",
      label: "Patient IC",
      visibility: [
        {
          name: "patientICType",
          operator: "!==",
          value: "na",
        },
      ],
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "patientContact",
      state: "",
      label: "Patient's Contact",
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "facilityName",
      state: "",
      label: "Facility Name",
      component: {
        type: "SelectField",
        choices: async () => {
          let queryStringParams = `countryCode=${countryCode}`;
          if (isArchive && !!patientId) {
            queryStringParams = `countryCode=${countryCode}&patientId=${patientId}`;
          }
          return queryList("facility/options", queryStringParams).then((resp) => {
            const facilityList = resp.data.map((facility) => {
              return {
                id: facility.facilityCode,
                name: facility.facilityName,
              };
            });
            return facilityList;
          })
        },
        clearable: true,
        // choices: [
        //   { id: "UMMC", name: "UMMC" },
        //   { id: "UKMMC", name: "UKMMC" },
        //   { id: "UiTM", name: "UiTM" },
        //   { id: "PUTRAJAYA HOSPITAL", name: "PUTRAJAYA HOSPITAL" },
        //   { id: "SELAYANG HOSPITAL", name: "SELAYANG HOSPITAL" },
        //   { id: "SERDANG HOSPITAL", name: "SERDANG HOSPITAL" },
        // ],
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "clinicType",
      state: "E",
      label: "Clinic Type",
      component: {
        type: "SelectField",
        choices: [
          { id: "G", name: "General Medicine" },
          { id: "E", name: "Endocrine" },
        ],
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "facilityCode",
      state: "",
      label: "Facility Code",
      component: {
        type: "TextField",
        props: {
          disabled: true,
        },
        derivation: ({ facilityName }) => {
          return facilityName;
        },
      },
    },
  ],
  [
    {
      name: "hospitalRegNumber",
      label: "Hospital Registration Number",
      state: "",
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
];

export const vietnamGeneralFormConfig = (isArchive, patientId, countryCode) => [
  [
    {
      name: "patientCode",
      state: fetchDataFromDatabase(),
      label: "Patient's Code",
      component: {
        type: "TextField",
        props: {
          disabled: true,
        },
      },
    },
  ],
  [
    {
      name: "patientName",
      state: "",
      label: "Patient's Name",
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "otherPatientDetails.dateOfBirth",
      state: "",
      label: "Date of birth",
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
      derivation: ({ patientIC }) => {
        const tokens = patientIC.split(":");
        return new Date(tokens[0]);
      }
    }
  ],
  [
    {
      name: "patientICType",
      state: "custom",
      label: "Patient Identifier Type",
      component: {
        type: "SelectField",
        choices: [
          { id: "custom", name: "Custom" },
          { id: "na", name: "NA" }
        ],
        props: {
          required: true,
        },
      },
    },
  ],
  [{
    name: "patientIC",
    state: "",
    label: "Patient Identifier",
    visibility: [
      {
        name: "patientICType",
        operator: "!==",
        value: "na",
      },
    ],
    component: {
      type: "TextField",
      props: {
        disabled: true,
        required: true,
      },
      derivation: ({ otherPatientDetails: { dateOfBirth }, patientName, hospitalRegNumber }) => {
        const dateOfBirthString = String(dateOfBirth);
        const patientNameString = String(patientName);
        const hospitalRegNumberString = String(hospitalRegNumber);
        if (dateOfBirthString.length === 0 || patientNameString.length === 0 || hospitalRegNumberString.length === 0) return "";
        return dateOfBirthString + "@" + patientNameString + "@" + hospitalRegNumberString;
      }
    },
  }],
  [
    {
      name: "patientContact",
      state: "",
      label: "Patient's Contact",
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "facilityName",
      state: "",
      label: "Facility Name",
      component: {
        type: "SelectField",
        choices: async () => {
          let queryStringParams = `countryCode=${countryCode}`;
          if (isArchive && !!patientId) {
            queryStringParams = `countryCode=${countryCode}&patientId=${patientId}`;
          }
          return queryList("facility/options", queryStringParams).then((resp) => {
            const facilityList = resp.data.map((facility) => {
              return {
                id: facility.facilityCode,
                name: facility.facilityName,
              };
            });
            return facilityList;
          })
        },
        clearable: true,
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "clinicType",
      state: "E",
      label: "Clinic Type",
      component: {
        type: "SelectField",
        choices: [
          { id: "G", name: "General Medicine" },
          { id: "E", name: "Endocrine" },
        ],
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "facilityCode",
      state: "",
      label: "Facility Code",
      component: {
        type: "TextField",
        props: {
          disabled: true,
        },
        derivation: ({ facilityName }) => {
          return facilityName;
        },
      },
    },
  ],
  [
    {
      name: "hospitalRegNumber",
      label: "Hospital Registration Number",
      state: "",
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
];

export const globalGeneralFormConfig = (isArchive, patientId, countryCode) => [
  [
    {
      name: "patientCode",
      state: fetchDataFromDatabase(),
      label: "Patient's Code",
      component: {
        type: "TextField",
        props: {
          disabled: true,
        },
      },
    },
  ],
  [
    {
      name: "patientName",
      state: "",
      label: "Patient's Name",
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "patientICType",
      state: "",
      label: "Patient Identifier Type",
      component: {
        type: "TextField",
        props: {
          required: false,
        },
      },
    },
    {
      name: "patientIC",
      state: "",
      label: "Patient Identifier",
      visibility: [
        {
          name: "patientICType",
          operator: "!==",
          value: "",
        },
      ],
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "patientContact",
      state: "",
      label: "Patient's Contact",
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "facilityName",
      state: "",
      label: "Facility Name",
      component: {
        type: "SelectField",
        choices: async () => {
          let queryStringParams = `countryCode=${countryCode}`;
          if (isArchive && !!patientId) {
            queryStringParams = `countryCode=${countryCode}&patientId=${patientId}`;
          }
          return queryList("facility/options", queryStringParams).then((resp) => {
            const facilityList = resp.data.map((facility) => {
              return {
                id: facility.facilityCode,
                name: facility.facilityName,
              };
            });
            return facilityList;
          })
        },
        clearable: true,
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "clinicType",
      state: "E",
      label: "Clinic Type",
      component: {
        type: "SelectField",
        choices: [
          { id: "G", name: "General Medicine" },
          { id: "E", name: "Endocrine" },
        ],
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "facilityCode",
      state: "",
      label: "Facility Code",
      component: {
        type: "TextField",
        props: {
          disabled: true,
        },
        derivation: ({ facilityName }) => {
          return facilityName;
        },
      },
    },
  ],
  [
    {
      name: "hospitalRegNumber",
      label: "Hospital Registration Number",
      state: "",
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
];
