import React from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import ReactLoading from "react-loading";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1400,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(18, 18, 18, 0.2)",
    position: "fixed",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  overlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(18, 18, 18, 0.34)",
    width: 400,
    height: 300,
    flexDirection: "column",
    borderRadius: 20,
  },
}));

export const LoadingOverlay = () => {
  const classes = useStyles();

  const { isFetching, message } = useSelector((state) => state.loading);

  if (isFetching === 0) return null;

  return (
    <div className={classes.root}>
      <div className={classes.overlay}>
        <Typography variant="h6" style={{ marginBottom: 20, color: "white" }}>
          {message}
        </Typography>
        <ReactLoading type="balls" color="#ffffff" height={100} width={100} />
      </div>
    </div>
  );
};
