import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

export default function DenseTable({ drugData }) {
  const applyNormalCase = (category) => {
    switch (category) {
      case "glucoseLoweringDrugsNonInsulin":
        return "Glucose Lowering Drugs (Non Insulin)";
      case "glucoseLoweringDrugsInsulin":
        return "Glucose Lowering Drugs (Insulin)";
      case "bloodPressureLowering":
        return "Blood Pressure Lowering";
      case "lipidLowering":
        return "Lipid Lowering";
      case "weightLowering":
        return "Weight Lowering";
      case "antiplateletAntithrombotic":
        return "Antiplatelet/Antithrombotic";
      default:
        return "";
    }
  };

  if (Object.keys(drugData).length === 0) {
    return (
      <div style={{ textAlign: "center" }}>
        <h3>Empty list. Please select the drugs</h3>
      </div>
    );
  }

  const sameGroup = [
    "bloodPressureLowering",
    "lipidLowering",
    "weightLowering",
  ];

  return (
    <>
      <TableContainer component={Paper}>
        {Object.keys(drugData).map((category) => {
          return (
            <>
              {category === "glucoseLoweringDrugsNonInsulin" && (
                <>
                  <Table
                    size="small"
                    aria-label="a dense table"
                    style={{ marginBottom: "20px" }}
                  >
                    <TableHead style={{ backgroundColor: "#9999ff " }}>
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          style={{ width: "100%", fontWeight: "bold" }}
                        >{`Category: ${applyNormalCase(category)}`}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead style={{ backgroundColor: "#e6e6ff" }}>
                      <TableRow>
                        <TableCell style={{ width: "20%", fontWeight: "600" }}>
                          Medication
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ width: "20%", fontWeight: "600" }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ width: "20%", fontWeight: "600" }}
                        >
                          Dose
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ width: "20%", fontWeight: "600" }}
                        >
                          Number of tablets
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ width: "20%", fontWeight: "600" }}
                        >
                          Frequency
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(drugData[category]).map((drug) => {
                        const {
                          fieldName,
                          frequency,
                          dateOfInitiation,
                          commonDosing,
                          numberOfTablets,
                          others,
                        } = drugData[category][drug];
                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {fieldName}
                            </TableCell>
                            <TableCell align="right">
                              {dateOfInitiation === ""
                                ? "NA"
                                : dateOfInitiation}
                            </TableCell>
                            {commonDosing === "others" ? (
                              <TableCell align="right">
                                {others === "" ? "NA" : others}
                              </TableCell>
                            ) : (
                              <TableCell align="right">
                                {commonDosing}
                              </TableCell>
                            )}
                            <TableCell align="right">
                              {numberOfTablets ?? "NA"}
                            </TableCell>
                            <TableCell align="right">
                              {frequency ?? "NA"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </>
              )}
              {category === "glucoseLoweringDrugsInsulin" && (
                <>
                  <Table
                    size="small"
                    aria-label="a dense table"
                    style={{ marginBottom: "20px" }}
                  >
                    <TableHead style={{ backgroundColor: "#9999ff " }}>
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          style={{ width: "100%", fontWeight: "bold" }}
                        >{`Category: ${applyNormalCase(category)}`}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead style={{ backgroundColor: "#e6e6ff" }}>
                      <TableRow>
                        <TableCell style={{ width: "25%", fontWeight: "600" }}>
                          Medication
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ width: "25%", fontWeight: "600" }}
                        >
                          Date
                        </TableCell>

                        <TableCell
                          align="right"
                          style={{ width: "25%", fontWeight: "600" }}
                        >
                          Total Daily Dosing
                        </TableCell>
                        {/* <TableCell
                          align="right"
                          style={{ width: "25%", fontWeight: "600" }}
                        >
                          Frequency
                        </TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(drugData[category]).map((drug) => {
                        const {
                          fieldName,
                          //frequency,
                          dateOfInitiation,
                          totalDailyDosing,
                        } = drugData[category][drug];
                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {fieldName}
                            </TableCell>
                            <TableCell align="right">
                              {dateOfInitiation === ""
                                ? "NA"
                                : dateOfInitiation}
                            </TableCell>
                            <TableCell align="right">
                              {totalDailyDosing ?? "NA"}
                            </TableCell>
                            {/* <TableCell align="right">
                              {frequency ?? "NA"}
                            </TableCell> */}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </>
              )}
              {category === "antiplateletAntithrombotic" && (
                <>
                  <Table
                    size="small"
                    aria-label="a dense table"
                    style={{ marginBottom: "20px" }}
                  >
                    <TableHead style={{ backgroundColor: "#9999ff " }}>
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          style={{ width: "100%", fontWeight: "bold" }}
                        >{`Category: ${applyNormalCase(category)}`}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead style={{ backgroundColor: "#e6e6ff" }}>
                      <TableRow>
                        <TableCell style={{ width: "20%", fontWeight: "600" }}>
                          Medication
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ width: "20%", fontWeight: "600" }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ width: "20%", fontWeight: "600" }}
                        >
                          Dose
                        </TableCell>

                        <TableCell
                          align="right"
                          style={{ width: "20%", fontWeight: "600" }}
                        >
                          Total Weekly Dosing
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ width: "20%", fontWeight: "600" }}
                        >
                          Frequency
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(drugData[category]).map((drug) => {
                        const {
                          fieldName,
                          commonDosing,
                          others,
                          frequency,
                          dateOfInitiation,
                          totalWeeklyDosing,
                        } = drugData[category][drug];
                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {fieldName}
                            </TableCell>
                            <TableCell align="right">
                              {dateOfInitiation === ""
                                ? "NA"
                                : dateOfInitiation}
                            </TableCell>
                            {commonDosing === "others" ? (
                              <TableCell align="right">
                                {others === "" ? "NA" : others}
                              </TableCell>
                            ) : (
                              <TableCell align="right">
                                {commonDosing ?? "NA"}
                              </TableCell>
                            )}
                            <TableCell align="right">
                              {totalWeeklyDosing ?? "NA"}
                            </TableCell>
                            <TableCell align="right">
                              {frequency ?? "NA"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </>
              )}
              {sameGroup.includes(category) && (
                <Table
                  size="small"
                  aria-label="a dense table"
                  style={{ marginBottom: "20px" }}
                >
                  <TableHead style={{ backgroundColor: "#9999ff " }}>
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        style={{ width: "100%", fontWeight: "bold" }}
                      >{`Category: ${applyNormalCase(category)}`}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead style={{ backgroundColor: "#e6e6ff" }}>
                    <TableRow>
                      <TableCell style={{ width: "25%", fontWeight: "600" }}>
                        Medication
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ width: "25%", fontWeight: "600" }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ width: "25%", fontWeight: "600" }}
                      >
                        Dose
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ width: "25%", fontWeight: "600" }}
                      >
                        Frequency
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(drugData[category]).map((drug) => {
                      const {
                        fieldName,
                        frequency,
                        dateOfInitiation,
                        commonDosing,
                        totalDailyDosing,
                        totalWeeklyDosing,
                        others,
                      } = drugData[category][drug];
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {fieldName}
                          </TableCell>
                          <TableCell align="right">
                            {dateOfInitiation === "" ? "NA" : dateOfInitiation}
                          </TableCell>
                          {commonDosing === "others" ? (
                            <TableCell align="right">
                              {others === "" ? "NA" : others}
                            </TableCell>
                          ) : (
                            <TableCell align="right">
                              {commonDosing ||
                                totalDailyDosing ||
                                totalWeeklyDosing}
                            </TableCell>
                          )}
                          <TableCell align="right">
                            {frequency ?? "NA"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </>
          );
        })}
      </TableContainer>
    </>
  );
}
