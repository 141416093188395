import axios from "axios";
import { refreshAuthToken } from "../logic/auth";
import { clearAuthDetails, hideLoading, showLoading } from "../../redux/dispatchers";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const axiosInstanceNoRefresh = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if (config.method !== "get") {
      showLoading();
    } else {
      showLoading("Loading Resources");
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axiosInstanceNoRefresh.interceptors.request.use(
  async (config) => {
    if (config.method !== "get") {
      showLoading();
    } else {
      showLoading("Loading Resources");
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// middleware after request
axiosInstance.interceptors.response.use(
  (res) => {
    hideLoading();
    return res;
  },
  async (err) => {
    if (err.response && err.response.status === 401) {
      //try to refresh authentication token and resend the request
      try {
        await refreshAuthToken();
        const req = await axiosInstance.request(err.config);
        return req;
      } catch (e) {
        if (e.response && e.response.status === 401) {
          clearAuthDetails();
          return Promise.reject(
            new Error("Your session has expired. Please login again.")
          );
        }
        return Promise.reject(e);
      } finally {
        hideLoading();
      }
    } else {
      hideLoading();
      return Promise.reject(err);
    }
  }
);

axiosInstanceNoRefresh.interceptors.response.use(
  (res) => {
    hideLoading();
    return res;
  },
  async (err) => {
    hideLoading();
    return Promise.reject(err);
  }
);
