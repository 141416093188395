import { Card, CardContent, Grid, makeStyles } from "@material-ui/core";
import { ArrowUpwardRounded, NotesRounded } from "@material-ui/icons";
import { format } from "date-fns";
import React from "react";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { changeDateFormat } from "../dashboard-utils";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  stat: {
    fontSize: "40px",
  },
  update: {
    display: "flex",
    marginLeft: "20px",
    fontSize: "18px",
    alignItems: "center",
  },
  plusSign: {
    color: "green",
    fontSize: "18px",
  },
  equal: {
    color: "grey",
    fontSize: "18px",
  },
  info: {
    color: "grey",
    fontSize: "14px",
    marginTop: "10px",
  },
}));

function InfoCard({ title, bigNumberData, areaChartData, areaChartConfig }) {
  const { total = 0, today = 0 } = bigNumberData;
  const { xKey, yKey } = areaChartConfig;
  const classes = useStyles();

  function dateConverter(date) {
    return format(date, "dd-MM-yyyy");
  }

  return (
    <Grid item sm={6}>
      <Card>
        <CardContent
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <div style={{ fontWeight: "bold" }}>
              {title}
            </div>
            <div className={classes.wrapper}>
              <div className={classes.stat}>{total}</div>
              <div className={classes.update}>
                {today > 0 && <div>+</div>}
                <div>{today}</div>
                {today > 0 ? (
                  <ArrowUpwardRounded className={classes.plusSign} />
                ) : (
                  <NotesRounded className={classes.equal} />
                )}
              </div>
            </div>
            <div style={{ color: "grey", fontSize: "12px" }}>
              New addition today
            </div>
            <div className={classes.info}>
              {`Last updated: ${dateConverter(
                new Date(changeDateFormat(new Date()))
              )}`}
            </div>
          </div>

          <ResponsiveContainer width="50%" height={120}>
            {/* <LineChart
              data={lineChart}
              margin={{ top: 5, right: 50, left: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <XAxis
                dataKey="date"
                interval={0}
                tickFormatter={(str) =>
                  format(parse(str, "yyyy-MM-dd", new Date()), "dd-MMM")
                }
              />
              <YAxis hide />
              <Line
                type="monotone"
                dataKey="total"
                stroke="#82ca9d"
                strokeWidth={2}
              />
            </LineChart> */}
            <AreaChart data={areaChartData} margin={{ top: 5, right: 50, left: 20 }}>
              <defs>
                <linearGradient id="green" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey={xKey}
                interval={0}
                tickFormatter={(str) => {
                  return format(new Date(str), "dd-MMM")
                }}
                style={{ fontSize: "12px" }}
              />
              <YAxis hide />
              <Tooltip />
              <Area
                type="monotone"
                dataKey={yKey}
                stroke="#82ca9d"
                fillOpacity={1}
                fill="url(#green)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default InfoCard;
