import { setDisableSelectCountryAction, setFacilityCountryAction, setSelectedCountryAction } from "../actions/selectedCountryAction";
import { store } from "../store";

export const setSelectedCountry = (selectedCountry) => {
  store.dispatch(setSelectedCountryAction(selectedCountry));
};

export const setDisableSelectedCountry = (disable) => {
  store.dispatch(setDisableSelectCountryAction(disable));
};

export const setFacilityCountry = (facilityCountry) => {
  store.dispatch(setFacilityCountryAction(facilityCountry));
};
