import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DialogContentText,
} from "@material-ui/core";

const AlertDialog = ({ open, alertTitle, alertContent, alertActions }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{alertTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{alertContent}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {alertActions.map((action) => (
          <Button key={action.name} onClick={action.callback}>
            {action.name}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
