import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles({
  body: {
    textAlign: "center",
  },
  text: { color: "#4d4d4d" },
});

function RestrictAccess() {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <h1 className={classes.text}>Sorry, you have no access to this page</h1>
    </div>
  );
}

export default RestrictAccess;
