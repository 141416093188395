export const Subject = Object.freeze({
  User: 'User',
  Facility: 'Facility',
  Country: 'Country',
  Patient: 'Patient',
  Record: 'MedicalRecord',
  Request: 'Request',
  ResetPasswordEmail: 'ResetPasswordEmail',
});

export const Action = Object.freeze({
  Create: 'create',
  Read: 'read',
  Update: 'update',
  Delete: 'delete',
  Search: 'search',
  Export: 'export',
  GetOptions: 'choose',
  Approve: 'approve',
  Deny: 'deny',
  Disable: 'disable',
  Enable: 'enable',
})
