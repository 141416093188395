import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Paper, Tooltip, Typography } from "@material-ui/core";
import { WarningOutlined } from "@material-ui/icons";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      style={{ flex: "1 1 auto", minHeight: 0 }}
      {...other}
    >
      {value === index && children}
    </div >
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export const CustomScrollMenu = ({
  components,
  callback,
  guard = false,
  forceValue,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    if (!!callback) {
      callback(newValue);
      return;
    };
    if (!guard) {
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (forceValue >= 0) setValue(forceValue);
  }, [forceValue]);

  return (
    <>

      {/* <div className={classes.root}> */}
      {/* <AppBar position="static" color="default" variant="dense"> */}
      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          // scrollButtons="auto"
          aria-label="scrollable auto tabs"
        >
          {components.map((component) => {
            const tab = <Tab
              label={<Box style={{ color: "black", display: "flex", alignItems: "center" }}><Typography variant="body2" style={{ margin: 1 }}>{component.label}</Typography>{component.showWarning && <WarningOutlined fontSize="small" style={{ color: "rgb(246,190,0)" }} />}</Box>}
              key={component.label}
            />
            if (component.showWarning) {
              return <Tooltip title={"Database data is invalid or not found. Please save this section again."}>
                {tab}
              </Tooltip>
            }
            return tab;
          }
          )}
        </Tabs>
      </Paper>
      {/* </AppBar> */}
      {components.map((component, index) => (
        <TabPanel value={value} index={index} key={index}>
          {component.contents}
        </TabPanel>
      ))}
      {/* </div> */}
    </>
  );
};
