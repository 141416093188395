import { axiosInstance } from "./axios-instance";

export const query = async (url) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((response) => ({
        status: response.status,
        headers: response.headers,
        allData: response.data,
        data: response.data.data,
        total: response.data.total,
        message: response.data.message,
      }))
      .then(({ status, headers, allData, data, total, message }) => {
        if (!!total || total === 0) total = parseInt(total, 10);
        if (status < 200 || status >= 300) {
          return reject("error occurred");
        }
        return resolve({ status, headers, allData, data, total, message });
      })
      .catch((err) => {
        if (!!err.response && !!err.response.data && err.response.data.message)
          return reject(err.response.data.message);
        return reject(err.message);
      });
  });
};

export const queryList = async (source, params) => {
  // construct URL to query
  const url = `/${source}?${params}`;
  return query(url);
};

export const getOne = async (source, id, params) => {
  // construct URL to query
  const url = `/${source}/${id}?${params ?? ""}`;
  return query(url);
};

export const create = async (source, data) => {
  // remove _id field
  delete data["_id"];
  let url = `/${source}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(url, data)
      .then((response) => ({
        status: response.status,
        headers: response.headers,
        data: response.data.data,
      }))
      .then(({ status, headers, data }) => {
        if (status < 200 || status >= 300) {
          return reject("error occurred");
        }
        return resolve({ status, headers, data });
      })
      .catch((err) => {
        if (
          !!err.response &&
          !!err.response.data &&
          err.response.data.message
        ) {
          return reject(err.response.data.message);
        }
        return reject(err.message);
      });
  });
};

export const update = async (source, data) => {
  const url = `/${source}/${!!data._id ? data._id : data.id ?? ""}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(url, data)
      .then((response) => ({
        status: response.status,
        headers: response.headers,
        data: response.data.data,
        allData: response.data,
      }))
      .then(({ status, headers, data, allData }) => {
        if (status < 200 || status >= 300) {
          return reject("error occurred");
        }
        return resolve({ status, headers, data, allData });
      })
      .catch((err) => {
        if (!!err.response && !!err.response.data && err.response.data.message)
          return reject(err.response.data.message);
        return reject(err.message);
      });
  });
};

export const deleteOne = async (source, id) => {
  let url = `/${source}/${id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(url)
      .then((response) => ({
        status: response.status,
        headers: response.headers,
        data: {},
      }))
      .then(({ status, headers, data }) => {
        if (status < 200 || status >= 300) {
          return reject("error occurred");
        }
        return resolve({ status, headers, data });
      })
      .catch((err) => {
        if (!!err.response && !!err.response.data && err.response.data.message)
          return reject(err.response.data.message);
        return reject(err.message);
      });
  });
};

// export const getOne = async (source, id) => {
//   if (!!queryData[source] && queryData[source].length > 0)
//     return {
//       data: queryData[source][0],
//     };
//   return { data: {} };
// };

// export const getById = (source, id) => {
//   if (!source || !queryData[source] || queryData[source].length === 0) return;
//   return queryData[source].find((data) => parseInt(data.id) === parseInt(id));
// };

// export const create = async (source, data) => {
//   data.id = "abc";
//   return { data };
// };

// export const update = async (source, data) => {
//   return { data };
// };
