import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router";
import { Action, Subject } from "../../constants/permissions";
import { query } from "../../services/api/common";
import { showErrorSnackbar } from "../../redux/dispatchers";

const useDisableForm = (subject, id, isCreate) => {
  const { search } = useLocation();
  const authDetails = useSelector((state) => state.authDetails);
  const [permission, setPermission] = useState(undefined);
  const [disableForm, setDisableForm] = useState(true);

  const history = useHistory();

  useEffect(() => {
    const subjectToURLMapping = {
      [Subject.Country]: "country",
      [Subject.Facility]: "facility",
      [Subject.Patient]: "patients",
      [Subject.Record]: "medicalrecords",
      [Subject.Request]: "requests",
      [Subject.User]: "users",
    }

    const url = subjectToURLMapping[subject];
    if (!!!id || !!!url) {
      setPermission({});
      return;
    }
    query(`${url}/cani/${id}`).then(data => data.data).then(permission => {
      setPermission(permission);
    }).catch(() => {
      setPermission({});
    })
  }, [authDetails, subject, id]);

  useEffect(() => {
    if (isCreate) { setDisableForm(false); return; }
    let mode = new URLSearchParams(search).get('mode');
    switch (mode) {
      case "edit":
        if (permission?.[Action.Update] === true) {
          setDisableForm(false);
        } else if (permission?.[Action.Update] === false) {
          showErrorSnackbar("You are not allowed to edit.");
          const url = new URL(window.location.href);
          url.searchParams.set("mode", "view");
          history.replace(url.pathname + url.search);
          setDisableForm(true);
        } else {
          setDisableForm(true);
        }
        break;
      default:
        setDisableForm(true);
    }
  }, [permission, search, isCreate, history]);

  return { disableForm }
}

export default useDisableForm;