import { dynamicConfig } from ".";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const useFormConfig = () => {
  const selectedCountryCode = useSelector((state) => state.selectedCountry.selectedCountry);
  const adminCountryCode = useSelector((state) => state.authDetails.countryCode);

  const config = useMemo(() => {
    let currentCountryCode;
    if (adminCountryCode === "*" || adminCountryCode === "Global") currentCountryCode = selectedCountryCode;
    else currentCountryCode = adminCountryCode;
    let countryCode = currentCountryCode;
    if (countryCode && dynamicConfig[countryCode.toLowerCase()]) return dynamicConfig[countryCode.toLowerCase()];
    return dynamicConfig["global"];
  }, [adminCountryCode, selectedCountryCode])

  return config;
}

export default useFormConfig;