import {
  Dialog,
  DialogTitle,
  DialogContent,
  Chip,
  TextField,
  Grid,
  DialogActions,
  Button,
} from "@material-ui/core";
import CustomCalendar from "../common/custom-calendar";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { update } from "../../services/api/common";
import {
  hideModal,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../redux/dispatchers";
import { string, number, array, lazy, validateFields } from "../../config/validation/yup";
import { addDays } from "date-fns";

const ApproveRequestDialog = () => {
  const { type, visible, data } = useSelector((state) => state.modal);
  const [additionalData, setAdditionalData] = useState({});
  const [error, setError] = useState({});

  const handleOnClick = async () => {
    const [hasError, error] = await validateFields(
      additionalData,
      {
        accessExpiry: lazy((value) =>
          value === "" ? string().required() : string().required().checkDate()
        ),
        reminders: array().of(number().min(1)),
      },
      false
    );
    if (!hasError) {
      data["status"] = "Approved";
      const {
        reloadData,
        setReloadData,
      } = data;
      update("requests", {
        id: data._id,
        status: "Approved",
        accessExpiry: additionalData.accessExpiry,
        reminders: additionalData.reminders,
      })
        .then(() => {
          hideModal();
          showSuccessSnackbar("The request has been updated");
          if (!!setReloadData) setReloadData(!reloadData);
        })
        .catch((e) => showErrorSnackbar(e));
    }
    setError(error);
  };

  useEffect(() => {
    for (var i = 0; i < additionalData?.reminders?.length; i++) {
      if (error?.[`reminders[${i}]`]) {
        setError({ ...error, reminders: error[`reminders[${i}]`] });
        return;
      }
    }
  }, [error, additionalData?.reminders]);

  const handleDismiss = () => {
    hideModal();
    setError({});
    setAdditionalData({});
  };

  return (
    <Dialog
      open={visible && type === "approveRequestModal"}
      onClose={handleDismiss}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Approving Edit Request</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomCalendar
              label="Access expires on"
              name="accessExpiry"
              minDate={addDays(new Date(), 1)}
              variant="outlined"
              onChange={(e) => {
                setAdditionalData({
                  ...additionalData,
                  accessExpiry: e.target.value,
                });
              }}
              error={!!error?.accessExpiry}
              helperText={error?.accessExpiry}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="tags-filled"
              options={["1", "2", "3"]}
              defaultValue={[]}
              onChange={(event, value) => {
                setAdditionalData({ ...additionalData, reminders: value });
              }}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option + " day" + (parseInt(option) === 1 ? "" : "s")}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="reminders"
                  variant="outlined"
                  label="Reminders"
                  placeholder="remind before"
                  error={error?.reminders}
                  helperText={error?.reminders}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button type="outlined" onClick={handleOnClick}>
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveRequestDialog;
