import { SET_DISABLE_SELECT_COUNTRY, SET_FACILITY_COUNTRY, SET_SELECTED_COUNTRY } from "../types";

const initialState = {
  selectedCountry: "Global",
  disable: false,
  facilityCountry: ""
};

const selectedCountryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload.selectedCountry === "*" ? "Global" : action.payload.selectedCountry,
      };
    case SET_DISABLE_SELECT_COUNTRY:
      return {
        ...state,
        disable: action.payload.disable,
      };
    case SET_FACILITY_COUNTRY:
      return {
        ...state,
        facilityCountry: action.payload.facilityCountry,
      };
    default:
      return state;
  }
};

export default selectedCountryReducer;
