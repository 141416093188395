import React, { Fragment, useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { authenticate } from "../../services/logic/auth";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { mainMenuListItem } from "./main-menu-listitems";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Footer } from "../footer/footer";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  setSelectedCountry,
  showErrorSnackbar,
} from "../../redux/dispatchers";
import { query } from "../../services/api/common";
import useCommonAuthorization from "../../hooks/useCommonAuthorization";
import { Action } from "../../constants/permissions"

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    color: "white",
    display: "flex",
    overflow: "hidden",
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    background: "rgb(0, 71, 136)",
    color: "#5c5c5c",
    overflow: "hidden",
  },
  drawerHeader: {
    flexGrow: 0,
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerIcon: {
    color: "white",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    display: "flex",
    flexDirection: "column",
    maxWidth: "99vw",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
    maxWidth: `calc(99vw - ${drawerWidth}px)`
  },
  active: {
    opacity: 1,
    color: "white",
    wordBreak: "break-all",
    background: "rgb(255, 255, 255, 0.3)",
  },
  name: {
    marginRight: "auto",
    opacity: 0.9,
  },
  logout: {
    color: "white",
    "&:hover": {
      background: "rgb(254, 0, 0)",
    },
  },
  logoutList: {
    flexGrow: 0,
    flexShrink: 0,
  },
  main: {
    opacity: 0.75,
    color: "white",
  },
  sub: {
    wordBreak: "break-all",
  },
  link: {
    color: "rgba(255, 255, 1)",
    "&:hover": {
      color: "white",
    },
    textDecoration: "none",
  },
  nameDiv: {
    display: "inline-block",
    marginLeft: 15,
    marginRight: "auto",
    color: "white",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  toolbar: {
    background: "rgb(0, 92, 179, 0.5)",
    color: "white",
  },
  component: {
    padding: theme.spacing(3),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  listItemIcon: {
    color: "white",
  },
  logoutIcon: {
    color: "white",
  },
  countryPicker: {
    padding: theme.spacing(3),
    flexGrow: 0,
    flexShrink: 1,
  },
  mainMenuList: {
    flexGrow: 2,
    flexShrink: 2,
    overflow: "auto"
  }
}));

export const MainMenu = ({ showMenu, component }) => {
  const location = useLocation();
  const name = useSelector((state) => state.authDetails.name);
  const authDetails = useSelector((state) => state.authDetails);
  const title = useSelector((state) => state.title.title);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [expand, setExpand] = useState(true);

  const [countryOptions, setCountryOptions] = useState([]);

  const selectedCountry = useSelector((state) => state.selectedCountry.selectedCountry);
  const disableSelectCountry = useSelector((state) => state.selectedCountry.disable);

  useEffect(() => {
    authenticate();
  }, [])

  const checkRole = useCommonAuthorization();

  const selectedCountryCode = useSelector((state) => state.selectedCountry.selectedCountry);

  useEffect(() => {
    const isOutOfRange = countryOptions.every((option) => option.countryCode !== selectedCountryCode);
    if (!isOutOfRange) {
      return;
    }
    if (authDetails.countryCode === null) {
      setSelectedCountry("Global");
      return;
    }
    const hasCountryInOptions = countryOptions.some((country) => country.countryCode === authDetails.countryCode);
    if (hasCountryInOptions) {
      setSelectedCountry(authDetails.countryCode);
    } else {
      setSelectedCountry("Global");
    }
  }, [countryOptions, authDetails.countryCode, selectedCountryCode]);

  useEffect(() => {
    if (authDetails.countryCode === null) {
      return;
    }
    query("country/options").then((data) => {
      setCountryOptions(data.data);
    }).catch(() => {
      setCountryOptions([]);
    })
  }, [authDetails]);

  const handleChange = (event) => {
    setSelectedCountry(event.target.value);
  };
  //country dropdown

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleExpand = (index) => {
    setExpand(!expand);
  };

  const unsavedChanges = useSelector((state) => state.unsavedAlert.unsaved)

  const facilityCountryRef = useRef(null);
  useEffect(() => {
    if (facilityCountryRef.current !== null) {
      if (facilityCountryRef.current !== selectedCountryCode && unsavedChanges) {
        window.alert("You are moving to another country. If you are filling in a form, some fields may have been altered. Please check your entry again.")
      }
    }
    facilityCountryRef.current = selectedCountryCode;
  }, [selectedCountryCode, unsavedChanges]);

  if (!showMenu) {
    return component;
  }

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar className={classes.toolbar} variant={"dense"}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6">{title}</Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <div className={classes.nameDiv}>
              <Typography variant="subtitle1" className={classes.name}>
                {name}
              </Typography>
              <Link to="/profile?mode=edit" className={classes.link}>
                View Profile
              </Link>
            </div>
            <IconButton
              onClick={handleDrawerClose}
              className={classes.drawerIcon}
            >
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <div className={classes.countryPicker} onClickCapture={() => {
            if (disableSelectCountry) showErrorSnackbar("Please clear any facility or country selection in the form.")
          }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" sx={{ color: 'white' }}>Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCountry}
                label="Country"
                onChange={handleChange}
                disabled={disableSelectCountry || countryOptions.length <= 1}
                sx={{
                  color: "white",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: "white !important",
                  }
                }}
              >
                <MenuItem value="Global">Global</MenuItem>
                {countryOptions.map((country) => <MenuItem value={country.countryCode}>{country.countryName}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <List className={classes.mainMenuList}>
            {mainMenuListItem.map((listItem, index) => {
              const {
                id,
                title,
                pathname,
                icon,
                subject,
                collapsible,
                children,
                enabled,
              } = listItem;
              if ((subject === undefined || ([Action.Create, Action.Read, Action.Delete].every((action) => checkRole(subject, action)) && [Action.Update, Action.Export].some((action) => checkRole(subject, action)))) && enabled === "true") {
                if (!collapsible) {
                  return (
                    <ListItem
                      key={id}
                      className={
                        location.pathname === `${pathname}`
                          ? classes.active
                          : classes.main
                      }
                      button
                      component={Link}
                      to={pathname}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={title} />
                    </ListItem>
                  );
                } else {
                  return (
                    <Fragment key={id}>
                      <ListItem
                        button
                        className={classes.main}
                        onClick={() => handleExpand(index)}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={title} />
                        {expand ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse
                        in={expand}
                        timeout="auto"
                        unmountOnExit
                        className={classes.nested}
                      >
                        <List>
                          {children.map((child) => {
                            const { id, pathname, title, icon, enabled } =
                              child;
                            if (((subject === undefined || ([Action.Create, Action.Read, Action.Delete].every((action) => checkRole(subject, action)) && [Action.Update, Action.Export].some((action) => checkRole(subject, action))))) && enabled === "true") {
                              return (
                                <ListItem
                                  key={id}
                                  className={
                                    location.pathname === `${pathname}`
                                      ? classes.active
                                      : classes.main
                                  }
                                  button
                                  component={Link}
                                  to={pathname}
                                >
                                  <ListItemIcon className={classes.listItemIcon}>
                                    {icon}
                                  </ListItemIcon>
                                  <ListItemText primary={title} />
                                </ListItem>
                              );
                            }
                            return null;
                          })}
                        </List>
                      </Collapse>
                    </Fragment>
                  );
                }
              }
              return null;
            })}
          </List>
          <List classes={classes.logoutList}>
            <ListItem
              button
              key={"logout"}
              to={"/signout"}
              component={Link}
              className={classes.logout}
            >
              <ListItemIcon className={classes.logoutIcon}>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={"Log Out"} />
            </ListItem>
          </List>
        </Drawer>
        <div className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}>
          <Toolbar variant="dense" />
          <div className={classes.component}>{component}</div>
          <Footer showMenu={showMenu} />
        </div>
      </div>
    </>
  );
};
