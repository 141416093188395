export const SEX_CHOICES = [
  { id: "male", name: "Male" },
  { id: "female", name: "Female" },
  { id: "others", name: "Others" },
  { id: "na", name: "NA" },
];

export const ETHNIC_CHOICES = [
  { id: "malay", name: "Malay" },
  { id: "chinese", name: "Chinese" },
  { id: "indian", name: "Indian" },
  { id: "bumiputeraSabah", name: "Bumiputera Sabah" },
  { id: "bumiputeraSarawak", name: "Bumiputera Sarawak" },
  { id: "orangAsli", name: "Orang Asli" },
  { id: "others", name: "Others" },
  { id: "na", name: "NA" },
];

export const VIETNAM_ETHNIC_CHOICES = [
  { id: "kinh", name: "Kinh" },
  { id: "chinese", name: "Chinese" },
  { id: "others", name: "Others" },
];

export const PAYMENT_STATUS_CHOICES = [
  { id: "pensioner", name: "Pensioner" },
  { id: "public", name: "Public" },
  { id: "private", name: "Private" },
  { id: "student", name: "Student" },
  { id: "fullPaying", name: "Full Paying" },
  { id: "na", name: "NA" },
];

export const VIETNAM_PAYMENT_STATUS_CHOICES = [
  { id: "insurance", name: "Insurance" },
  { id: "noInsurance", name: "No Insurance" },
];

export const EDUCATION_LEVEL_CHOICES = [
  { id: "noFormalEducation", name: "No Formal Education" },
  { id: "primary", name: "Primary" },
  { id: "secondary", name: "Secondary" },
  { id: "tertiary", name: "Tertiary" },
  { id: "others", name: "Others" },
  { id: "na", name: "NA" },
];

export const VIETNAM_EDUCATION_LEVEL_CHOICES = [
  { id: "illiterate", name: "Illiterate" },
  { id: "primarySchool", name: "Primary school" },
  { id: "highSchool", name: "High school" },
  { id: "university", name: "University" },
  { id: "postGraduate", name: "Post-graduate" },
];

export const NUMBER_OF_PREGNANCY = [
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "3", name: ">/=3" },
  { id: "na", name: "NA" },
];

export const PREV_GDM = [
  { id: "0-<1", name: "0-<1 Years" },
  { id: "1-<5", name: "1-<5 Years" },
  { id: "5-<10", name: "5-<10 Years" },
  { id: ">=10", name: ">=10 Years" },
  { id: "na", name: "NA" },
];

export const GDM_CHOICES = [
  { id: "yes", name: "Yes" },
  { id: "no", name: "No" },
  { id: "na", name: "NA" },
];
