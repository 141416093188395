import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton, Tooltip } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { Link } from "react-router-dom";

export function ActionColumn(props) {
  const {
    appendUrl,
    data,
    reloadData,
    setReloadData,
    actionConfig: {
      // functions for action buttons
      // return undefined from these function to disable/hide the button
      view: { getViewId = undefined, tooltip: viewTooltip, destination: viewDestination, isNewTab: viewIsNewTab },
      edit: { getEditId = undefined, tooltip: editTooltip, destination: editDestination, isNewTab: editIsNewTab },
      yes: { getYesAction = undefined, tooltip: yesTooltip },
      no: { getNoAction = undefined, tooltip: noTooltip },
    }
  } = props;
  const append = !!appendUrl ? `${appendUrl}` : "";

  let currentViewId = data.id;
  if (getViewId !== undefined && typeof getViewId === "function") {
    currentViewId = getViewId(data);
  }

  let currentEditId = data.id;
  if (getEditId !== undefined && typeof getEditId === "function") {
    currentEditId = getEditId(data);
  }

  let handleYesAction = undefined;
  if (getYesAction !== undefined && typeof getYesAction === "function") {
    handleYesAction = getYesAction(data);
    if (typeof handleYesAction !== "function") {
      handleYesAction = undefined;
    }
  }

  let handleNoAction = undefined;
  if (getNoAction !== undefined && typeof getNoAction === "function") {
    handleNoAction = getNoAction(data);
    if (typeof handleNoAction !== "function") {
      handleNoAction = undefined;
    }
  }

  return (
    <>
      <View
        destination={viewDestination}
        id={currentViewId}
        append={append}
        newTab={!!viewIsNewTab}
        disabled={!!!currentViewId}
        tooltip={viewTooltip}
      />
      <Edit
        destination={editDestination}
        id={currentEditId}
        append={append}
        newTab={!!editIsNewTab}
        disabled={!!!currentEditId}
        tooltip={editTooltip}
      />
      {(!!handleYesAction || !!handleNoAction) && <>
        <Yes
          tooltip={yesTooltip}
          handleOnClick={(action) => {
            if (typeof handleYesAction === 'function') handleYesAction(action, data, reloadData, setReloadData);
          }}
          disabled={!!!handleYesAction}
        />
        <No
          tooltip={noTooltip}
          handleOnClick={(action) => {
            if (typeof handleNoAction === 'function') handleNoAction(action, data, reloadData, setReloadData);
          }}
          disabled={!!!handleNoAction}
        />
      </>}
    </>
  );
}

export function checkVisibility(obj, data) {
  if (obj.showWhen === undefined || obj.showWhen?.length < 1) return true;
  let resultArr = [];
  for (let i = 0; i < obj.showWhen.length; i++) {
    let field = obj.showWhen[i].field;
    let value = obj.showWhen[i].value;
    //if the value property is an array
    if (!!value?.length && typeof value !== "string") {
      let subResultArr = [];
      for (let j = 0; j < value.length; j++) {
        subResultArr.push(data[field] === value[j]);
      }
      resultArr.push(subResultArr);
    } else if (value === undefined) {
      resultArr.push(!!data[field]);
    } else {
      resultArr.push(data[field] === value);
    }
  }
  return resultArr.reduce((previousValue, currentValue) => {
    if (Array.isArray(currentValue)) {
      currentValue = currentValue.includes(true);
    }
    return previousValue && currentValue;
  }, true);
}

function View({ tooltip, destination, id, append, newTab = false, disabled = false }) {
  let target = "_self";
  let rel = "";
  if (newTab) {
    target = "_blank";
    rel = "noopener noreferrer";
  }
  return (
    <Link
      to={disabled ? "#" : `${destination}/${id}${append}?mode=view`}
      target={target}
      rel={rel}
      onClick={(e) => e.stopPropagation()}
      onClickCapture={(e) => {
        if (disabled) {
          e.stopPropagation();
          e.preventDefault();
        };
      }}
      style={{ pointerEvents: disabled ? "none" : "auto" }}
      color={"secondary"}
    >
      <Tooltip title={tooltip ?? "View"}>
        <IconButton size="small" disabled={disabled}>
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
    </Link>
  );
}

function Edit({ tooltip, destination, id, append, newTab = false, disabled = false }) {
  let target = "_self";
  let rel = "";
  if (newTab) {
    target = "_blank";
    rel = "noopener noreferrer";
  }
  return (
    <Link
      to={disabled ? "#" : `${destination}/${id}${append}?mode=edit`}
      target={target}
      rel={rel}
      onClick={(e) => e.stopPropagation()}
      onClickCapture={(e) => {
        if (disabled) {
          e.stopPropagation();
          e.preventDefault();
        };
      }}
      color={"secondary"}
      style={{ pointerEvents: disabled ? "none" : "auto" }}>
      <Tooltip title={tooltip ?? "Edit"}>
        <IconButton size="small" disabled={disabled}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    </Link >
  );
}

function Yes({ tooltip = "Approve", handleOnClick, disabled }) {
  return (
    <Tooltip title={tooltip}>
      <IconButton
        size="small"
        style={{ color: disabled ? "rgba(0, 0, 0, 0.26)" : "green" }}
        onClick={(e) => { e.stopPropagation(); handleOnClick("approve") }}
        disabled={disabled}
      >
        <CheckCircleIcon />
      </IconButton>
    </Tooltip>
  );
}

function No({ tooltip = "Deny", handleOnClick, disabled }) {
  return (
    <Tooltip title={tooltip}>
      <IconButton
        size="small"
        style={{ color: disabled ? "rgba(0, 0, 0, 0.26)" : "red" }}
        onClick={(e) => { e.stopPropagation(); handleOnClick("deny") }}
        disabled={disabled}
      >
        <CancelIcon />
      </IconButton>
    </Tooltip>
  );
}

export function StatusColumn({ status, color }) {

  return (
    <Tooltip title={status}>
      <div
        style={{
          display: "flex",
          height: "20px",
          width: "20px",
          backgroundColor: color,
          borderRadius: "50%",
        }}
      ></div>
    </Tooltip>
  );
}
