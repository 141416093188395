import { CustomTable } from "../common/custom-table";
import React, { useEffect, useState } from "react";
import { clearFormData, setTitle } from "../../redux/dispatchers";
import { useSelector } from "react-redux";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import useCommonAuthorization from "../../hooks/useCommonAuthorization";
import { Action, Subject } from "../../constants/permissions";

import { update } from "../../services/api/common";
import {
  hideModal,
  showErrorSnackbar,
  showModal,
  showSuccessSnackbar,
} from "../../redux/dispatchers";

const MedicalRecordList = () => {
  const countryCode = useSelector((state) => state.selectedCountry.selectedCountry);
  const [checked, setChecked] = useState("");

  useEffect(() => {
    setTitle("Medical Records");
    clearFormData();
  });

  const checkRole = useCommonAuthorization();

  return (
    <CustomTable
      old
      source="medicalRecords"
      noCreate={!checkRole(Subject.Record, Action.Create)}
      noSearch={!checkRole(Subject.Record, Action.Search)}
      noExport={!checkRole(Subject.Record, Action.Export)}
      noSelectAll={!checkRole(Subject.Record, Action.Delete)}
      canSelectRow={(row) => row?.permissions?.[Action.Delete] ?? false}
      defaultOrderBy="submittedOn"
      defaultOrder={2}
      searchBarPlaceholder="Patient Code/Patient IC/Facility Name/Facility Code/Created By"
      onClick={() => { }}
      filters={[
        { filterItem: "isDraft", filterValue: false },
        { filterItem: "isArchive", filterValue: false },
        ...(checked !== ""
          ? [{ filterItem: "status", filterValue: checked }]
          : []),
        ...(countryCode !== "Global" ? [{
          filterItem: "countryCode",
          filterValue: countryCode,
        }] : [])
      ]}
      fields={[
        {
          id: "patientCode",
          label: "Patient Code",
        },
        {
          id: "facilityName",
          label: "Facility Name",
        },
        {
          id: "clinicType",
          label: "Clinic Type",
        },
        {
          id: "facilityCode",
          label: "Facility Code",
        },
        {
          id: "createdBy",
          label: "Created By",
        },
        {
          id: "submittedOn",
          label: "Completed On",
          date: true,
        },
        {
          id: "status",
          label: "Status",
          status: true,
          colorScheme: {
            "Completed": "grey",
            "Approved": "green",
            "Denied": "red",
            "Pending": "orange",
          }
        },
        {
          id: "actions",
          label: "Actions",
          action: true,
          actionConfig: {
            view: {
              destination: "medicalRecords",
              newTab: true,
              getViewId: (row) => {
                if (row?.permissions?.[Action.Read]) {
                  return row.id;
                }
                return undefined;
              },
            },
            edit: {
              destination: "medicalRecords",
              getEditId: (row) => {
                if (row?.permissions?.[Action.Update]) {
                  return row.id;
                }
                return undefined;
              },
              newTab: false,
            },
            yes: {
              showWhen: [{ field: "status", value: "Pending" }],
              getYesAction: (row) => {
                if (row?.status !== "Pending" || !row?.permissions?.[Action.Approve]) return undefined;
                return (action, data, reloadData, setReloadData) => {
                  let title = action === "approve" ? "Approving" : "Denying";
                  showModal({
                    type: "confirmationModal",
                    title: `${title} Medical Record`,
                    content: `Are you sure you want to ${action} this medical record?`,
                    confirm: "Confirm",
                    cancel: "Cancel",
                    onConfirm: () => {
                      if (action === "approve") {
                        data["status"] = "Approved";
                      } else {
                        data["status"] = "Denied";
                      }
                      const { _id, ...rest } = data;
                      update("medicalRecords/status", rest)
                        .then(() => {
                          showSuccessSnackbar("The medical record status has been updated");
                          if (!!setReloadData) setReloadData(!reloadData);
                        })
                        .catch((e) => showErrorSnackbar(e))
                        .finally(() => hideModal());
                    },
                  });
                }
              },
            },
            no: {
              showWhen: [{ field: "status", value: "Pending" }],
              getNoAction: (row) => {
                if (row?.status !== "Pending" || !row?.permissions?.[Action.Deny]) return undefined;
                return (action, data, reloadData, setReloadData) => {
                  let title = action === "approve" ? "Approving" : "Denying";
                  showModal({
                    type: "confirmationModal",
                    title: `${title} Medical Record`,
                    content: `Are you sure you want to ${action} this medical record?`,
                    confirm: "Confirm",
                    cancel: "Cancel",
                    onConfirm: () => {
                      if (action === "approve") {
                        data["status"] = "Approved";
                      } else {
                        data["status"] = "Denied";
                      }
                      const { _id, ...rest } = data;
                      update("medicalRecords/status", rest)
                        .then(() => {
                          showSuccessSnackbar("The medical record status has been updated");
                          if (!!setReloadData) setReloadData(!reloadData);
                        })
                        .catch((e) => showErrorSnackbar(e))
                        .finally(() => hideModal());
                    },
                  });
                }
              },
            },
          }
        },
      ]}
      createAction={clearFormData}
      filterSelection={
        <FilterSelection checked={checked} setCheck={setChecked} />
      }
    />
  );
};

function FilterSelection({ checked, setCheck }) {
  return (
    <FormControl style={{ margin: 10 }}>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked === "Pending"}
              onChange={(e) => setCheck(e.target.checked ? "Pending" : "")}
            />
          }
          label="Pending Approval"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checked === "Approved"}
              onChange={(e) => setCheck(e.target.checked ? "Approved" : "")}
            />
          }
          label="Approved"
        />
      </FormGroup>
    </FormControl>
  );
}

export default MedicalRecordList;
