import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import { FormHelperText, Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

export default function CustomRadioButton({
  label,
  choices,
  value,
  setValue,
  name,
  error,
  helperText,
}) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="body1">{label}</Typography>
      <FormControl component="fieldset" error={error}>
        <RadioGroup
          value={value}
          onChange={(e) => setValue(e)}
          style={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "flex-start",
          }}
          name={name}
        >
          {choices.map((option) => (
            <Tooltip
              disableHoverListener={option.tooltip === undefined}
              disableFocusListener={option.tooltip === undefined}
              key={option.id}
              title={
                <span style={{ whiteSpace: "pre-line", fontSize: "1.3em" }}>
                  {option.tooltip}
                </span>
              }
            >
              <FormControlLabel
                value={option.id}
                key={option.id}
                control={<Radio />}
                label={
                  <>
                    <span>{option.name}</span>
                    {option.tooltip !== undefined && (
                      <InfoOutlinedIcon fontSize="small" />
                    )}
                  </>
                }
                style={{ marginRight: 20 }}
              />
            </Tooltip>
          ))}
        </RadioGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
}
