
export const conditionFormConfig = [
  [
    {
      name: "hypertension.choice",
      label: "Hypertension",
      state: "na",
      // date_label: "Date of diagnosis",
      component: {
        type: "RadioWithTooltip",
        props: {
          required: true,
          row: true,
          tooltipText: "BP >=140 / >=90 or on BP-lowering therapy",
        },
        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "hypertension.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "hypertension.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "hyperlipidaemia.choice",
      label: "Hyperlipidaemia",
      state: "na",
      // date_label: "Date of diagnosis",
      component: {
        type: "RadioWithTooltip",
        props: {
          required: true,
          row: true,
          tooltipText: "Total chol >= 5.2 mmol/L or on lipid-lowering therapy",
        },
        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "hyperlipidaemia.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "hyperlipidaemia.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "overweightObesity.choice",
      label: "Overweight/Obesity",
      state: "na",
      // date_label: "Date of diagnosis",
      component: {
        type: "RadioWithTooltip",
        props: {
          required: true,
          row: true,
          tooltipText:
            "BMI >= 23 kg/m2, or waist circumference >=90cm in men, >=80cm in women",
        },
        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "overweightObesity.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "overweightObesity.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "ischaemicHeartDisease.choice",
      label: "Ischaemic heart disease",
      state: "na",
      // date_label: "Date of diagnosis",
      component: {
        type: "Radio",
        props: {
          required: true,
          row: true,
        },
        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "ischaemicHeartDisease.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "ischaemicHeartDisease.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
    [
      [
        {
          name: "ischaemicHeartDisease.subcondition.acuteCoronarySyndrome.choice",
          label: "Acute Coronary Syndrome",
          state: "na",
          visibility: [
            {
              name: "ischaemicHeartDisease.choice",
              value: "yes",
            },
          ],
          component: {
            type: "Radio",
            props: {
              required: true,
              row: true,
            },
            choices: [
              {
                id: "yes",
                name: "Yes",
              },
              {
                id: "no",
                name: "No",
              },
              {
                id: "na",
                name: "NA",
              },
            ],
          },
        },
        {
          name: "ischaemicHeartDisease.subcondition.acuteCoronarySyndrome.select",
          label: "Number of events",
          state: "1",
          visibility: [
            {
              name: "ischaemicHeartDisease.subcondition.acuteCoronarySyndrome.choice",
              value: "yes",
            },
          ],
          component: {
            type: "SelectField",
            props: { required: true },
            choices: [
              { id: "1", name: "1" },
              { id: ">/=2", name: ">/=2" },
              { id: "na", name: "NA" },
            ],
          },
        },
        {
          name: "ischaemicHeartDisease.subcondition.acuteCoronarySyndrome.date",
          label: "Date of first event",
          state: "",
          visibility: [
            {
              name: "ischaemicHeartDisease.choice",
              value: "yes",
            },
            {
              name: "ischaemicHeartDisease.subcondition.acuteCoronarySyndrome.choice",
              value: "yes",
            },
          ],
          component: {
            type: "DateField",
            props: {
              required: true,
            },
          },
        },
      ],
    ],
    [
      [
        {
          name: "ischaemicHeartDisease.subcondition.percutaneousCoronaryIntervention.choice",
          label: "Percutaneous coronary intervention",
          state: "na",
          visibility: [
            {
              name: "ischaemicHeartDisease.choice",
              value: "yes",
            },
          ],
          component: {
            type: "Radio",
            props: {
              required: true,
              row: true,
            },
            choices: [
              {
                id: "yes",
                name: "Yes",
              },
              {
                id: "no",
                name: "No",
              },
              {
                id: "na",
                name: "NA",
              },
            ],
          },
        },
        {
          name: "ischaemicHeartDisease.subcondition.percutaneousCoronaryIntervention.date",
          label: "Date of first procedure",
          state: "",
          visibility: [
            {
              name: "ischaemicHeartDisease.choice",
              value: "yes",
            },
            {
              name: "ischaemicHeartDisease.subcondition.percutaneousCoronaryIntervention.choice",
              value: "yes",
            },
          ],
          component: {
            type: "DateField",
            props: {
              required: true,
            },
          },
        },
      ],
    ],
    [
      [
        {
          name: "ischaemicHeartDisease.subcondition.coronaryArteryBypassGraft.choice",
          label: "Coronary artery bypass graft",
          state: "na",
          visibility: [
            {
              name: "ischaemicHeartDisease.choice",
              value: "yes",
            },
          ],
          component: {
            type: "Radio",
            props: {
              required: true,
              row: true,
            },
            choices: [
              {
                id: "yes",
                name: "Yes",
              },
              {
                id: "no",
                name: "No",
              },
              {
                id: "na",
                name: "NA",
              },
            ],
          },
        },
        {
          name: "ischaemicHeartDisease.subcondition.coronaryArteryBypassGraft.date",
          label: "Date of the first procedure",
          state: "",
          visibility: [
            {
              name: "ischaemicHeartDisease.choice",
              value: "yes",
            },
            {
              name: "ischaemicHeartDisease.subcondition.coronaryArteryBypassGraft.choice",
              value: "yes",
            },
          ],
          component: {
            type: "DateField",
            props: {
              required: true,
            },
          },
        },
      ],
    ],
  ],
  [
    {
      name: "ischaemicStroke.choice",
      label: "Ischaemic stroke",
      state: "na",
      component: {
        type: "Radio",
        props: { required: true },
        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "ischaemicStroke.select",
      label: "Number of events",
      state: "1",
      visibility: [
        {
          name: "ischaemicStroke.choice",
          value: "yes",
        },
      ],
      component: {
        type: "SelectField",
        props: { required: true },
        choices: [
          { id: "1", name: "1" },
          { id: ">/=2", name: ">/=2" },
          { id: "na", name: "NA" },
        ],
      },
    },
    {
      name: "ischaemicStroke.date",
      label: "Date of first event",
      state: "",
      visibility: [
        {
          name: "ischaemicStroke.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: { required: true },
      },
    },
  ],
  [
    {
      name: "haemorrhagicStroke.choice",
      label: "Haemorrhagic stroke",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },
        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "haemorrhagicStroke.date",
      label: "Date of diagnosis",
      visibility: [
        {
          name: "haemorrhagicStroke.choice",
          value: "yes",
        },
      ],
      state: "",
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "unspecifiedStroke.choice",
      label: "Unspecified stroke",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },
        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "unspecifiedStroke.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "unspecifiedStroke.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "hospitalizationForHeartFailure.choice",
      label: "Hospitalization for heart failure",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },
        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "hospitalizationForHeartFailure.select",
      label: "Number of events",
      state: "1",
      visibility: [
        {
          name: "hospitalizationForHeartFailure.choice",
          value: "yes",
        },
      ],
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: [
          { id: "1", name: "1" },
          { id: "2", name: "2" },
          { id: ">/=3", name: ">/=3" },
          { id: "na", name: "NA" },
        ],
      },
    },
    {
      name: "hospitalizationForHeartFailure.date",
      label: "Date of first event",
      state: "",
      visibility: [
        {
          name: "hospitalizationForHeartFailure.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "peripheralArterialDisease.choice",
      label: "Peripheral arterial disease",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },

        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "peripheralArterialDisease.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "peripheralArterialDisease.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "lowerExtremityAmputation.choice",
      label: "Lower-extremity amputation",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },

        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "lowerExtremityAmputation.select",
      label: "Number of events",
      state: "1",
      visibility: [
        {
          name: "lowerExtremityAmputation.choice",
          value: "yes",
        },
      ],
      component: {
        type: "SelectField",
        props: {
          required: true,
        },

        choices: [
          { id: "1", name: "1" },
          { id: ">/=2", name: ">/=2" },
          { id: "na", name: "NA" },
        ],
      },
    },
    {
      name: "lowerExtremityAmputation.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "lowerExtremityAmputation.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
          disabled: true,
        },
        derivation: ({
          lowerExtremityAmputation: {
            subcondition: { firstAmputation },
          },
        }) => {
          const { date } = firstAmputation;
          return date;
        },
      },
    },
    {
      name: "lowerExtremityAmputation.reason",
      label: "Reason",
      state: "peripheralArterialDisease",
      visibility: [
        {
          name: "lowerExtremityAmputation.choice",
          value: "yes",
        },
      ],
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: [
          {
            id: "peripheralArterialDisease",
            name: "Peripheral Arterial Disease",
          },
          {
            id: "infectedDiabeticFootUlcer",
            name: "Infected Diabetic Foot Ulcer",
          },
          { id: "necrotisingFasciitis", name: "Necrotising Fasciitis" },
          { id: "gasGangrene", name: "Gas Gangrene" },
          { id: "others", name: "Others" },
          { id: "na", name: "NA" },
        ],
      },
    },
    [
      [
        {
          name: "lowerExtremityAmputation.subcondition.firstAmputation.select",
          label: "First Amputation",
          state: "unilateral",
          visibility: [
            {
              name: "lowerExtremityAmputation.choice",
              value: "yes",
            },
          ],
          component: {
            type: "SelectField",
            props: {
              required: true,
            },
            choices: [
              { id: "unilateral", name: "Unilateral" },
              { id: "bilateral", name: "Bilateral" },
              { id: "na", name: "NA" },
            ],
          },
        },
        {
          name: "lowerExtremityAmputation.subcondition.firstAmputation.select_2",
          label: "First Amputation Site",
          state: "rays",
          visibility: [
            {
              name: "lowerExtremityAmputation.choice",
              value: "yes",
            },
          ],
          component: {
            type: "SelectField",
            props: {
              required: true,
            },

            choices: [
              { id: "rays", name: "Ray's" },
              { id: "midfoot", name: "midfoot" },
              { id: "hindFootDistalLeg", name: "hindfoot/distal leg" },
              { id: "belowKnee", name: "below knee" },
              { id: "transKnee", name: "trans knee" },
              { id: "aboveKnee", name: "above knee" },
              { id: "na", name: "NA" },
            ],
          },
        },
        {
          name: "lowerExtremityAmputation.subcondition.firstAmputation.date",
          label: "Date of first amputation",
          state: "",
          visibility: [
            {
              name: "lowerExtremityAmputation.choice",
              value: "yes",
            },
          ],
          component: {
            type: "DateField",
            props: {
              required: true,
            },
          },
        },
      ],
    ],
  ],
  [
    {
      name: "endStageKidneyDisease.choice",
      label: "End-stage kidney disease",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },

        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "endStageKidneyDisease.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "endStageKidneyDisease.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
        // derivation: ({
        //   endStageKidneyDisease: {
        //     subcondition: { dialysis, kidneyTransplant },
        //   },
        // }) => {
        //   if (!dialysis.date) return kidneyTransplant.date;
        //   if (!kidneyTransplant.date) return dialysis.date;

        //   let minimumDate;
        //   let validDates = [];
        //   let dialysisDate = parseDate(dialysis.date);
        //   let kidneyTransplantDate = parseDate(kidneyTransplant.date);
        //   if (isValid(dialysisDate)) validDates.push(dialysisDate);
        //   if (isValid(kidneyTransplantDate))
        //     validDates.push(kidneyTransplantDate);

        //   if (validDates.length > 0) {
        //     minimumDate = format(min(validDates), "dd/MM/yyyy");
        //   } else minimumDate = ""; // if no date then reset to empty value
        //   return minimumDate;
        // },
      },
    },
    [
      [
        {
          name: "endStageKidneyDisease.subcondition.dialysis.select",
          label: "Dialysis",
          state: "haemodialysis",
          visibility: [
            {
              name: "endStageKidneyDisease.choice",
              value: "yes",
            },
          ],
          component: {
            type: "SelectField",
            props: {
              required: true,
            },

            choices: [
              { id: "haemodialysis", name: "Haemodialysis" },
              { id: "peritonealDialysis", name: "Peritoneal dialysis" },
              { id: "unspecified", name: "Unspecified" },
              { id: "na", name: "NA" },
            ],
          },
        },
        {
          name: "endStageKidneyDisease.subcondition.dialysis.date",
          label: "Date of dialysis initiation",
          state: "",
          visibility: [
            {
              name: "endStageKidneyDisease.choice",
              value: "yes",
            },
            {
              name: "endStageKidneyDisease.subcondition.dialysis.select",
              value: ["haemodialysis", "peritonealDialysis", "unspecified"],
            },
          ],
          component: {
            type: "DateField",
            props: {
              required: true,
            },
          },
        },
      ],
    ],
    [
      [
        {
          name: "endStageKidneyDisease.subcondition.kidneyTransplant.choice",
          label: "Kidney transplant",
          state: "na",
          visibility: [
            {
              name: "endStageKidneyDisease.choice",
              value: "yes",
            },
          ],
          component: {
            type: "Radio",
            props: {
              required: true,
            },

            choices: [
              {
                id: "yes",
                name: "Yes",
              },
              {
                id: "no",
                name: "No",
              },
              {
                id: "na",
                name: "NA",
              },
            ],
          },
        },
        {
          name: "endStageKidneyDisease.subcondition.kidneyTransplant.date",
          label: "Date of transplant",
          state: "",
          visibility: [
            {
              name: "endStageKidneyDisease.subcondition.kidneyTransplant.choice",
              value: "yes",
            },
          ],
          component: {
            type: "DateField",
            props: {
              required: true,
            },
          },
        },
      ],
    ],
  ],
  [
    {
      name: "retinopathy.choice",
      label: "Retinopathy",
      state: "na",
      component: {
        type: "Radio",
        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "retinopathy.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "retinopathy.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
    [
      [
        {
          name: "retinopathy.subcondition.retinopathy_left.choice",
          label: "Retinopathy (Left Eye)",
          state: "na",
          visibility: [
            {
              name: "retinopathy.choice",
              value: "yes",
            },
          ],
          component: {
            type: "Radio",
            choices: [
              {
                id: "yes",
                name: "Yes",
              },
              {
                id: "no",
                name: "No",
              },
              {
                id: "na",
                name: "NA",
              },
            ],
          },
        },
        {
          name: "retinopathy.subcondition.retinopathy_left.select",
          label: "Type",
          state: "nonProliferative",
          visibility: [
            {
              name: "retinopathy.subcondition.retinopathy_left.choice",
              value: "yes",
            },
          ],
          component: {
            type: "SelectField",
            props: {
              required: true,
            },

            choices: [
              { id: "nonProliferative", name: "Non-proliferative" },
              { id: "proliferative", name: "Proliferative" },
            ],
          },
        },
        {
          name: "retinopathy.subcondition.retinopathy_left.nonproliferative_select",
          label: "Condition",
          state: "mild",
          visibility: [
            {
              name: "retinopathy.subcondition.retinopathy_left.choice",
              value: "yes",
            },
            {
              name: "retinopathy.subcondition.retinopathy_left.select",
              value: "nonProliferative",
            },
          ],
          component: {
            type: "SelectField",
            props: {
              required: true,
            },

            choices: [
              { id: "mild", name: "Mild" },
              { id: "moderate", name: "Moderate" },
              { id: "severe", name: "Severe" },
              { id: "unspecified", name: "Unspecified" },
              { id: "na", name: "NA" },
            ],
          },
        },
        {
          name: "retinopathy.subcondition.retinopathy_left.proliferative_select",
          label: "Condition",
          state: "laserPhotocoagulation",
          visibility: [
            {
              name: "retinopathy.subcondition.retinopathy_left.choice",
              value: "yes",
            },
            {
              name: "retinopathy.subcondition.retinopathy_left.select",
              value: "proliferative",
            },
          ],
          component: {
            type: "SelectField",
            props: {
              required: true,
            },

            choices: [
              { id: "laserPhotocoagulation", name: "Laser photocoagulation" },
              { id: "anti-VEGF", name: "anti-VEGF" },
              { id: "unspecified", name: "Unspecified" },
              { id: "na", name: "NA" },
            ],
          },
        },
        {
          name: "retinopathy.subcondition.retinopathy_left.date",
          label: "Date of diagnosis",
          state: "",
          visibility: [
            {
              name: "retinopathy.subcondition.retinopathy_left.choice",
              value: "yes",
            },
          ],
          component: {
            type: "DateField",
            props: {
              required: true,
            },
          },
        },
      ],
    ],
    [
      [
        {
          name: "retinopathy.subcondition.retinopathy_right.choice",
          label: "Retinopathy (Right Eye)",
          state: "na",
          visibility: [
            {
              name: "retinopathy.choice",
              value: "yes",
            },
          ],
          component: {
            type: "Radio",
            choices: [
              {
                id: "yes",
                name: "Yes",
              },
              {
                id: "no",
                name: "No",
              },
              {
                id: "na",
                name: "NA",
              },
            ],
          },
        },
        {
          name: "retinopathy.subcondition.retinopathy_right.select",
          label: "Type",
          state: "nonProliferative",
          visibility: [
            {
              name: "retinopathy.subcondition.retinopathy_right.choice",
              value: "yes",
            },
          ],
          component: {
            type: "SelectField",
            props: {
              required: true,
            },

            choices: [
              { id: "nonProliferative", name: "Non-proliferative" },
              { id: "proliferative", name: "Proliferative" },
            ],
          },
        },
        {
          name: "retinopathy.subcondition.retinopathy_right.nonproliferative_select",
          label: "Condition",
          state: "mild",
          visibility: [
            {
              name: "retinopathy.subcondition.retinopathy_right.choice",
              value: "yes",
            },
            {
              name: "retinopathy.subcondition.retinopathy_right.select",
              value: "nonProliferative",
            },
          ],
          component: {
            type: "SelectField",
            props: {
              required: true,
            },

            choices: [
              { id: "mild", name: "Mild" },
              { id: "moderate", name: "Moderate" },
              { id: "severe", name: "Severe" },
              { id: "unspecified", name: "Unspecified" },
              { id: "na", name: "NA" },
            ],
          },
        },
        {
          name: "retinopathy.subcondition.retinopathy_right.proliferative_select",
          label: "Condition",
          state: "laserPhotocoagulation",
          visibility: [
            {
              name: "retinopathy.subcondition.retinopathy_right.choice",
              value: "yes",
            },
            {
              name: "retinopathy.subcondition.retinopathy_right.select",
              value: "proliferative",
            },
          ],
          component: {
            type: "SelectField",
            props: {
              required: true,
            },

            choices: [
              { id: "laserPhotocoagulation", name: "Laser photocoagulation" },
              { id: "anti-VEGF", name: "anti-VEGF" },
              { id: "unspecified", name: "Unspecified" },
              { id: "na", name: "NA" },
            ],
          },
        },
        {
          name: "retinopathy.subcondition.retinopathy_right.date",
          label: "Date of diagnosis",
          state: "",
          visibility: [
            {
              name: "retinopathy.subcondition.retinopathy_right.choice",
              value: "yes",
            },
          ],
          component: {
            type: "DateField",
            props: {
              required: true,
            },
          },
        },
      ],
    ],
  ],
  [
    {
      name: "metabolicAssociatedFattyLiverDisease.choice",
      label: "Metabolic-associated fatty liver disease",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },

        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "metabolicAssociatedFattyLiverDisease.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "metabolicAssociatedFattyLiverDisease.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
    [
      [
        {
          name: "metabolicAssociatedFattyLiverDisease.subcondition.type.select",
          label: "Type",
          state: "nafl",
          visibility: [
            {
              name: "metabolicAssociatedFattyLiverDisease.choice",
              value: "yes",
            },
          ],
          component: {
            type: "SelectField",
            props: {
              required: true,
            },

            choices: [
              { id: "nafl", name: "NAFL" },
              { id: "nash", name: "NASH" },
              { id: "cirrhosis", name: "cirrhosis" },
              { id: "unspecified", name: "Unspecified" },
              { id: "na", name: "NA" },
            ],
          },
        },
        {
          name: "metabolicAssociatedFattyLiverDisease.subcondition.type.date",
          label: "Date of diagnosis",
          state: "",
          visibility: [
            {
              name: "metabolicAssociatedFattyLiverDisease.choice",
              value: "yes",
            },
            {
              name: "metabolicAssociatedFattyLiverDisease.subcondition.type.select",
              operator: "!==",
              value: "na",
            },
          ],
          component: {
            type: "DateField",
            props: {
              required: true,
            },
          },
        },
      ],
    ],
  ],
  [
    {
      name: "malignancyLipOralCavityAndPharynx.choice",
      label: "Malignancy - lip, oral cavity and pharynx",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },

        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "malignancyLipOralCavityAndPharynx.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "malignancyLipOralCavityAndPharynx.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "malignancyRespiratoryAndIntrathoracicOrgans.choice",
      label: "Malignancy - respiratory and intrathoracic organs",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },

        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "malignancyRespiratoryAndIntrathoracicOrgans.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "malignancyRespiratoryAndIntrathoracicOrgans.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "malignancyGenitourinaryOrgans.choice",
      label: "Malignancy - genitourinary organs",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },

        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "malignancyGenitourinaryOrgans.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "malignancyGenitourinaryOrgans.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "malignancyDigestiveOrganAndPeritoneum.choice",
      label: "Malignancy - digestive organ and peritoneum",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },

        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "malignancyDigestiveOrganAndPeritoneum.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "malignancyDigestiveOrganAndPeritoneum.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "malignancyBreast.choice",
      label: "Malignancy - breast",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },

        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "malignancyBreast.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "malignancyBreast.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "malignancyBoneConnectiveTissueAndSkin.choice",
      label: "Malignancy - bone, connective tissue and skin",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },

        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "malignancyBoneConnectiveTissueAndSkin.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "malignancyBoneConnectiveTissueAndSkin.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "malignancyLymphaticAndHaematopoieticTissue.choice",
      label: "Malignancy - lymphatic and haematopoietic tissue",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },

        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "malignancyLymphaticAndHaematopoieticTissue.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "malignancyLymphaticAndHaematopoieticTissue.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "neuroendocrineTumours.choice",
      label: "Neuroendocrine tumours",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },

        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "neuroendocrineTumours.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "neuroendocrineTumours.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "malignancyOtherAndUnspecifiedSites.choice",
      label: "Malignancy - other and unspecified sites",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },

        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "malignancyOtherAndUnspecifiedSites.text",
      label: "Others",
      state: "",
      visibility: [
        {
          name: "malignancyOtherAndUnspecifiedSites.choice",
          value: "yes",
        },
      ],
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
    {
      name: "malignancyOtherAndUnspecifiedSites.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "malignancyOtherAndUnspecifiedSites.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "osteoporosis.choice",
      label: "Osteoporosis",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },

        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "osteoporosis.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "osteoporosis.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
    [
      [
        {
          name: "osteoporosis.subcondition.fractureHistory.choice",
          label: "Fracture history",
          state: "na",
          visibility: [
            {
              name: "osteoporosis.choice",
              value: "yes",
            },
          ],
          component: {
            type: "Radio",
            props: {
              required: true,
            },

            choices: [
              {
                id: "yes",
                name: "Yes",
              },
              {
                id: "no",
                name: "No",
              },
              {
                id: "na",
                name: "NA",
              },
            ],
          },
        },
        {
          name: "osteoporosis.subcondition.fractureHistory.select",
          label: "First fracture site",
          state: "spine",
          visibility: [
            {
              name: "osteoporosis.subcondition.fractureHistory.choice",
              value: "yes",
            },
          ],
          component: {
            type: "SelectField",
            props: {
              required: true,
            },
            choices: [
              { id: "spine", name: "Spine" },
              { id: "hip", name: "Hip" },
              { id: "distalRadiusColles", name: "distal radius (Colle's)" },
              { id: "na", name: "NA" },
            ],
          },
        },
        {
          name: "osteoporosis.subcondition.fractureHistory.date",
          label: "Date of the first fracture",
          state: "",
          visibility: [
            {
              name: "osteoporosis.subcondition.fractureHistory.choice",
              value: "yes",
            },
          ],
          component: {
            type: "DateField",
            props: {
              required: true,
            },
          },
        },
        {
          name: "osteoporosis.subcondition.fractureHistory.text",
          label: "Number of fractures",
          state: "",
          visibility: [
            {
              name: "osteoporosis.subcondition.fractureHistory.choice",
              value: "yes",
            },
          ],
          component: {
            type: "TextField",
            props: {
              required: true,
            },
          },
        },
      ],
    ],
  ],
  [
    {
      name: "obstructiveSleepApnoea.choice",
      label: "Obstructive sleep apnoea",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },
        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
    {
      name: "obstructiveSleepApnoea.date",
      label: "Date of diagnosis",
      state: "",
      visibility: [
        {
          name: "obstructiveSleepApnoea.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
];
