import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { loginUser } from "../../services/logic/auth";
import CustomTextField from "../common/custom-text-field";
import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import logo from "../../images/logo.svg";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { setSelectedCountry } from "../../redux/dispatchers";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    backgroundColor: "rgb(0, 92, 179, 0.3)",
  },
  logo: {
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: 400,
    },
    textAlign: "center",
  },
  title: {
    textAlign: "center",
  },
  bg: {
    width: "100vw",
    height: "100vh",
    position: "absolute",
    objectFit: "cover",
    zIndex: -2,
    overflow: "hidden",
  },
  form: {
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  errorStyle: {
    color: "red",
    marginBottom: 10,
  },
  componentContainer: {
    textAlign: "center",
    marginBottom: -100,
    marginTop: -100,
  },
  buttonContainer: {
    textAlign: "center",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    loginUser(email, password).then((data) => {
      setSelectedCountry(data?.countryCode);
    }).finally(() => setLoading(false));
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <form className={classes.form} noValidate>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} className={classes.componentContainer}>
              <img src={logo} className={classes.logo} alt="logo" />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.title} variant="h5">
                Target-T2D e-Portal Dashboard
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Email"
                value={email}
                setValue={setEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Password"
                value={password}
                setValue={setPassword}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onLogin(e);
                    e.target.blur();
                  }
                }}
              />
            </Grid>
            <Typography variant="subtitle1">
              <Link to="/forgot">Forgot password?</Link>
            </Typography>
            <Grid item xs={12} className={classes.buttonContainer}>
              <Button
                variant="outlined"
                onClick={onLogin}
                color="primary"
                size="large"
                disabled={loading}
              >
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </React.Fragment>
  );
};
