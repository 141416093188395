import { SHOW_LOADING, HIDE_LOADING } from "../types";

export const showLoadingAction = (message) => {
  return {
    type: SHOW_LOADING,
    payload: { message },
  };
};

export const hideLoadingAction = () => {
  return {
    type: HIDE_LOADING,
  };
};
