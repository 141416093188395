import {
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CustomRadioButton from "../components/common/custom-radio-button";
import CustomCalendar from "../components/common/custom-calendar";
import CustomCheckbox from "../components/common/custom-checkbox";
import ClearIcon from '@material-ui/icons/Clear';

const commonProps = {
  fullWidth: true,
  variant: "outlined",
};

const components = {
  Grid: () => Grid,
  FormControl: () => FormControl,
  // Grid: (props) => <Grid {...props} />,
  TextField: ({ clearable, onClear, name, ...props }) => <TextField name={name} InputProps={{
    endAdornment: (
      clearable && <InputAdornment position="end">
        <IconButton onClick={(e) => onClear(name)}>
          <ClearIcon />
        </IconButton>
      </InputAdornment>
    )
  }} {...commonProps} {...props} />,
  Radio: ({ label, choices, name, onChange, ...props }) => (
    <CustomRadioButton
      name={name}
      label={label}
      choices={choices}
      setValue={onChange}
      {...commonProps}
      {...props}
    />
  ),
  SelectField: ({ choices, clearable, onClear, name, ...props }) => {
    return (
      <TextField name={name} select InputProps={{
        endAdornment: (
          clearable && <InputAdornment position="end">
            <IconButton onClick={(e) => onClear(name)}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        )
      }} {...commonProps} {...props}>
        {choices.map((choice) => (
          <MenuItem key={choice.id} value={choice.id}>
            {choice.name}
          </MenuItem>
        ))}
      </TextField>
    )
  },
  DateField: (props) => <CustomCalendar {...commonProps} {...props} />,
  TextFieldWithAdornment: ({ inputAdornmentText, ...props }) => (
    <TextField
      {...commonProps}
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{inputAdornmentText}</InputAdornment>
        ),
      }}
    />
  ),
  RadioWithTooltip: ({
    name,
    choices,
    onChange,
    tooltipText,
    label,
    ...props
  }) => (
    <CustomRadioButton
      {...commonProps}
      {...props}
      name={name}
      label={
        <span>
          {label}
          <Tooltip
            title={
              <span style={{ whiteSpace: "pre-line", fontSize: "1.3em" }}>
                {tooltipText}
              </span>
            }
          >
            <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
          </Tooltip>
        </span>
      }
      choices={choices}
      setValue={onChange}
    />
  ),
  Checkbox: ({ checkboxChoices, choices, ...props }) => (
    <CustomCheckbox choices={checkboxChoices} {...props} />
  ),
};

export default components;
