import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
} from "@material-ui/core";

const CustomCheckbox = ({
  name,
  choices,
  onChange,
  value,
  error,
  helperText,
}) => {
  return (
    <FormControl error={error}>
      <FormGroup onChange={onChange}>
        {choices.map((choice) => {
          if (value["na"] && choice.id !== "na" && value[choice.id]) {
            onChange({
              target: {
                name: name,
                value: choice.id,
              },
            });
          }
          return (
            <FormControlLabel
              key={choice.id}
              control={
                <Checkbox
                  name={name}
                  value={choice.id}
                  checked={value[choice.id] ?? false}
                  disabled={choice.id === "na" ? false : value["na"] ?? false}
                />
              }
              label={choice.name}
            />
          );
        })}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CustomCheckbox;
