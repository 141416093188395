import { CustomTable } from "../common/custom-table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import RequestDetailsDialog from "./request-details-dialog";
import useCommonAuthorization from "../../hooks/useCommonAuthorization";
import { Action, Subject } from "../../constants/permissions";
import { update } from "../../services/api/common";
import {
  hideModal,
  showErrorSnackbar,
  showModal,
  showSuccessSnackbar,
  setTitle,
} from "../../redux/dispatchers";

export function updateRequestStatus(action, data, reloadData, setReloadData) {
  let title = action === "approve" ? "Approving" : "Denying";
  if (action === "approve" && data.requestType === "Edit") {
    showModal({
      type: "approveRequestModal",
      data: { ...data, reloadData: reloadData, setReloadData: setReloadData },
    });
  } else {
    showModal({
      type: "confirmationModal",
      title: `${title} ${data.requestType} Request`,
      content: `Are you sure you want to ${action} this request?`,
      confirm: "Confirm",
      cancel: "Cancel",
      onConfirm: () => {
        if (action === "approve") {
          data["status"] = "Approved";
        } else {
          data["status"] = "Denied";
        }
        update("requests", data)
          .then(() => {
            showSuccessSnackbar("The request status has been updated");
            if (!!setReloadData) setReloadData(!reloadData);
          })
          .catch((e) => showErrorSnackbar(e))
          .finally(() => hideModal());
      },
    });
  }
}

export default function RequestsList() {
  useEffect(() => setTitle("Requests"));
  const role = useSelector((state) => state.authDetails.role);
  const userId = useSelector((state) => state.authDetails._id);
  const countryCode = useSelector((state) => state.selectedCountry.selectedCountry);
  const [requestType, setRequestType] = useState("");
  const [target, setTarget] = useState("");

  const checkRole = useCommonAuthorization();

  return (
    <>
      <CustomTable
        old
        source="requests"
        noCreate
        noSearch={!checkRole(Subject.Record, Action.Search)}
        noExport={!checkRole(Subject.Record, Action.Export)}
        noSelect={!checkRole(Subject.Record, Action.Delete)}
        defaultOrderBy="createdAt"
        defaultOrder={2}
        onClick={() => { }}
        searchBarPlaceholder="Patient Code/Medical Record ID"
        filters={[
          ...(requestType !== ""
            ? [
              {
                filterItem: "requestType",
                filterValue: requestType,
              },
            ]
            : []),
          ...(target !== ""
            ? target === "requestBy"
              ? [
                {
                  filterItem: "requestBy._id",
                  filterValue: userId,
                },
              ]
              : [
                {
                  filterItem: "assignee._id",
                  filterValue: userId,
                },
                {
                  filterItem: "status",
                  filterValue: "Pending",
                }
              ]
            : []),
          ...(role === "ra"
            ? [
              [
                {
                  filterItem: "requestBy._id",
                  filterValue: userId,
                },
                {
                  filterItem: "assignee._id",
                  filterValue: userId,
                },
              ],
            ]
            : []),
          ...(countryCode !== "Global" ? [{
            filterItem: "countryCode",
            filterValue: countryCode,
          }] : [])
        ]}
        fields={[
          {
            id: "patientCode",
            label: "Patient Code",
          },
          {
            id: "requestBy.name",
            label: "Requested By",
          },
          {
            id: "createdAt",
            label: "Requested On",
            date: true,
          },
          {
            id: "assignee.name",
            label: "Assignee/Reviewer",
          },
          {
            id: "requestType",
            label: "Request Type",
          },
          {
            id: "status",
            label: "Status",
            status: true,
            colorScheme: {
              "Completed": "grey",
              "Approved": "green",
              "Denied": "red",
              "Pending": "orange",
            }
          },
          {
            id: "actions",
            label: "Actions",
            action: true,
            actionConfig: {
              view: {
                destination: "/medicalRecords",
                newTab: true,
                getViewId: (row) => {
                  if (row?.permissions?.[Action.Read]) {
                    return row.recordId;
                  }
                  return undefined;
                },
              },
              edit: {
                destination: "/medicalRecords",
                getEditId: (row) => {
                  if (row?.permissions?.[Action.Update]) {
                    return row.recordDraftId;
                  }
                  return undefined;
                },
                newTab: false,
              },
              yes: {
                showWhen: [{ field: "status", value: "Pending" }],
                getYesAction: (row) => {
                  if (row?.status !== "Pending" || !row?.permissions?.[Action.Approve]) return undefined;
                  return updateRequestStatus;
                },
              },
              no: {
                showWhen: [{ field: "status", value: "Pending" }],
                getNoAction: (row) => {
                  if (row?.status !== "Pending" || !row?.permissions?.[Action.Deny]) return undefined;
                  return updateRequestStatus;
                },
              },
            }
          },
          {
            viewRequest: true,
          },
        ]}
        filterSelection={
          <FilterSelection
            requestType={requestType}
            setRequestType={setRequestType}
            target={target}
            setTarget={setTarget}
            role={role}
            userId={userId}
          />
        }
        viewRequest={(data, reloadData, setReloadData) =>
          showModal({
            type: "requestDetailsModal",
            data: {
              ...data,
              reloadData: reloadData,
              setReloadData: setReloadData,
            },
          })
        }
      />
      <RequestDetailsDialog />
    </>
  );
}

function FilterSelection(props) {
  const { requestType, setRequestType, target, setTarget } =
    props;
  return (
    <FormControl style={{ margin: 10 }}>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={requestType === "Edit"}
              onChange={(e) => setRequestType(e.target.checked ? "Edit" : "")}
            />
          }
          label="Edit Request"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={requestType === "Delete"}
              onChange={(e) => setRequestType(e.target.checked ? "Delete" : "")}
            />
          }
          label="Delete Request"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={target === "requestBy"}
              onChange={(e) => setTarget(e.target.checked ? "requestBy" : "")}
            />
          }
          label="Your request(s)"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={target === "assignee"}
              onChange={(e) => setTarget(e.target.checked ? "assignee" : "")}
            />
          }
          label="Request(s) to review"
        />
      </FormGroup>
    </FormControl>
  );
}
