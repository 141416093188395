import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CustomTextField from "../common/custom-text-field";
import { CircularProgress, Grid, Typography, Box } from "@material-ui/core";
import logo from "../../images/logo.svg";
import { getResetPasswordLink } from "../../services/api/auth";
import { showSuccessSnackbar } from "../../redux/dispatchers";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    backgroundColor: "rgb(0, 92, 179, 0.3)",
  },
  logo: {
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: 400,
    },
    textAlign: "center",
  },
  title: {
    textAlign: "center",
  },
  bg: {
    width: "100vw",
    height: "100vh",
    position: "absolute",
    objectFit: "cover",
    zIndex: -2,
    overflow: "hidden",
  },
  form: {
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  errorStyle: {
    color: "red",
    marginBottom: 10,
  },
  componentContainer: {
    textAlign: "center",
    marginBottom: -100,
    marginTop: -100,
  },
  buttonContainer: {
    textAlign: "center",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    getResetPasswordLink(email).then(() => {
      showSuccessSnackbar("An email has been sent. Please check your inbox.");
    }).catch(() => { }).finally(() => setLoading(false));
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Box className={classes.form}>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} className={classes.componentContainer}>
              <img src={logo} className={classes.logo} alt="logo" />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.title} variant="h5">
                Forgot Password?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Email"
                value={email}
                setValue={setEmail}
                onKeyPress={(e) => {
                  if (e.key === "Enter") onSubmit(e)
                }}
              />
            </Grid>
            <Typography variant="subtitle1">
              <Link to="/signin">Sign in with another account</Link>
            </Typography>
            <Grid item xs={12} className={classes.buttonContainer}>
              <Button
                variant="outlined"
                onClick={onSubmit}
                color="primary"
                size="large"
                disabled={loading}
              >
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </React.Fragment>
  );
};
