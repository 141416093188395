import {
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  TextField,
  Grid,
  Typography,
  ListItem,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { memo } from "react";
import CustomCalendar from "../../common/custom-calendar";

function DrugInput({
  drugDetail,
  drugResponse,
  active,
  handleDrugSelect,
  error,
}) {
  const {
    field_name: fieldName,
    sub_field: subField,
    default_value: defaultValue,
  } = drugDetail;
  const {
    "Common dosing": commonDosingField,
    Others: othersField,
    "Number of tablets": numberOfTabletsField,
    Frequency: frequencyField,
    "Date of Initiation": dateOfInitiationField,
    "Total daily dosing": totalDailyDosingField,
    "Total weekly dosing": totalWeeklyDosingField,
  } = subField;

  const { default_value: dateOfInitiationDefaultValue } =
    dateOfInitiationField ?? {};
  const { default_value: totalDailyDosingDefaultValue } =
    totalDailyDosingField ?? {};
  const { default_value: totalWeeklyDosingDefaultValue } =
    totalWeeklyDosingField ?? {};
  const { default_value: othersDefaultValue } = othersField ?? {};
  const {
    field_options: commonDosingOptions,
    default_value: dosingDefaultValue,
  } = commonDosingField ?? {};
  const {
    field_options: numberOfTabletsOptions,
    default_value: numberOfTabletsDefaultValue,
  } = numberOfTabletsField ?? {};
  const {
    field_options: frequencyOptions,
    default_value: frequencyDefaultValue,
  } = frequencyField ?? {};

  const {
    value = "na",
    dateOfInitiation,
    commonDosing,
    totalDailyDosing,
    totalWeeklyDosing,
    others,
    numberOfTablets,
    frequency,
  } = drugResponse;

  const defaults = {
    value: defaultValue.toLowerCase(),
    dateOfInitiation: dateOfInitiationDefaultValue,
    commonDosing: dosingDefaultValue,
    totalDailyDosing: totalDailyDosingDefaultValue,
    totalWeeklyDosing: totalWeeklyDosingDefaultValue,
    others: othersDefaultValue,
    numberOfTablets: numberOfTabletsDefaultValue,
    frequency: frequencyDefaultValue,
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let changes = { [name]: value };
    if (name === "frequency" && value === "Every Other Day") {
      changes = { ...changes, commonDosing: "others" };
    }
    let actualDefaults = {};
    for (const key in defaults) {
      if (defaults[key] === undefined) continue;
      actualDefaults = { ...actualDefaults, [key]: defaults[key] };
    }
    handleDrugSelect(drugDetail.category, {
      ...actualDefaults,
      ...drugResponse,
      ...changes,
      fieldName,
    });
  };

  return (
    <ListItem divider>
      <Grid container spacing={3} alignItems="center" direction="row">
        <Grid item sm={5}>
          <Typography>{fieldName}</Typography>
        </Grid>
        <Grid item sm={2}>
          <FormControl error={!!error?.value}>
            <RadioGroup name="value" value={value} onChange={handleChange} row>
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label="Yes"
                disabled={!active}
              />
              <FormControlLabel
                value="na"
                control={<Radio />}
                label="NA"
                disabled={!active}
              />
            </RadioGroup>
            <FormHelperText>{error?.value}</FormHelperText>
          </FormControl>
        </Grid>
        {value === "yes" && !!dateOfInitiationField && (
          <>
            {!!totalDailyDosingField && (
              <Grid item sm={4}>
                <TextField
                  name="totalDailyDosing"
                  value={totalDailyDosing}
                  onChange={handleChange}
                  label="Total Daily Dosing"
                  disabled={!active}
                  fullWidth
                  error={!!error?.totalDailyDosing}
                  helperText={error?.totalDailyDosing}
                  variant="outlined"
                  required
                />
              </Grid>
            )}
            {!!totalWeeklyDosingField && (
              <Grid item sm={4}>
                <TextField
                  name="totalWeeklyDosing"
                  value={totalWeeklyDosing}
                  onChange={handleChange}
                  label="Total Weekly Dosing"
                  disabled={!active}
                  fullWidth
                  error={!!error?.totalWeeklyDosing}
                  helperText={error?.totalWeeklyDosing}
                  variant="outlined"
                  required
                />
              </Grid>
            )}
            {!!commonDosingField && commonDosingOptions.length > 0 && (
              <Grid item sm={4}>
                <TextField
                  select
                  value={commonDosing}
                  name="commonDosing"
                  onChange={handleChange}
                  label="Common Dosing"
                  disabled={!active || frequency === "Every Other Day"}
                  fullWidth
                  error={!!error?.commonDosing}
                  helperText={error?.commonDosing}
                  variant="outlined"
                  required
                >
                  {commonDosingOptions.map((option) => (
                    <MenuItem value={option.id}>{option.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            {!!othersField &&
              (commonDosing === "others" ||
                commonDosingOptions.length === 0) && (
                <Grid item sm={4}>
                  <TextField
                    onChange={handleChange}
                    name="others"
                    label="Others"
                    disabled={!active}
                    defaultValue={others}
                    fullWidth
                    error={!!error?.others}
                    helperText={error?.others}
                    variant="outlined"
                    required
                  />
                </Grid>
              )}
            {!!numberOfTabletsField && (commonDosing !== "NA" || others) && (
              <Grid item sm={4}>
                <TextField
                  select
                  onChange={handleChange}
                  name="numberOfTablets"
                  label="Number of Tablets"
                  disabled={!active}
                  defaultValue={numberOfTablets}
                  fullWidth
                  error={!!error?.numberOfTablets}
                  helperText={error?.numberOfTablets}
                  variant="outlined"
                  required
                >
                  {numberOfTabletsOptions.map((option) => (
                    <MenuItem value={option.id}>{option.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            {!!frequencyField && (
              <Grid item sm={4}>
                <TextField
                  select
                  onChange={handleChange}
                  name="frequency"
                  label="Frequency"
                  disabled={!active}
                  defaultValue={frequency}
                  fullWidth
                  error={!!error?.frequency}
                  helperText={error?.frequency}
                  variant="outlined"
                  required
                >
                  {frequencyOptions.map((option) => (
                    <MenuItem value={option.id}>{option.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid item sm={4}>
              <CustomCalendar

                name="dateOfInitiation"
                label="Date of Initiation"
                value={dateOfInitiation}
                onChange={handleChange}
                disabled={!active}
                fullWidth
                error={!!error?.dateOfInitiation}
                helperText={error?.dateOfInitiation}
                required
                variant="outlined"
              />
            </Grid>
          </>
        )}
      </Grid>
    </ListItem>
  );
}

export default memo(DrugInput);
