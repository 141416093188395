import { boolean, mixed, number, object, string } from '../../yup';

export const historyCVRFValidationSchema = {
  dateOfDiagnosis: object({
    date: string().required().checkDate(),
  }),
  height: object({
    text: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().numberRangeCheck().decimalPointCheck(2),
      otherwise: () => string().length(0),
    }),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().length(0),
    }),
    choice: string().required().oneOf(['yes', 'na']),
  }),
  armSpan: object({
    text: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().numberRangeCheck().decimalPointCheck(2),
      otherwise: () => string().length(0),
    }),
    // FIXME not able to check the state of height.text
    choice: string().oneOf(['yes', 'na', '']),
  }),
  weight: object({
    text: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().numberRangeCheck().decimalPointCheck(2),
      otherwise: () => string().length(0),
    }),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().length(0),
    }),
    choice: string().required().oneOf(['yes', 'na']),
  }),
  waistCircum: object({
    text: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().numberRangeCheck().decimalPointCheck(1),
      otherwise: () => string().length(0),
    }),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().length(0),
    }),
    choice: string().required().oneOf(['yes', 'na']),
  }),
  systolicBp: string().matches(/^\d{2,3}$/, {
    message: 'The value must have either 2 or 3 digits.',
  }),
  diastolicBp: string().matches(/^\d{2,3}$/, {
    message: 'The value must have either 2 or 3 digits.',
  }),
  map: number(),
  heartRate: string().matches(/^\d{2,3}$/, {
    message: 'The value must have either 2 or 3 digits.',
  }),
  bmi: object({
    text: string().numberRangeCheck().decimalPointCheck(2),
  }),
  smokingStatus: object({
    choice: string()
      .required()
      .oneOf(['activesmoker', 'formersmoker', 'nonsmoker', 'na']),
  }),
  alcoholStatus: object({
    choice: string()
      .required()
      .oneOf(['regulardrinker', 'occasionaldrinker', 'nondrinker', 'na']),
  }),
  familyHistoryDiabetes: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    checkbox: mixed().when('choice', {
      is: 'yes',
      then: () =>
        object()
          .shape({
            father: boolean(),
            mother: boolean(),
            siblings: boolean(),
            na: boolean(),
          })
          .test(
            'atLeastOneIsSelected',
            'Must select at least one',
            function (value) {
              return (
                value['father'] ||
                value['mother'] ||
                value['siblings'] ||
                value['na']
              );
            },
          ),
      otherwise: () => mixed().nullable(),
    }),
  }),
  familyHistoryASCVD: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    checkbox: mixed().when('choice', {
      is: 'yes',
      then: () =>
        object()
          .shape({
            father: boolean(),
            mother: boolean(),
            siblings: boolean(),
            na: boolean(),
          })
          .test(
            'atLeastOneIsSelected',
            'Must select at least one',
            function (value) {
              return (
                value['father'] ||
                value['mother'] ||
                value['siblings'] ||
                value['na']
              );
            },
          ),
      otherwise: () => mixed().nullable(),
    }),
  }),
};
