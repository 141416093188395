import { useSelector } from "react-redux"

const useCommonAuthorization = () => {
  const userPermission = useSelector((state) => state.authDetails.permissions);

  const checkRole = (subject, action) => {
    if (!!!userPermission || typeof userPermission !== 'object') {
      return false;
    }

    return userPermission?.[subject]?.[action] ?? false;
  }

  return checkRole;
}

export default useCommonAuthorization;
