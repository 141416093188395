import { HIDE_LOADING, SHOW_LOADING } from "../types";

const initialState = {
  isFetching: 0,
  message: "Loading",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADING:
      return {
        isFetching: state.isFetching + 1,
        message: action.payload.message,
      };
    case HIDE_LOADING:
      return { isFetching: state.isFetching - 1, message: state.message };
    default:
      return state;
  }
};

export default reducer;
