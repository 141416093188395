import {
  SET_AUTH_DETAILS,
  SET_AUTH_TOKEN,
  CLEAR_AUTH_DETAILS,
  SET_PROFILE,
} from "../types";

export const initialState = {
  _id: undefined,
  id: undefined,
  role: undefined,
  name: undefined,
  email: undefined,
  countryCode: undefined,
  permissions: undefined,
  facilityCode: undefined,
  history: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_DETAILS:
      return { ...action.payload, countryCode: action.payload.countryCode === '*' ? "Global" : action.payload.countryCode };
    case SET_AUTH_TOKEN:
      return { ...state, accessToken: action.payload.accessToken };
    case SET_PROFILE:
      return {
        ...state,
        name: action.payload.name,
        role: action.payload.role,
      };
    case CLEAR_AUTH_DETAILS:
      return {
        _id: null,
        id: null,
        role: null,
        name: null,
        email: null,
        countryCode: null,
        permissions: null,
        facilityCode: null,
        history: null,
      };
    default:
      return state;
  }
};

export default reducer;
