import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutUser } from "../../services/logic/auth";
import { Dashboard } from "../dashboard/dashboard";

const SignOut = () => {
  const [hasError, setHasError] = useState(null);
  const authDetails = useSelector((state) => state.authDetails);
  const { email, name, permissions } = authDetails ?? {};
  const history = useHistory();

  useEffect(() => {
    logoutUser().then(() => {
      setHasError(false);
    }).catch(() => {
      setHasError(true);
    })
  }, [])

  useEffect(() => {
    if (!!!email && !!!name && (!!!permissions || Object.keys(permissions).length === 0)) {
      history.replace("/signin")
    }
  }, [email, name, permissions, history]);

  if (hasError) return <Typography>Failed to log out...</Typography>
  return <Dashboard />
}

export default SignOut;
