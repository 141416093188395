import { string, object, boolean } from "../yup";

export const malaysiaGeneralValidationSchema = {
  patientCode: null,
  patientName: string().required().min(1),
  otherPatientDetails: object().nullable(),
  patientICType: string()
    .required()
    .oneOf(["new ic", "old ic", "army/police ic", "na"]),
  patientIC: string()
    .when("patientICType", ([patientICType], schema) => {
      if (patientICType === "new ic") {
        return schema
          .required()
          .matches(
            /^(([[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))-([0-9]{2})-([0-9]{4})$/,
            {
              message:
                "Make sure that the IC is valid and follow the format 000000-00-0000",
            }
          );
      } else if (patientICType === "army/police ic") {
        return schema
          .required()
          .matches(/^[A-Z]+\/[0-9]+$/, {
            message: "Make sure that the IC is valid",
          });
      } else if (patientICType === "old ic") {
        return schema
          .required()
          .matches(/^[0-9]{7}$/, {
            message: "Make sure the Old IC is valid. i.e. 0000000",
          });
      } else {
        return schema.nullable();
      }
    }),
  patientContact: string()
    .required()
    .matches(
      /^(601)[02-46-9]-[0-9]{7}$|^(601)[1]-[0-9]{8}$|^603-\d{8}$|^(60[4-79])-(\d{7})$|^(608[1-9])-(\d{6})$/,
      {
        message:
          "This should be a valid Malaysia mobile phone number / landline number  i.e. 6012-6674433 / 604-7778888",
      }
    ),
  facilityName: string().required(),
  clinicType: string().required().oneOf(["G", "E"]),
  facilityCode: null,
  hospitalRegNumber: string().required().matches(/^(?:[a-zA-Z]|\d)+$/, {
    message:
      "This should only contains alphanumeric characters i.e. A00000000",
  }),
  // for system purpose
  // not part of the data entry
  _id: string(),
  record: object(),
  facilityId: string(),
  isArchive: boolean(),
  isDraft: boolean(),
};

export const vietnamGeneralValidationSchema = {
  patientCode: null,
  patientName: string().required(),
  patientICType: string()
    .required()
    .oneOf(["custom", "na"]),
  patientIC: string()
    .when("patientICType", ([patientICType], schema) => {
      if (patientICType === "na") {
        return schema.nullable();
      } else {
        return schema.required();
      }
    }),
  otherPatientDetails: object({
    dateOfBirth: string().required().checkDate()
  }).required(),
  patientContact: string()
    .required()
    .matches(
      /^(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})$/,
      {
        message:
          "This should be a valid Vietnamese mobile phone number / landline number",
      }
    ),
  facilityName: string().required(),
  clinicType: string().required().oneOf(["G", "E"]),
  facilityCode: null,
  hospitalRegNumber: string().required().matches(/^(?:[a-zA-Z]|\d)+$/, {
    message:
      "This should only contains alphanumeric characters i.e. A00000000",
  }),
  // for system purpose
  // not part of the data entry
  _id: string(),
  record: object(),
  facilityId: string(),
  isArchive: boolean(),
  isDraft: boolean(),
};

export const globalGeneralValidationSchema = {
  patientCode: null,
  patientName: string().required(),
  otherPatientDetails: object().nullable(),
  patientICType: string(),
  patientIC: string()
    .when("patientICType", ([patientICType], schema) => {
      if (patientICType === "") {
        return schema.nullable();
      } else {
        return schema.required();
      }
    }),
  patientContact: string()
    .required(),
  facilityName: string().required(),
  clinicType: string().required().oneOf(["G", "E"]),
  facilityCode: null,
  hospitalRegNumber: string().required().matches(/^(?:[a-zA-Z]|\d)+$/, {
    message:
      "This should only contains alphanumeric characters i.e. A00000000",
  }),
  // for system purpose
  // not part of the data entry
  _id: string(),
  record: object(),
  facilityId: string(),
  isArchive: boolean(),
  isDraft: boolean(),
};
