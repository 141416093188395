import { List } from "@material-ui/core";
import MutuallyExclusiveWrapper from "./mutually-exclusive-wrapper";

function DrugInputList({ drugList, handleDrugSelect, drugResponses, errors }) {
  return (
    <List>
      {drugList.map((exclusiveDrugs, idx) => {
        return (
          <MutuallyExclusiveWrapper
            mutuallyExclusiveList={exclusiveDrugs}
            drugResponses={drugResponses}
            handleDrugSelect={handleDrugSelect}
            errors={errors}
            key={`${exclusiveDrugs[0].field_name}-${idx}`}
          />
        );
      })}
    </List>
  );
}

export default DrugInputList;
