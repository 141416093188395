import DrugInput from "./drug-input";
import { applyCamelCase } from "../../../utils/commonUtils";

function MutuallyExclusiveWrapper({
  mutuallyExclusiveList,
  drugResponses,
  handleDrugSelect,
  errors,
}) {
  const drugSelected = mutuallyExclusiveList
    .map((drug) => drugResponses[applyCamelCase(drug.field_name)])
    .find((result) => result);
  return (
    <>
      {mutuallyExclusiveList.map(
        (drug) =>
          !drug.hide && (
            <DrugInput
              drugDetail={drug}
              active={
                !(
                  drug.otherMutuallyExclusive &&
                  Array.isArray(drug.otherMutuallyExclusive) &&
                  drug.otherMutuallyExclusive.find(
                    (drugName) => drugResponses[applyCamelCase(drugName)]
                  )
                ) &&
                (!drugSelected || drug.field_name === drugSelected.fieldName)
              }
              handleDrugSelect={handleDrugSelect}
              drugResponse={
                drugSelected?.fieldName === drug.field_name ? drugSelected : {}
              }
              key={drug.field_name}
              error={errors[drug.field_name]}
            />
          )
      )}
    </>
  );
}

export default MutuallyExclusiveWrapper;
