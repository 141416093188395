import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { query } from "../../../services/api/common";
import { useSelector } from "react-redux";

function Chart({ patientData }) {
  const [range, setRange] = useState("week");
  const [data, setData] = useState([]);
  const [num, setNum] = useState(3);

  const countryCode = useSelector((state) => state.selectedCountry.selectedCountry)

  useEffect(() => {
    query(`patients/statistics/timeseries?frequency=${range}&num=${num}&cummulativeFrequency=false${countryCode !== "Global" ? `&countryCode=${countryCode}` : ""}`).then((data) => {
      setData(data.data);
    }).catch(() => setData([]))
  }, [range, num, patientData, countryCode]);

  const handleChange = (e) => {
    setNum(e.target.value);
  };

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              marginLeft: "auto",
              marginBottom: "20px",
              marginRight: "auto",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Medical records (count of completes vs drafts vs archives)
          </div>

          <ResponsiveContainer width="99%" height={350}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(str) => {
                  return range === "week"
                    ? format(
                      new Date(str),
                      "dd-MMM-yyy, wo"
                    )
                    : format(new Date(str), "MMM,yyy");
                }}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="completed" fill="#28407a"></Bar>
              <Bar dataKey="draft" fill="#8f8fcc"></Bar>
              <Bar dataKey="archive" fill="#5a5a80"></Bar>
            </BarChart>
          </ResponsiveContainer>
          <div
            style={{
              marginLeft: "50px",
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Button
              style={{
                backgroundColor: "#1a4593",
                color: "white",
                marginRight: "20px",
              }}
              variant="primary"
              name="week"
              onClick={() => setRange("week")}
            >
              Weekly
            </Button>
            <Button
              style={{
                backgroundColor: "#1a4593",
                color: "white",
                marginRight: "20px",
              }}
              variant="primary"
              name="monthly"
              onClick={() => setRange("month")}
            >
              Monthly
            </Button>
            <FormControl fullWidth style={{ width: "200px" }}>
              <InputLabel id="select">Number of weeks/months</InputLabel>
              <Select
                id="select-field"
                value={num}
                label="field"
                onChange={handleChange}
              >
                <MenuItem value={0}>1</MenuItem>
                <MenuItem value={3}>4</MenuItem>
                <MenuItem value={5}>6</MenuItem>
                <MenuItem value={11}>12</MenuItem>
              </Select>
            </FormControl>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Chart;
