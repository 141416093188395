import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    textAlign: "center",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 2,
  },
  line: {
    border: "1px solid rgba(0,0,0,0.2)",
    borderRadius: "5px",
    marginLeft: 30,
    marginRight: 30,
  },
}));

export const Footer = ({ showMenu }) => {
  const classes = useStyles();

  if (!showMenu) {
    return <></>;
  }
  return (
    <footer className={classes.root}>
      <hr className={classes.line} />
      <div className={classes.container}>
        <Typography variant="caption">MEMS &#169; Copyright 2021 All Rights Reserved</Typography>
      </div>
    </footer>
  );
};
