import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  setTitle,
  showSuccessSnackbar,
  showErrorSnackbar,
} from "../../redux/dispatchers";
import { CustomScrollMenu } from "../common/custom-scroll-menu";
import GenericForm from "./generic-form";
import DrugForm from "./medications/drug-form";
import { useParams, useHistory } from "react-router-dom";
import { query } from "../../services/api/common";
import { useSelector } from "react-redux";
import { update } from "../../services/api/common";
import { RequestDialog } from "../requests/requests-dialog";
import { Typography, Box } from "@mui/material";
import useDisableForm from "../common/useDisableForm";
import { Subject } from "../../constants/permissions";
import useMedicalRecordForm from "../../hooks/useMedicalRecordForm";
import useCustomScrollMenuControl from "../../hooks/useCustomScrollMenuControl";
import { useLocation } from "react-router-dom";
import BackButton from "../common/back-button";
import { FormModeDropdown } from "../common/form-mode-dropdown";

export const MedicalRecordForm = ({ isCreate, excludeGeneral = false }) => {
  const { id } = useParams();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const storedGeneral = useSelector((state) => state.form?.general);
  const { disableForm } = useDisableForm(Subject.Record, storedGeneral?.record?.recordId, isCreate)
  const { storedFormState, validationResult, config, queryFunctions, hasFetchError, isLoading, patientId, medicalRecordId, emitFunctions } = useMedicalRecordForm(id, null);

  const [openPresciptionDialog, setOpenPrescriptionDialog] = useState(false);

  const selectedCountry = useSelector((state) => state.selectedCountry.selectedCountry);

  // get the medical record for this patient
  useEffect(() => {
    setTitle("Medical Record");
  }, []);

  const noError = Object.values(validationResult).every((result) => !result);
  const [canSubmit, setCanSubmit] = useState(false);

  const handleSubmit = () => {
    const url = new URL(window.location.href);
    url.searchParams.set("submit", true);
    history.replace(url.pathname + url.search);
  };

  useEffect(() => {
    if (id) {
      query(`/medicalrecords/canisubmit?recordId=${id}`).then((data) => data.data).then((canSubmit) => setCanSubmit(canSubmit)).catch(setCanSubmit(false));
    } else {
      setCanSubmit(false);
    }
  }, [id])

  const recordIdRef = useRef(null);

  useEffect(() => {
    if (!!medicalRecordId) {
      if (recordIdRef.current === null)
        history.replace(`${excludeGeneral ? "/patients" : ""}/medicalRecords/${medicalRecordId}${search}`);
      recordIdRef.current = medicalRecordId;
    }
  }, [medicalRecordId, search, excludeGeneral, history]);

  const needSubmit = new URLSearchParams(search).get("submit");

  useEffect(() => {
    if (needSubmit === "true") {
      setOpenPrescriptionDialog(false);
      if (noError) {
        return update("medicalRecords/confirm", {
          id: id ?? storedGeneral?.record?.recordId,
        }).then(() => {
          showSuccessSnackbar(
            "Medical record draft is now submitted for verification!"
          );
          history.push("/medicalrecord");
        }).catch((e) => {
          showErrorSnackbar(e)
          history.replace(pathname + "?mode=edit");
        })
      } else {
        showErrorSnackbar("Invalid data found. Check the sections with ⚠️");
        history.replace(pathname + "?mode=edit");
      }
    }
  }, [needSubmit, history, id, noError, pathname, storedGeneral?.record?.recordId])

  useEffect(() => {
    if (isCreate) {
      const url = new URL(window.location.href);
      url.searchParams.set("mode", "edit");
      history.replace(url.pathname + url.search);
    }
  }, [isCreate, history])

  const fetchOptionsArgs = useMemo(() => ({ countryCode: selectedCountry }), [selectedCountry])

  const components = [
    ...(!excludeGeneral ? [{
      label: "General",
      showWarning: validationResult.general,
      contents: (
        <GenericForm
          disableForm={disableForm}
          formConfig={config?.general?.config}
          validationSchema={config?.general?.validation}
          onSaveAction={queryFunctions?.general}
          storedState={storedFormState?.general}
          completeStateForDerivation={storedFormState}
          currentEditingId={patientId}
          emitCurrentState={emitFunctions?.general}
          alertUnsaved
          checkUnsavedChangesMode="partial"
          fetchOptionsArgs={fetchOptionsArgs}
        // only partially check for unsaved changes
        // by making sure that the form state is the subset of the storedState
        // because in stored state, there is some additional system value that is not in the form
        />
      ),
    }] : []),
    {
      label: "Demographics",
      showWarning: validationResult.demographics,
      contents: (
        <GenericForm
          disableForm={disableForm}
          formConfig={config?.demographics?.config}
          validationSchema={config?.demographics?.validation}
          onSaveAction={queryFunctions?.demographics}
          storedState={storedFormState?.demographics}
          completeStateForDerivation={storedFormState}
          currentEditingId={medicalRecordId}
          emitCurrentState={emitFunctions?.demographics}
          fetchOptionsArgs={fetchOptionsArgs}
          alertUnsaved
        />
      ),
    },
    {
      label: "Risk Factors",
      showWarning: validationResult.history_cvrf,
      contents: (
        <GenericForm
          disableForm={disableForm}
          formConfig={config?.history_cvrf?.config}
          validationSchema={config?.history_cvrf?.validation}
          onSaveAction={queryFunctions.history_cvrf}
          storedState={storedFormState?.history_cvrf}
          completeStateForDerivation={storedFormState}
          currentEditingId={medicalRecordId}
          emitCurrentState={emitFunctions?.history_cvrf}
          fetchOptionsArgs={fetchOptionsArgs}
          alertUnsaved
        />
      ),
    },
    {
      label: "Conditions",
      showWarning: validationResult.condition,
      contents: (
        <GenericForm
          disableForm={disableForm}
          formConfig={config?.condition?.config}
          validationSchema={config?.condition?.validation}
          onSaveAction={queryFunctions?.condition}
          storedState={storedFormState?.condition}
          completeStateForDerivation={storedFormState}
          currentEditingId={medicalRecordId}
          emitCurrentState={emitFunctions?.condition}
          fetchOptionsArgs={fetchOptionsArgs}
          alertUnsaved
        />
      ),
    },
    {
      label: "Laboratory Tests",
      showWarning: validationResult.labs,
      contents: (
        <GenericForm
          disableForm={disableForm}
          formConfig={config?.labs?.config}
          validationSchema={config?.labs?.validation}
          onSaveAction={queryFunctions?.labs}
          storedState={storedFormState?.labs}
          completeStateForDerivation={storedFormState}
          currentEditingId={medicalRecordId}
          emitCurrentState={emitFunctions?.labs}
          fetchOptionsArgs={fetchOptionsArgs}
          alertUnsaved
        />
      ),
    },
    {
      label: "Medications",
      showWarning: validationResult.drugs,
      contents: (
        <DrugForm
          disableForm={disableForm}
          handleSubmit={canSubmit ? handleSubmit : undefined}
          openDialog={openPresciptionDialog}
          setOpenDialog={setOpenPrescriptionDialog}
          completeDrugData={config?.drugs?.config}
          validationSchema={config?.drugs?.validation}
          onSaveAction={queryFunctions?.drugs}
          emitCurrentState={emitFunctions?.drugs}
          currentEditingId={medicalRecordId}
          fetchOptionsArgs={fetchOptionsArgs}
        />
      ),
    },
  ];

  const { tabIndex, redirectOnChangePage } = useCustomScrollMenuControl(components.length);

  if (hasFetchError) return <Typography>Medical record is not available. Please refresh the page or select another medical record.</Typography>

  return (
    (isCreate || !isLoading) && (
      <>
        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box>
            <BackButton />
          </Box>
          {storedGeneral?.isDraft && <Typography component={"span"} variant={"caption"} sx={{ background: "#91B2BE", paddingX: 1, paddingY: 0.25, borderRadius: 2 }}>
            DRAFT
          </Typography>}
          {storedGeneral?.isArchive && <Typography component={"span"} variant={"caption"} sx={{ background: "grey", paddingX: 1, paddingY: 0.25, borderRadius: 2 }}>
            IN ARCHIVE
          </Typography>
          }
          <Box display={"flex"} alignItems={"center"}>
            <RequestDialog initialAssignee={storedGeneral?.userId} viewOnly={!disableForm} />
            {!isCreate && <FormModeDropdown />}
          </Box>
        </Box>
        <CustomScrollMenu
          // guard={alertState.unsaved}
          callback={redirectOnChangePage}
          components={components
            .map((component) => {
              return {
                ...component,
                contents: (
                  React.cloneElement(component.contents)
                ),
              };
            })}
          // components={[]}
          forceValue={tabIndex}
        />
      </>
    )
  );
};
