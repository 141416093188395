import { roundOff } from "../../utils/commonUtils";

const RADIO_BUTTON_CHOICES = [
  { id: "yes", name: "Yes" },
  { id: "na", name: "NA" },
];

export const historyCVRFConfig = [
  [
    {
      name: "dateOfDiagnosis.date",
      label: "Date of Diabetes Diagnosis",
      state: "",
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "height.choice",
      label: "Height",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },
        choices: RADIO_BUTTON_CHOICES,
      },
    },
    {
      name: "height.text",
      label: "Height",
      state: "",
      visibility: [
        {
          name: "height.choice",
          value: "yes",
        },
      ],
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "cm",
          required: true,
          placeholder: "0.00",
        },
        onBlurAction: (value) => roundOff(value, 2),
      },
    },
    {
      name: "height.date",
      label: "Date of Height Measurement",
      state: "",
      visibility: [
        {
          name: "height.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "armSpan.choice",
      label: "Arm Span",
      state: "na",
      visibility: [
        {
          name: "height.choice",
          value: "na",
        },
      ],
      component: {
        type: "Radio",
        props: {
          required: true,
        },
        choices: RADIO_BUTTON_CHOICES,
      },
    },
    {
      name: "armSpan.text",
      label: "Arm Span",
      state: "",
      visibility: [
        {
          name: "height.choice",
          value: "na",
        },
        {
          name: "armSpan.choice",
          value: "yes",
        },
      ],
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "cm",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 2),
      },
    },
  ],
  [
    {
      name: "weight.choice",
      label: "Weight",
      state: "na",
      component: {
        type: "Radio",
        props: {
          required: true,
        },
        choices: RADIO_BUTTON_CHOICES,
      },
    },
    {
      name: "weight.text",
      label: "Weight",
      state: "",
      visibility: [
        {
          name: "weight.choice",
          value: "yes",
        },
      ],
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "kg",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 2),
      },
    },
    {
      name: "weight.date",
      label: "Date of Weight Measurement",
      state: "",
      visibility: [
        {
          name: "weight.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "waistCircum.choice",
      label: "Waist Circumference",
      state: "na",
      component: {
        type: "Radio",
        props: {
          requireD: true,
        },
        choices: RADIO_BUTTON_CHOICES,
      },
    },
    {
      name: "waistCircum.text",
      label: "Waist Circumference",
      state: "",
      visibility: [
        {
          name: "waistCircum.choice",
          value: "yes",
        },
      ],
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "cm",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 1),
      },
    },
    {
      name: "waistCircum.date",
      label: "Date of Waist Circumference",
      state: "",
      visibility: [
        {
          name: "waistCircum.choice",
          value: "yes",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "systolicBp",
      label: "Systolic BP",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          required: true,
          inputAdornmentText: "mmHg",
        },
      },
    },
    {
      name: "diastolicBp",
      label: "Diastolic BP",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          required: true,
          inputAdornmentText: "mmHg",
        },
      },
    },
  ],
  [
    {
      name: "map",
      label: "MAP",
      state: "",
      component: {
        type: "TextField",
        derivation: ({ systolicBp, diastolicBp }) => {
          if (systolicBp === "" || diastolicBp === "") return "00.00";
          if (Number.isNaN(systolicBp) || Number.isNaN(diastolicBp))
            return "00.00";
          const systolicFloat = parseFloat(systolicBp);
          const diastolicFloat = parseFloat(diastolicBp);
          if (
            systolicFloat < 10 ||
            systolicFloat > 999 ||
            diastolicFloat < 10 ||
            diastolicFloat > 999
          )
            return "00.00";
          if (systolicFloat % 1.0 > 0 || diastolicFloat % 1.0 > 0)
            return "00.00";
          const result = (2 * parseInt(diastolicBp) + parseInt(systolicBp)) / 3;
          return roundOff(result, 2);
        },
        props: {
          disabled: true,
        },
      },
    },
  ],
  [
    {
      name: "heartRate",
      label: "Heart Rate",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          required: true,
          inputAdornmentText: "beats per min",
        },
      },
    },
  ],
  [
    {
      name: "bmi.text",
      label: "BMI",
      state: "",
      component: {
        type: "TextField",
        derivation: ({
          height: { text: height },
          weight: { text: weight },
        }) => {
          if (!height || !weight) {
            return;
          }
          if (parseInt(height) === 0) {
            return;
          }
          const result = roundOff(
            weight / Math.pow(parseInt(height) / 100, 2),
            2
          );
          if (result !== "NaN") return result;
          else return;
        },
        props: {
          disabled: true,
        },
      },
    },
  ],
  [
    {
      name: "smokingStatus.choice",
      label: "Smoking Status",
      state: "activesmoker",
      component: {
        type: "Radio",
        props: {
          required: true,
        },
        choices: [
          { id: "activesmoker", name: "Active smoker" },
          {
            id: "formersmoker",
            name: "Former smoker",
            tooltip: "Quit smoking for 6 months or more",
          },
          { id: "nonsmoker", name: "Non-smoker" },
          { id: "na", name: "NA" },
        ],
      },
    },
  ],
  [
    {
      name: "alcoholStatus.choice",
      label: "Alcohol Status",
      state: "regulardrinker",
      component: {
        type: "Radio",
        props: {
          required: true,
        },
        choices: [
          {
            id: "regulardrinker",
            name: "Regular drinker",
            tooltip: "Male >14 drinks per week \nFemale >7 drinks per week",
          },
          { id: "occasionaldrinker", name: "Occassional drinker" },
          { id: "nondrinker", name: "Non-drinker" },
          { id: "na", name: "NA" },
        ],
      },
    },
  ],
  [
    {
      name: "familyHistoryDiabetes.choice",
      label: "Family History of Diabetes in 1st Degree Relatives",
      state: "na",
      component: {
        type: "RadioWithTooltip",
        choices: [
          { id: "yes", name: "Yes" },
          { id: "no", name: "No" },
          { id: "na", name: "NA" },
        ],
        props: {
          tooltipText: "1st degree relatives: father, mother, siblings",
          required: true,
        },
      },
    },
    {
      name: "familyHistoryDiabetes.checkbox",
      label: "Family History Diabetes",
      state: {
        father: false,
        mother: false,
        siblings: false,
        na: false,
      },
      visibility: [
        {
          name: "familyHistoryDiabetes.choice",
          value: "yes",
        },
      ],
      component: {
        type: "Checkbox",
        checkboxChoices: [
          {
            id: "father",
            name: "Father",
          },
          {
            id: "mother",
            name: "Mother",
          },
          {
            id: "siblings",
            name: "Siblings",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
  ],
  [
    {
      name: "familyHistoryASCVD.choice",
      label: "Family History of Premature ASCVD",
      state: "na",
      component: {
        type: "RadioWithTooltip",
        choices: [
          { id: "yes", name: "Yes" },
          { id: "no", name: "No" },
          { id: "na", name: "NA" },
        ],
        props: {
          tooltipText:
            "Male: diagnosed =< age 55 years \nFemale: diagnosed =< age 65 years",
          required: true,
        },
      },
    },
    {
      name: "familyHistoryASCVD.checkbox",
      label: "Family History of premature ASCVD",
      state: {
        father: false,
        mother: false,
        siblings: false,
        na: false,
      },
      visibility: [
        {
          name: "familyHistoryASCVD.choice",
          value: "yes",
        },
      ],
      component: {
        type: "Checkbox",
        checkboxChoices: [
          {
            id: "father",
            name: "Father",
          },
          {
            id: "mother",
            name: "Mother",
          },
          {
            id: "siblings",
            name: "Siblings",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
  ],
];
