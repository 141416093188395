import {
  setAuthDetails,
  clearAuthDetails,
  showErrorSnackbar,
  showLoading,
  hideLoading,
} from "../../redux/dispatchers";
import { authentication, login, logout, refresh } from "../api/auth";

export const loginUser = async (em, password) => {
  try {
    const { data, permissions } = await login(em, password);
    const { _id = null, id = null, role = null, name = null, email = null, countryCode = null, facilityCode = null, history = null } = data;
    setAuthDetails(_id, id, role, name, email, countryCode, permissions, facilityCode, history);
    return data;
  } catch (e) {
    showErrorSnackbar("Invalid email or password, please try again.");
    throw e;
  }
};

export const authenticate = async () => {
  try {
    const { data, permissions } = await authentication();
    const { _id = null, id = null, role = null, name = null, email = null, countryCode = null, facilityCode = null, history = null } = data;
    setAuthDetails(_id, id, role, name, email, countryCode, permissions, facilityCode, history);
    return data;
  } catch { }
}

export const refreshAuthToken = async () => {
  const { data, permissions } = await refresh();
  const { _id = null, id = null, role = null, name = null, email = null, countryCode = null, facilityCode = null, history = null } = data;
  setAuthDetails(_id, id, role, name, email, countryCode, permissions, facilityCode, history);
};

export const logoutUser = async () => {
  try {
    showLoading("Logging out");
    await logout();
  } catch { } finally {
    // clear auth data in redux store
    clearAuthDetails();
    hideLoading();
  }
};
