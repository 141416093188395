import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import Typography from "@material-ui/core/Typography";
import { Grid, Typography } from "@material-ui/core";
import logo from "../../images/logo.svg";
import CustomTextField from "../common/custom-text-field";
import { resetPassword } from "../../services/api/auth";
import { logoutUser } from "../../services/logic/auth";
import jwt from "jwt-decode";
import { parse } from "query-string";
import { hideModal, showModal } from "../../redux/dispatchers";
import { useHistory } from "react-router-dom";
import { store } from "../../redux/store";
import {
  showErrorSnackbar
} from "../../redux/dispatchers";
import { MIN_PASSWORD_LENGTH } from "../../constants/rules";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    backgroundColor: "rgb(0, 92, 179, 0.3)",
  },
  logo: {
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: 400,
    },
    textAlign: "center",
  },
  title: {
    textAlign: "center",
  },
  bg: {
    width: "100vw",
    height: "100vh",
    position: "absolute",
    objectFit: "cover",
    zIndex: -2,
    overflow: "hidden",
  },
  form: {
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  errorStyle: {
    color: "red",
    marginBottom: 10,
  },
  componentContainer: {
    textAlign: "center",
    marginBottom: -100,
    marginTop: -100,
  },
  buttonContainer: {
    textAlign: "center",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const ResetPassword = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const history = useHistory();
  // get name on load
  useEffect(() => {
    try {
      const decoded = jwt(parse(props.location.search).token);
      setName(decoded.name);
    } catch (e) {
      history.replace("/signin");
    }
  }, [props.location.search, history]);

  useEffect(() => {
    if (store.getState().authDetails._id) logoutUser();
  }, []);

  const onResetPassword = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage(
        "Password is not the same with the password confirmation."
      );
      return;
    }

    if (password.length < MIN_PASSWORD_LENGTH) {
      setErrorMessage(`Password has to be more than ${MIN_PASSWORD_LENGTH} characters.`);
      return;
    }

    resetPassword(parse(props.location.search).token, password)
      .then(() => {
        showModal({
          type: "confirmationModal",
          title: "Reset Password",
          content: "Successfully reset your password!",
          cancel: "OK",
          onCancel: () => {
            history.push("/signin");
            hideModal();
          },
        });
      })
      .catch((e) => {
        showErrorSnackbar("Link has expired");
      });
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <form className={classes.form} noValidate>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} className={classes.componentContainer}>
              <img src={logo} className={classes.logo} alt="logo" />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.title} variant="h5">
                Reset Password
              </Typography>
            </Grid>
            <Typography className={classes.desc} variant="subtitle1">
              Hi, {name}
            </Typography>
            <Grid item xs={12}>
              <CustomTextField
                // margin="normal"
                label="Password"
                value={password}
                setValue={setPassword}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                // margin="normal"
                label="Confirm Password"
                value={confirmPassword}
                setValue={setConfirmPassword}
                type="password"
              />
            </Grid>
            {/* <Grid item xs={12} className={classes.buttonContainer}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                className={classes.submit}
                onClick={onResetPassword}
              >
                Reset Password
              </Button>
            </Grid> */}
            <Grid item xs={12}>
              <Typography className={classes.errorStyle}>
                {errorMessage}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  className={classes.submit}
                  onClick={onResetPassword}
                >
                  Reset Password
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </React.Fragment>
  );
};
