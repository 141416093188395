import { SHOW_MODAL, HIDE_MODAL } from "../types";

export const showModalAction = (
  type,
  title,
  content,
  confirm,
  cancel,
  onConfirm,
  onCancel,
  data
) => {
  return {
    type: SHOW_MODAL,
    payload: {
      visible: true,
      type,
      title,
      content,
      confirm,
      cancel,
      onConfirm,
      onCancel,
      data,
    },
  };
};

export const hideModalAction = () => {
  return {
    type: HIDE_MODAL,
  };
};
