import { applyCamelCase } from '../../utils';
import { lazy, mixed, string, object } from '../../yup';

const schemaForEachDrug = (drugDetail) => {
  let schema = {};
  const { sub_field: subField } = drugDetail;
  const {
    'Common dosing': commonDosingField,
    Others: othersField,
    'Number of tablets': numberOfTabletsField,
    Frequency: frequencyField,
    'Date of Initiation': dateOfInitiationField,
    'Total daily dosing': totalDailyDosingField,
    'Total weekly dosing': totalWeeklyDosingField,
  } = subField;

  schema['value'] = string()
    .required()
    .matches(/^yes$/, { message: 'Only "YES" and "NA" accepted.' });

  if (!!dateOfInitiationField) {
    schema['dateOfInitiation'] = string().required().checkDate();
  }

  if (!!totalDailyDosingField) {
    schema['totalDailyDosing'] = lazy((value) =>
      value === '-9999' ? string().matches(/^-9999$/) : string().required(),
    );
  }

  if (!!totalWeeklyDosingField) {
    schema['totalWeeklyDosing'] = lazy((value) =>
      value === '-9999' ? string().matches(/^-9999$/) : string().required(),
    );
  }

  if (!!commonDosingField) {
    schema['commonDosing'] = string().when('frequency', {
      is: 'Every Other Day',
      then: (schema) => schema.required().matches(/^others$/g),
      otherwise: (schema) =>
        schema
          .required()
          .oneOf(
            commonDosingField.field_options?.map((option) => option.id) ?? [],
          ),
    });
  }
  if (!!othersField) {
    schema['others'] = mixed().when('frequency', {
      is: 'Every Other Day',
      then: () =>
        string()
          .required()
          .matches(/^\d+\/\d+mg$/g, {
            message: 'Make sure the format is correct i.e. 40/60mg',
          }),
      otherwise: () =>
        mixed().when('commonDosing', {
          is: 'others',
          then: () =>
            lazy((val) =>
              val === 'NA' || val === ''
                ? string().required()
                : string()
                  .required()
                  .matches(
                    /(?:^\d+(?:\.\d+)? tablet$)|(?:^\d+(?:\.\d+)?mg$)/,
                    {
                      message:
                        "Value should be a number with unit 'tablet' or 'mg', i.e. 3 tablet or 3mg",
                    },
                  ),
            ),
        }),
    });
  }

  if (!!numberOfTabletsField) {
    schema['numberOfTablets'] = lazy(
      (value) =>
        value === '' || value === 'NA'
          ? string().required()
          : string()
            .oneOf(
              numberOfTabletsField.field_options?.map(
                (option) => option.id,
              ) ?? [],
            )
            .matches(/^[0-9]+$/g), // number() does not work here
    );
  }

  if (!!frequencyField) {
    schema['frequency'] = string().oneOf(
      frequencyField.field_options?.map((option) => option.id) ?? [],
    );
  }
  return schema;
};

export const drugValidationSchema = (drugData) => {
  let result = {};
  let onSortEdges = {};
  drugData.forEach((exclusive) => {
    if (exclusive.length === 0) return;
    const category = exclusive[0].category;
    let schema = {};
    let categoryOnSortEdges = [];
    exclusive.forEach((drug) => {
      schema = {
        ...schema,
        // [applyCamelCase(drug.field_name)]: object().shape(schemaForEachDrug).required()
        [applyCamelCase(drug.field_name)]: object().when(
          applyCamelCase(drug.field_name),
          {
            is: (exist) => !!exist,
            then: (schema) => schema.shape(schemaForEachDrug(drug)).required(),
            otherwise: (schema) => schema.notRequired(),
          },
        ),
      };
      categoryOnSortEdges.push([
        applyCamelCase(drug.field_name),
        applyCamelCase(drug.field_name),
      ]);
    });
    result = {
      ...result,
      [category]: { ...(result[category] ?? {}), ...schema },
    };
    onSortEdges = {
      ...onSortEdges,
      [category]: [...(onSortEdges[category] ?? []), ...categoryOnSortEdges],
    };
  });
  let finalResult = {};
  for (const category in result) {
    finalResult[applyCamelCase(category)] = object()
      .shape(result[category], onSortEdges[category])
      .noUnknown(true);
  }
  return finalResult;
};
