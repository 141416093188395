import { TextField, Grid, Button } from "@material-ui/core";
import { Container, Typography } from "@mui/material";
import { Autocomplete } from "@material-ui/lab";
import CollapsiblePanel from "../../common/collapsible-panel";
import DrugInputList from "./drug-input-list";
import React, { useEffect, useState, useMemo } from "react";
import { validateFields } from "../../../config/validation/yup";
import { useSelector } from "react-redux";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/dispatchers";
import escapeRegExp from "../../../utils/drugUtils";
import { allChangesSaved, applyCamelCase } from "../../../utils/commonUtils";
import SimpleDialog from "./popup-dialog";
import FormWrapper from "../../../components/common/form-wrapper";

function DrugForm({ handleSubmit, openDialog, setOpenDialog, disableForm, completeDrugData, validationSchema, onSaveAction, emitCurrentState, currentEditingId }) {
  const [state, setState] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filterState, setFilterState] = useState([]);
  // const [openDialog, setOpenDialog] = useState(false);
  const [dialogAction, setDialogAction] = useState("close");
  const [error, setError] = useState({});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const handleDrugSelectInCategory = (category, drug) => {
    // category > drug.fieldName > responses
    const drugName = applyCamelCase(drug.fieldName);
    category = applyCamelCase(category);
    const { [category]: drugSelectInCategory } = state;
    if (!drugSelectInCategory && drug.value === "yes") {
      setState({ ...state, [category]: { [drugName]: drug } });
      return;
    }
    const drugHistory = drugSelectInCategory && drugSelectInCategory[drugName];
    if (!drugHistory && drug.value === "yes") {
      setState({
        ...state,
        [category]: { ...state[category], [drugName]: drug },
      });
      return;
    }
    if (drugHistory && drug.value === "na") {
      // remove the drug response
      const { [drugName]: remove, ...newState } = drugSelectInCategory;
      if (Object.keys(newState).length === 0) {
        const { [category]: removedCategory, ...stateWithoutCategory } = state;
        setState({ ...stateWithoutCategory });
        return;
      }
      setState({ ...state, [category]: newState });
      return;
    }
    if (drugHistory && drug.value === "yes") {
      // update the drug response
      setState({
        ...state,
        [category]: { ...state[category], [drugName]: drug },
      });
      return;
    }
    // ignore if there is no history and the value is no
    setState({
      ...state,
      [category]: { ...state[category], [drugName]: drug },
    });
  };
  const handleSearchValueChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // const handleSubmit = () => {
  //   // setFormData("drugs", state);
  //   // alert(JSON.stringify(state));
  //   if (alertState.unsaved) {
  //     setOpenUnsavedAlert();
  //   } else {
  //     alert("Submitted successfully");
  //   }
  //   setOpenDialog(false);
  // };

  const handleSave = () => {
    validateFields(
      state,
      validationSchema,
      false
    ).then(([hasError, err]) => {
      if (!hasError) {
        onSaveAction(state, currentEditingId).then(() => {
          setHasUnsavedChanges(false);
          showSuccessSnackbar("Saved successfully!");
        }).catch((e) => {
          if (typeof e === "string") {
            showErrorSnackbar(e);
          } else {
            showErrorSnackbar("Your data entry is invalid. Make the changes and save again.");
          }
        });
      } else {
        showErrorSnackbar("Your data entry is invalid. Make the changes and save again.");
      }
      let drugErrors = {};
      for (let category in err) {
        for (let drugKey in err[category]) {
          const drugState = state[category][drugKey];
          drugErrors = { ...drugErrors, [drugState.fieldName]: err[category][drugKey] }
        }
      }
      setError(drugErrors);
    });
    setOpenDialog(false);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleOpen = (type) => {
    setDialogAction(type);
    setOpenDialog(true);
  };

  // const flattenedDrugList = data.flat();
  const findDrugsByFieldName = (drugList, searchKeyword) => {
    searchKeyword = escapeRegExp(searchKeyword);
    if (searchKeyword.length === 0) return [];
    const regex = new RegExp(`.*${searchKeyword.toLowerCase()}.*`);
    return drugList.map((exclusiveList) => {
      let allHidden = true;
      const filteredList = exclusiveList.map((drug) => {
        const visible = regex.test(drug.field_name.toLowerCase());
        const currentHideStatus =
          (drug.hide !== undefined && drug.hide) || !visible;
        allHidden = allHidden && currentHideStatus;
        return {
          ...drug,
          hide: currentHideStatus,
        };
      });
      if (allHidden) return [];
      return filteredList;
    }).filter((result) => result.length > 0);
  };
  const filterDrugsByYesNo = (drugList, filterList, state) => {
    const drugsWithYes = Object.keys(state)
      .map((category) => Object.keys(state[category]))
      .flat();
    return drugList.map((exclusiveList) => {
      let allHidden = true;
      const filteredList = exclusiveList.map((drug) => {
        const drugFieldName = applyCamelCase(drug.field_name);
        const visible =
          (drugsWithYes.includes(drugFieldName) &&
            filterList.includes("yes")) ||
          (!drugsWithYes.includes(drugFieldName) && filterList.includes("na"));
        const currentHideStatus =
          (drug.hide !== undefined && drug.hide) || !visible;
        allHidden = allHidden && currentHideStatus;
        return {
          ...drug,
          hide: currentHideStatus,
        };
      });
      if (allHidden) return [];
      return filteredList;
    }).filter((result) => result.length > 0);
  };

  const categorizedCompleteData = useMemo(() => {
    let result = {};
    completeDrugData.forEach((exclusive) => {
      if (exclusive.length === 0) return;
      const category = exclusive[0].category;
      result = {
        ...result,
        [category]: [...(result[category] ?? []), exclusive],
      };
    });
    return result;
  }, [completeDrugData])

  const categorizedFilteredData = useMemo(() => {
    if (searchTerm.length === 0 && filterState.length === 0)
      return categorizedCompleteData;
    let result = { ...categorizedCompleteData };
    for (let category in result) {
      if (searchTerm.length > 0) {
        result[category] = findDrugsByFieldName(result[category], searchTerm);
      }
      if (filterState.length > 0) {
        result[category] = filterDrugsByYesNo(result[category], filterState, state);
      }
    }
    return result;
  }, [searchTerm, filterState, state, categorizedCompleteData]);

  useEffect(() => {
    if (disableForm) {
      setFilterState(() => ["yes"]);
    } else {
      setFilterState(() => []);
    }
  }, [disableForm]);

  const storedState = useSelector((state) => state.form?.drugs);
  useEffect(() => {
    let camelCaseToNormalCaseMapping = {};
    for (const categoryInNormalCase in categorizedCompleteData) {
      camelCaseToNormalCaseMapping[applyCamelCase(categoryInNormalCase)] = categoryInNormalCase;
    }
    setState((state) => {
      let finalState = {};
      const source = state !== undefined && Object.keys(state).length > 0 && !disableForm ? state : storedState;
      for (let categoryInCamelCase in source) {
        const allDrugsInCategoryWithInnerExclusiveArray = categorizedCompleteData[camelCaseToNormalCaseMapping[categoryInCamelCase]];
        if (allDrugsInCategoryWithInnerExclusiveArray === undefined) {
          continue;
        }
        let allDrugsInCategory = allDrugsInCategoryWithInnerExclusiveArray.flat();
        let categoryState = {};
        for (let drugNameInCamelCase in source?.[categoryInCamelCase]) {
          const isExist = allDrugsInCategory.some((drug) => applyCamelCase(drug.field_name) === drugNameInCamelCase);
          if (isExist) {
            categoryState[drugNameInCamelCase] = source?.[categoryInCamelCase]?.[drugNameInCamelCase];
          }
        }
        if (Object.keys(categoryState).length > 0) {
          finalState[categoryInCamelCase] = categoryState;
        }
      }
      return finalState;
    })
  }, [disableForm, storedState, categorizedCompleteData]);

  useEffect(() => {
    if (!disableForm) setHasUnsavedChanges(!(allChangesSaved(state, storedState) && allChangesSaved(storedState, state)));
    else setHasUnsavedChanges(false);
  }, [disableForm, state, storedState]);

  useState(() => {
    if (typeof emitCurrentState === "function") emitCurrentState(state);
  }, [state]);

  return (
    <Container sx={{ marginTop: 3 }}>
      <FormWrapper disableForm={disableForm} hasUnsavedChanges={hasUnsavedChanges}>
        <Button
          name="showPrescription"
          style={{ width: 200 }}
          onClick={() => handleOpen("close")}
          variant="outlined"
          color="primary"
        >
          Show prescription
        </Button>
        <SimpleDialog
          handleSubmit={handleSubmit}
          handleSave={handleSave}
          action={dialogAction}
          open={openDialog}
          handleClose={handleClose}
          handle
          drugData={state}
        />
        {!disableForm && (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={9}>
              <TextField
                variant="outlined"
                fullWidth
                onChange={handleSearchValueChange}
                label="Search"
                value={searchTerm}
                margin="normal"
              />
            </Grid>
            <Grid item xs>
              <Autocomplete
                multiple
                freeSolo
                id="tags-outlined"
                options={["yes", "na"]}
                getOptionLabel={(option) => option}
                defaultValue={disableForm ? ["yes"] : []}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Filter"
                    margin="normal"
                  />
                )}
                onChange={(event, value) => setFilterState(value)}
              />
            </Grid>
          </Grid>
        )}
        {Object.keys(categorizedFilteredData).map((key) => (
          <CollapsiblePanel
            headerTitle={key}
            searching={
              // expand during search and also when the form is disabled
              disableForm || searchTerm?.length > 0 || filterState.length > 0
            }
            key={key}
          >
            <DrugInputList
              drugList={categorizedFilteredData[key]}
              drugResponses={state[applyCamelCase(key)] ?? []}
              handleDrugSelect={handleDrugSelectInCategory}
              filterState={filterState}
              key={`${key}-drug-list`}
              errors={error}
            />
            {categorizedFilteredData[key].length === 0 && <Typography variant="subtitle">No matching medication in this section.</Typography>}
          </CollapsiblePanel>
        ))}
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            marginTop: "30px",
          }}
        >
          <Button
            name="save"
            color="primary"
            variant="outlined"
            style={{ width: 200 }}
            onClick={() => handleOpen("save")}
            disabled={onSaveAction === undefined}
          >
            Save
          </Button>
          {handleSubmit !== undefined && <Button
            name="submit"
            color="secondary"
            variant="outlined"
            style={{ width: 300 }}
            onClick={() => handleOpen("submit")}
          >
            Finalize and Submit All
          </Button>}
        </div>
      </FormWrapper>
    </Container>
  );
}

export default DrugForm;
