import React from "react";
import { useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { hideSnackbar } from "../../redux/dispatchers";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export const SnackbarModal = () => {
  const classes = useStyles();
  let open = useSelector((state) => state.snackbar.open);
  const message = useSelector((state) => state.snackbar.message);
  const alert = useSelector((state) => state.snackbar.alertType);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    hideSnackbar();
  };

  if (!open) return null;

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};
