import { setTitle, clearFormData } from "../../redux/dispatchers";
import { CustomTable } from "../common/custom-table";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useCommonAuthorization from "../../hooks/useCommonAuthorization";
import { Action, Subject } from "../../constants/permissions";

export function FacilityList() {
  useEffect(() => setTitle("Facility"));
  const countryCode = useSelector((state) => state.selectedCountry.selectedCountry);
  const checkRole = useCommonAuthorization();

  return (
    <CustomTable
      old
      source="facility"
      defaultOrderBy="facilityCode"
      noCreate={!checkRole(Subject.Facility, Action.Create)}
      noExport={!checkRole(Subject.Facility, Action.Export)}
      noSelectAll={!checkRole(Subject.Facility, Action.Delete)}
      noSearch={!checkRole(Subject.Facility, Action.Search)}
      canSelectRow={(row) => row?.permissions?.[Action.Delete] ?? false}
      onClick={() => { }}
      getRowId={(row) => row.facilityCode}
      searchBarPlaceholder="Facility Code/Facility Name"
      filters={[{ filterItem: "isDisabled", filterValue: false }, ...(countryCode !== "Global" ? [{ filterItem: "countryCode", filterValue: countryCode }] : [])]}
      fields={[
        {
          id: "facilityCode",
          label: "Facility Code",
        },
        {
          id: "facilityName",
          label: "Facility Name",
        },
        {
          id: "actions",
          label: "Actions",
          action: true,
          actionConfig: {
            view: {
              destination: "facility",
              newTab: false,
              getViewId: (row) => {
                if (row?.permissions?.[Action.Read]) {
                  return row.facilityCode;
                }
                return undefined;
              },
            },
            edit: {
              destination: "facility",
              getEditId: (row) => {
                if (row?.permissions?.[Action.Update]) {
                  return row.facilityCode;
                }
                return undefined;
              },
              newTab: false,
            },
            yes: {},
            no: {}
          },
        },
      ]}
      createAction={clearFormData}
    />
  );
}
