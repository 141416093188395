import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";

const BackButton = () => {
  const history = useHistory();

  // back to previous page
  const handleBack = () => {
    history.goBack();
  }

  return <Button
    variant="outlined"
    color="primary"
    onClick={handleBack}
    component={"a"}
    style={{ margin: 10 }}
  >
    Back
  </Button>
}

export default BackButton;