import { COUNTRY_CHOICES } from "../../../constants/choices";
import { MIN_PASSWORD_LENGTH } from "../../../constants/rules";
import { string, boolean } from "../yup";

export const usersValidationSchema = {
  email: string().email("This is not a valid email").required(),
  name: string().required(),
  password: string().min(MIN_PASSWORD_LENGTH).required(),
  role: string().oneOf(["sc", "ra", "sa", "ca"]).required(),
  facilityCode: string().when("role", {
    is: (role) =>
      !role ||
      role === 'sc' ||
      role === 'ra',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  countryCode: string().when("role", {
    is: (role) => !role || role === 'ca',
    then: (schema) => schema.oneOf(COUNTRY_CHOICES.map((choice) => choice.id)).required(),
    otherwise: (schema) => schema.nullable(),
  }),
  isDisabled: boolean(),
};
