import { Action, Subject } from "../../constants/permissions";
import useCommonAuthorization from "../../hooks/useCommonAuthorization";
import { setTitle } from "../../redux/dispatchers";
import { CustomTable } from "../common/custom-table";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export function PatientList() {
  useEffect(() => setTitle("Patients"));

  const countryCode = useSelector((state) => state.selectedCountry.selectedCountry);
  const checkRole = useCommonAuthorization();

  return (
    <CustomTable
      old
      source="patients"
      defaultOrderBy="patientName"
      noCreate
      noExport={!checkRole(Subject.Patient, Action.Export)}
      noSearch={!checkRole(Subject.Patient, Action.Search)}
      noSelectAll={!checkRole(Subject.Patient, Action.Delete)}
      canSelectRow={(row) => row?.permissions?.[Action.Delete] ?? false}
      searchBarPlaceholder="Patient Code/Patient IC/Patient Name"
      onClick={() => { }}
      filters={[
        { filterItem: "isDraft", filterValue: false },
        { filterItem: "isArchive", filterValue: false },
        ...(countryCode !== "Global" ? [{
          filterItem: "countryCode",
          filterValue: countryCode,
        }] : [])
      ]}
      fields={[
        {
          id: "patientCode",
          label: "Patient Code",
        },
        {
          id: "patientIC",
          label: "IC",
        },
        {
          id: "patientName",
          label: "Name",
        },
        {
          id: "facilityName",
          label: "Facility Name",
        },
        {
          id: "createdBy",
          label: "Created By",
        },
        {
          id: "createdAt",
          label: "Created On",
          date: true,
        },
        {
          id: "actions",
          action: true,
          label: "Actions",
          actionConfig: {
            view: {
              destination: "patients",
              newTab: false,
              getViewId: (row) => {
                if (row?.permissions?.[Action.Read]) {
                  return row.id;
                }
                return undefined;
              },
            },
            edit: {
              destination: "patients",
              getEditId: (row) => {
                if (row?.permissions?.[Action.Update]) {
                  return row.id;
                }
                return undefined;
              },
              newTab: false,
            },
            yes: {},
            no: {}
          }
        },
      ]}
    />
  );
}
