import { store } from "../store";
import { showModalAction, hideModalAction } from "../actions";

export const showModal = ({
  type,
  title,
  content,
  confirm,
  cancel,
  onConfirm,
  onCancel,
  data,
}) => {
  store.dispatch(
    showModalAction(
      type,
      title,
      content,
      confirm,
      cancel,
      onConfirm,
      onCancel,
      data
    )
  );
};

export const hideModal = () => {
  store.dispatch(hideModalAction());
};
