import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { showErrorSnackbar } from "../redux/dispatchers";

const useCustomScrollMenuControl = (numOfTabs) => {
  const { search } = useLocation();
  const tabIndex = new URLSearchParams(search).get("tab");
  const history = useHistory();

  const storedGeneral = useSelector((state) => state.form?.general);

  useEffect(() => {
    const currentTabIndex = tabIndex === null || Number(tabIndex) < 0 || Number(tabIndex) > numOfTabs ? 0 : tabIndex;
    if (currentTabIndex !== tabIndex) {
      const url = new URL(window.location.href);
      url.searchParams.set("tab", currentTabIndex);
      history.replace(url.pathname + url.search);
    }
  }, [tabIndex, history, numOfTabs])

  const redirectOnChangePage = (nextPageIndex) => {
    if (!!!storedGeneral && nextPageIndex !== 0) {
      showErrorSnackbar("Please fill in patient details first.")
      return;
    }
    const url = new URL(window.location.href);
    url.searchParams.set("tab", nextPageIndex);
    history.replace(url.pathname + url.search);
  }

  return { tabIndex: Number(tabIndex), redirectOnChangePage };
}

export default useCustomScrollMenuControl;