import { InputAdornment } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { SearchOutlined } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import clsx from "clsx";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import React, { Fragment, useCallback, useEffect } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import {
  hideModal, showErrorSnackbar, showModal,
  showSuccessSnackbar
} from "../../redux/dispatchers";
import { axiosInstance } from "../../services/api/axios-instance";
import { deleteOne, queryList } from "../../services/api/common";
import { ActionColumn, StatusColumn } from "../table-view/table-components";
import { DateField } from "./date-field";
import { ReferenceField } from "./reference-field";

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    expandable,
    noSelect,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
            style={{ visibility: noSelect ? "hidden" : undefined }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="none"
            sortDirection={
              orderBy === headCell.id ? (order === 1 ? "asc" : "desc") : false
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={
                orderBy === headCell.id ? (order === 1 ? "asc" : "desc") : "asc"
              }
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "DESC" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {expandable && <TableCell />}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf([1, 2]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "right",
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
      }
      : {
        color: theme.palette.text.primary,
      },
  title: {
    flex: "1 1 100%",
  },
  button: {
    marginLeft: 10,
    marginRight: 10,
    width: 150,
  },
}));

const TableToolbar = (props) => {
  const classes = useToolbarStyles();

  const {
    selectedItems,
    numSelected,
    setDeleteModal,
    setNavigate,
    source,
    noCreate,
    noExport,
    old,
    order,
    orderBy,
    search,
    filters,
    createAction,
  } = props;

  if (numSelected > 0) {
    return (
      <Box
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Typography
          className={classes.title}
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            onClick={() => setDeleteModal(selectedItems)}
            color="inherit"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }

  if (!noExport || !noCreate) {
    return (
      <Box
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {!noCreate && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={() => {
              if (!!createAction) createAction();
              // special case, for users include the role too
              if (source === "users") {
                const roleFilter = filters.find((f) => f.filterItem === "role");
                if (!!roleFilter)
                  setNavigate(`/users/create/${roleFilter.filterValue}`);
              } else setNavigate(`/${source}/create`);
            }}
          >
            Create
          </Button>
        )}
        {!noExport && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            startIcon={<ImportExportIcon />}
            onClick={async () => {
              // query required data (without pagination)
              let params = "";
              if (old) {
                params = `query=order:${orderBy}${order}`;
                // add search text if any
                if (search !== "") params += `$q:${search}`;

                if (filters && filters.length) {
                  for (let i = 0; i < filters.length; i++) {
                    const { filterItem, filterValue } = filters[i];

                    params += `$${filterItem}:${filterValue}`;
                  }
                }
              } else {
                params = `sort=${order === 1 ? "" : "-"}${orderBy}`;
                // add search text if any
                if (search !== "") params += `&filter[q]=${search}`;

                if (filters && filters.length) {
                  for (let i = 0; i < filters.length; i++) {
                    const { filterItem, filterValue } = filters[i];
                    params += `&filter[${filterItem}]=${filterValue}`;
                  }
                }
              }
              axiosInstance
                .get(`export/${source}?${params}`, {
                  withCredentials: true,
                  responseType: "blob",
                })
                .then((res) => {
                  const link = document.createElement("a");
                  // Create blob link to download
                  link.href = window.URL.createObjectURL(new Blob([res.data]));
                  link.setAttribute("download", `${source}.xlsx`);
                  // Append to html link element page
                  document.body.appendChild(link);
                  // Start download
                  link.click();
                  // Clean up and remove the link
                  link.parentNode.removeChild(link);
                })
                .catch((e) => {
                  showErrorSnackbar(
                    <div style={{ display: "flex" }}>
                      <ReportProblemIcon
                        style={{ color: "yellow", marginRight: 10 }}
                      />
                      <Typography>{e.message}</Typography>
                    </div>
                  );
                });
            }}
          >
            Export
          </Button>
        )}
      </Box>
    );
  }
  return <></>;
};

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

function SearchBar({ setSearch, setPage, placeholder }) {
  let searchValue = "";
  return (
    <TextField
      label="Search"
      variant="outlined"
      size="small"
      placeholder={placeholder}
      fullWidth
      onChange={(e) => (searchValue = e.target.value)}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          setSearch(searchValue);
          setPage(0);
          e.target.blur();
        }
      }}
      style={{ marginTop: 5 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setSearch(searchValue);
                setPage(0);
              }}
            >
              <SearchOutlined />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

/*
Props List
source: URL to look for the data
defaultOrder: initial order (asc or desc)
defaultOrderBy: initial field to order
appendUrl: whether to append url when on click row
fields:
fields to display
{
  id: field id,
  label: label name,
  boolean: is a boolean field,
  sheetKey: key for the options field,
  choices: choices for the select field
}
filterItem: field name to filter
filterValue: field value to filter
 */

export const CustomTable = ({
  source,
  defaultOrder,
  defaultOrderBy,
  fields,
  title,
  appendUrl,
  customUrl,
  filters,
  params,
  noCreate,
  noExport,
  noSearch,
  old,
  expandFields,
  onClick,
  noSelectAll,
  canSelectRow = () => !!!noSelectAll,
  createAction,
  filterSelection,
  searchBarPlaceholder,
  getRowId = (row) => row.id ?? row._id,
  customDeleteModalAction,
  ...props
}) => {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();
  const { order = defaultOrder, orderBy = defaultOrderBy, page = 0, rowsPerPage = 10, search = "" } = Object.fromEntries(new URLSearchParams(location.search).entries());

  const pageNumber = isNaN(Number(page)) ? 0 : Number(page);
  const rowsPerPageNumber = isNaN(Number(rowsPerPage)) ? 0 : Number(rowsPerPage);

  const setOrder = useCallback((order) => {
    const url = new URL(window.location.href);
    url.searchParams.set("order", order);
    history.replace(url.pathname + url.search);
  }, [history])
  const setOrderBy = useCallback((orderBy) => {
    const url = new URL(window.location.href);
    url.searchParams.set("orderBy", orderBy);
    history.replace(url.pathname + url.search);
  }, [history])
  const setPage = useCallback((newPage) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", newPage);
    if (pageNumber !== newPage) history.replace(url.pathname + url.search);
  }, [history, pageNumber])
  const setRowsPerPage = useCallback((rowsPerPage) => {
    const url = new URL(window.location.href);
    url.searchParams.set("rowsPerPage", rowsPerPage);
    history.replace(url.pathname + url.search);
  }, [history])
  const setSearch = useCallback((search) => {
    const url = new URL(window.location.href);
    url.searchParams.set("search", search);
    history.replace(url.pathname + url.search);
  }, [history])

  // const [order, setOrder] = React.useState(defaultOrder);
  // const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [search, setSearch] = React.useState("");

  // initialize states
  const [selected, setSelected] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [navigate, setNavigate] = React.useState("");
  const [reloadData, setReloadData] = React.useState(false);
  const [open, setOpen] = React.useState("");

  const showSelectAllCheckbox =
    !noSelectAll && rows.some((row) => canSelectRow(row) === true);

  // called when component is loaded and any dependent parameters changed
  useEffect(() => {
    let params = "";
    if (old) {
      params = `query=order:${orderBy}${order}$from:${pageNumber * rowsPerPageNumber}$to:${pageNumber * rowsPerPageNumber + rowsPerPageNumber - 1
        }`;
      // add search text if any
      if (search !== "") params += `$q:${search}`;

      if (filters && filters.length) {
        for (let i = 0; i < filters.length; i++) {
          if (filters[i].length) {
            params += "$";
            for (let j = 0; j < filters[i].length; j++) {
              const { filterItem, filterValue } = filters[i][j];
              params += `${filterItem}:${filterValue}`;
              if (j !== filters[i].length - 1) {
                params += "|";
              }
            }
          } else {
            const { filterItem, filterValue } = filters[i];
            params += `$${filterItem}:${filterValue}`;
          }
        }
      }
    } else {
      params = `sort=${order === 1 ? "" : "-"}${orderBy}&offset=${pageNumber * rowsPerPageNumber
        }&limit=${rowsPerPageNumber}`;
      // add search text if any
      if (search !== "") params += `&filter[q]=${search}`;

      if (filters && filters.length) {
        for (let i = 0; i < filters.length; i++) {
          const { filterItem, filterValue } = filters[i];
          params += `&filter[${filterItem}]=${filterValue}`;
        }
      }
    }
    //TODO
    // let params = "";
    // params = `sort=${order === 1 ? "" : "-"}${orderBy}&offset=${
    //   page * rowsPerPage
    // }&limit=${rowsPerPage}`;
    // // add search text if any
    // if (search !== "") params += `&filter[${filters.length}][q]=${search}`;

    // if (filters && filters.length) {
    //   for (let i = 0; i < filters.length; i++) {
    //     const { filterItem, filterValue } = filters[i];
    //     params += `&filter[${i}][${filterItem}]=${filterValue}`;
    //   }
    // }

    let isActive = true;

    queryList(source, params)
      .then((resp) => {
        if (isActive) {
          setRows(resp?.data ?? []);
          setTotal(resp?.total ?? 0);
        }
      })
      .catch((e) => {
        setRows([])
        setTotal(-1)
        showErrorSnackbar(e);
      });

    return () => {
      isActive = false;
    };
  }, [
    old,
    filters,
    source,
    order,
    orderBy,
    pageNumber,
    rowsPerPageNumber,
    search,
    reloadData,
  ]);

  useEffect(() => {
    if (total > 0) {
      const lastPageIndex = Math.ceil(total / rowsPerPageNumber) - 1;
      if (pageNumber > lastPageIndex) {
        setPage(lastPageIndex);
      }
    } else if (total < 0) {
      setPage(0)
    } else {
      setPage(pageNumber)
    }
  }, [pageNumber, total, rowsPerPageNumber, setPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 1;
    setOrder(isAsc ? 2 : 1);
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows
        .map((n) => {
          if (canSelectRow(n) === true) return getRowId(n);
          return undefined;
        })
        .filter((selected) => selected !== undefined);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const deleteSelectedData = () => {
    if (selected.length === 0) return;
    if (selected.length === 1) {
      deleteOne(source, selected[0])
        .then(() => {
          setReloadData(!reloadData);
          setSelected([]);
          showSuccessSnackbar("Successfully deleted item!");
        })
        .catch((e) => {
          showErrorSnackbar(e);
        });
    } else {
      const promises = [];
      for (let i = 0; i < selected.length; i++) {
        promises.push(deleteOne(source, selected[i]));
      }
      Promise.all(promises)
        .then(() => {
          showSuccessSnackbar("Successfully deleted item(s)!");
        })
        .catch((e) => {
          showErrorSnackbar(e);
        })
        .finally(() => {
          setReloadData(!reloadData);
          setSelected([]);
        });
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // redirect if needed
  if (navigate) {
    return <Redirect to={{ pathname: navigate, state: params }} push />;
  }

  return (
    <div className={classes.root}>
      {!noSearch && (
        <SearchBar
          setSearch={setSearch}
          setPage={setPage}
          placeholder={searchBarPlaceholder}
        />
      )}
      {filterSelection}
      <Paper className={classes.paper}>
        <TableToolbar
          selectedItems={selected}
          numSelected={selected.length}
          title={title}
          setDeleteModal={
            !!customDeleteModalAction
              ? () =>
                customDeleteModalAction(selected, setSelected, setReloadData)
              : () => {
                showModal({
                  type: "confirmationModal",
                  title: "Delete Items",
                  content:
                    "Are you sure you want to delete the selected item(s)?",
                  confirm: "Delete",
                  cancel: "Cancel",
                  onConfirm: () => {
                    deleteSelectedData();
                    hideModal();
                  },
                });
              }
          }
          setNavigate={setNavigate}
          source={source}
          noCreate={noCreate}
          noExport={noExport}
          old={old}
          orderBy={orderBy}
          order={order}
          search={search}
          filters={filters}
          createAction={createAction}
          total={total}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.filter((row) => canSelectRow(row) === true).length}
              headCells={fields}
              expandable={!!expandFields && expandFields.length > 0}
              noSelect={!showSelectAllCheckbox}
            />
            <TableBody>
              {rows.map((row, index) => {
                let currentRowId = row.id ?? row._id;
                if (getRowId !== undefined && typeof getRowId === "function") {
                  currentRowId = getRowId(row);
                }
                const isItemSelected = isSelected(currentRowId);
                const labelId = `table-checkbox-${index}`;
                // populate cells
                const cells = fields.map((field) => {
                  let value = row;
                  if (!!field.id) {
                    const fieldIdToken = field?.id?.split(".") ?? [];
                    let i = 0;
                    while (i < fieldIdToken.length && value !== undefined) {
                      value = value[fieldIdToken[i++]];
                    }
                  } else {
                    value = undefined;
                  }
                  return (
                    <TableCell
                      component="th"
                      id={currentRowId + field.id}
                      scope="row"
                      onClick={() => {
                        if (!!onClick) {
                          onClick();
                          return;
                        }
                        let append = !!appendUrl ? `${appendUrl}` : "";
                        if (customUrl) {
                          setNavigate(`/${customUrl}/${currentRowId}${append}`);
                          return;
                        }
                        setNavigate(`/${source}/${currentRowId}${append}`);
                      }}
                      style={{ cursor: !!onClick ? "default" : "pointer" }}
                      key={currentRowId + field.id}
                    >
                      {!!field.action && !!field.id && !!field.actionConfig ? (
                        <ActionColumn
                          appendUrl={appendUrl}
                          source={source}
                          data={row}
                          reloadData={reloadData}
                          setReloadData={setReloadData}
                          actionConfig={field.actionConfig}
                        />
                      ) : !!field.viewRequest ? (
                        <Button
                          onClick={() =>
                            props.viewRequest(row, reloadData, setReloadData)
                          }
                        >
                          View Details
                        </Button>
                      ) : !!field.status ? (
                        <StatusColumn
                          status={field?.tooltipText?.[value] ?? value}
                          color={field?.colorScheme?.[value] ?? "grey"}
                        />
                      ) : !!field.boolean ? (
                        !!value ? (
                          <CheckIcon />
                        ) : (
                          <CloseIcon />
                        )
                      ) : !!field.date ? (
                        <DateField value={value} />
                      ) : !!field.reference ? (
                        <ReferenceField
                          source={field.source}
                          field={field.field}
                          id={value}
                          choices={field.choices}
                        />
                      ) : !!field.choices ? (
                        field.choices.filter((choice) => choice.id === value)
                          .length > 0 ? (
                          field.choices.filter(
                            (choice) => choice.id === value
                          )[0].name
                        ) : (
                          ""
                        )
                      ) : !!field.array && field.id !== "keywords" ? (
                        value.map((item) => <Chip label={item} key={item} />)
                      ) : !!field.keywords ? (
                        value
                          .map((it, iti) => {
                            // OR string
                            let orStr =
                              "(" +
                              it.keyword
                                .map((kw, kwi) =>
                                  kwi === it.keyword.length - 1
                                    ? kw
                                    : kw + " OR "
                                )
                                .join("") +
                              ")";
                            // last one, don't need to add AND
                            if (iti === value.length - 1) {
                              return orStr;
                            } else {
                              return orStr + " AND ";
                            }
                          })
                          .join("")
                      ) : typeof value === "object" ? (
                        parse(
                          (() => {
                            for (let prop in value) {
                              if (value[prop] !== "") {
                                return value[prop];
                              }
                            }
                            return "NA";
                          })()
                        )
                      ) : (
                        parse(value ?? "")
                      )}
                    </TableCell>
                  );
                });
                return (
                  <Fragment key={index}>
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={currentRowId}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onChange={(e) => handleClick(e, currentRowId)}
                          disabled={
                            typeof canSelectRow === "function" &&
                            canSelectRow(row) === false
                          }
                        />
                      </TableCell>
                      {cells}
                      {!!expandFields && expandFields.length > 0 && (
                        <TableCell>
                          <Button
                            onClick={() =>
                              open === currentRowId
                                ? setOpen("")
                                : setOpen(currentRowId)
                            }
                          >
                            View Details
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                    {open === currentRowId && (
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={fields.length + 2}
                        >
                          <Collapse
                            in={open === currentRowId}
                            timeout="auto"
                            unmountOnExit
                          >
                            {expandFields.map((expandField, index) => (
                              <div style={{ margin: 20 }} key={index}>
                                <Typography
                                  variant="subtitle2"
                                  style={{ color: "grey" }}
                                >
                                  {expandField.label}
                                </Typography>
                                {!!expandField.date ? (
                                  <DateField value={row[expandField.id]} />
                                ) : !!expandField.choices ? (
                                  expandField.choices.filter(
                                    (choice) =>
                                      choice.id === row[expandField.id]
                                  ).length > 0 ? (
                                    expandField.choices.filter(
                                      (choice) =>
                                        choice.id === row[expandField.id]
                                    )[0].name
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  <Typography variant="subtitle2">
                                    {parse(row[expandField.id])}
                                  </Typography>
                                )}
                              </div>
                            ))}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPageNumber}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

CustomTable.defaultProps = {
  source: "",
  defaultOrder: 1,
  defaultOrderBy: "id",
  title: "",
  appendUrl: "",
  filters: [],
};
