import { SET_TITLE } from "../types";

export const setTitleAction = (title) => {
  return {
    type: SET_TITLE,
    payload: {
      title,
    },
  };
};
