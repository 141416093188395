import { lazy, mixed, number, object, string } from '../../yup';

export const conditionValidationSchema = {
  endStageKidneyDisease: object({
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
    }),
    choice: string().required().oneOf(['yes', 'no', 'na']),
    subcondition: mixed().when('choice', {
      is: 'yes',
      then: () =>
        object({
          dialysis: object({
            select: string()
              .required()
              .oneOf([
                'haemodialysis',
                'peritonealDialysis',
                'unspecified',
                'na',
              ]),
            date: mixed().when('select', {
              is: (val) => val !== 'na',
              then: () => string().required().checkDate(),
              otherwise: () => string().nullable(),
            }),
          }),
          kidneyTransplant: object({
            choice: string().required().oneOf(['yes', 'no', 'na']),
            date: mixed().when('choice', {
              is: 'yes',
              then: () => string().required().checkDate(),
              otherwise: () => string().nullable(),
            }),
          }),
        }),
    }),
  }),
  haemorrhagicStroke: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
  }),
  hospitalizationForHeartFailure: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
    select: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().oneOf(['1', '2', '>/=3', 'na']),
      otherwise: () => string().nullable(),
    }),
  }),
  hypertension: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
  }),
  hyperlipidaemia: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
  }),
  overweightObesity: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
  }),
  ischaemicHeartDisease: object({
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
    }),
    choice: string().required().oneOf(['yes', 'no', 'na']),
    subcondition: mixed().when('choice', {
      is: 'yes',
      then: () =>
        object({
          acuteCoronarySyndrome: object({
            choice: string().required().oneOf(['yes', 'no', 'na']),
            date: mixed().when('choice', {
              is: 'yes',
              then: () => string().required().checkDate(),
              otherwise: () => string().nullable(),
            }),
            select: mixed().when('choice', {
              is: 'yes',
              then: () => string().required().oneOf(['1', '>/=2', 'na']),
              otherwise: () => string().nullable(),
            }),
          }),
          coronaryArteryBypassGraft: object({
            choice: string().required().oneOf(['yes', 'no', 'na']),
            date: mixed().when('choice', {
              is: 'yes',
              then: () => string().required().checkDate(),
              otherwise: () => string().nullable(),
            }),
          }),
          percutaneousCoronaryIntervention: object({
            choice: string().required().oneOf(['yes', 'no', 'na']),
            date: mixed().when('choice', {
              is: 'yes',
              then: () => string().required().checkDate(),
              otherwise: () => string().nullable(),
            }),
          }),
        }),
      otherwise: () =>
        object({
          acuteCoronarySyndrome: object({
            choice: string().length(0),
            date: string().length(0),
          }),
          coronaryArteryBypassGraft: object({
            choice: string().length(0),
            date: string().length(0),
          }),
          percutaneousCoronaryIntervention: object({
            choice: string().length(0),
            date: string().length(0),
          }),
        }),
    }),
  }),
  ischaemicStroke: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
    select: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().oneOf(['1', '>/=2', 'na']),
      otherwise: () => string().nullable(),
    }),
  }),
  lowerExtremityAmputation: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    select: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().oneOf(['1', '>/=2', 'na']),
      otherwise: () => string().nullable(),
    }),
    reason: mixed().when('choice', {
      is: 'yes',
      then: () =>
        string()
          .required()
          .oneOf([
            'peripheralArterialDisease',
            'infectedDiabeticFootUlcer',
            'necrotisingFasciitis',
            'gasGangrene',
            'others',
            'na',
          ]),
      otherwise: () => string().nullable(),
    }),
    subcondition: mixed().when('choice', {
      is: 'yes',
      then: () =>
        object({
          firstAmputation: object({
            select: string()
              .required()
              .oneOf(['unilateral', 'bilateral', 'na']), //[Unilateral, bilateral, na]
            select_2: string().when('select', {
              is: (val) => val !== 'na',
              then: () =>
                string()
                  .required()
                  .oneOf([
                    'rays',
                    'midfoot',
                    'hindFootDistalLeg',
                    'belowKnee',
                    'transKnee',
                    'aboveKnee',
                    'na',
                  ]),
            }),
            date: mixed().when('select', {
              is: (val) => val !== 'na',
              then: () => string().required().checkDate(),
              otherwise: () => string().nullable(),
            }),
          }),
        }),
      otherwise: () =>
        object({
          firstAmputation: object({
            select: string().length(0),
            select_2: string().length(0),
            date: string().length(0),
          }),
        }),
    }),
  }),
  malignancyBoneConnectiveTissueAndSkin: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
  }),
  malignancyBreast: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
  }),
  malignancyDigestiveOrganAndPeritoneum: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
  }),
  malignancyGenitourinaryOrgans: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
  }),
  malignancyLipOralCavityAndPharynx: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
  }),
  malignancyLymphaticAndHaematopoieticTissue: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
  }),
  malignancyOtherAndUnspecifiedSites: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
    text: mixed().when('choice', {
      is: 'yes',
      then: () => string().required(),
      otherwise: () => string().nullable(),
    }),
  }),
  malignancyRespiratoryAndIntrathoracicOrgans: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
  }),
  metabolicAssociatedFattyLiverDisease: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
    subcondition: mixed().when('choice', {
      is: 'yes',
      then: () =>
        object({
          type: object({
            select: string()
              .required()
              .oneOf(['nafl', 'nash', 'cirrhosis', 'unspecified', 'na']),
            date: mixed().when('select', {
              is: (val) => val !== 'na',
              then: () => string().required().checkDate(),
              otherwise: () => string().nullable(),
            }),
          }),
        }),
      otherwise: () =>
        object({
          select: string().length(0),
          date: string().length(0),
        }),
    }),
  }),
  neuroendocrineTumours: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
  }),
  obstructiveSleepApnoea: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
  }),
  osteoporosis: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
    subcondition: mixed().when('choice', {
      is: 'yes',
      then: () =>
        object({
          fractureHistory: object({
            choice: string().required().oneOf(['yes', 'no', 'na']),
            date: mixed().when('choice', {
              is: 'yes',
              then: () => string().required().checkDate(),
              otherwise: () => string().nullable(),
            }),
            select: mixed().when('choice', {
              is: 'yes',
              then: () =>
                string()
                  .required()
                  .oneOf(['spine', 'hip', 'distalRadiusColles', 'na']),
              otherwise: () => string().nullable(),
            }),
            text: mixed().when('choice', {
              is: 'yes',
              then: () =>
                lazy((value) =>
                  value === ''
                    ? string().required()
                    : number().required().positive().integer(),
                ),
              otherwise: () => string().nullable(),
            }),
          }),
        }),
      otherwise: () =>
        object({
          fractureHistory: object({
            choice: string().length(0),
            date: string().length(0),
            select: string().length(0), //['spine', 'hip', distalRadiusColles]
            text: string().length(0),
          }),
        }),
    }),
  }),
  peripheralArterialDisease: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
  }),
  retinopathy: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().checkDate(),
    }),
    subcondition: mixed().when('choice', {
      is: 'yes',
      then: () =>
        object({
          retinopathy_left: object({
            choice: string().required().oneOf(['yes', 'no', 'na']),
            select: mixed().when('choice', {
              is: 'yes',
              then: () =>
                string()
                  .required()
                  .oneOf(['nonProliferative', 'proliferative']),
              otherwise: () => string().length(0),
            }),
            nonproliferative_select: string().when(['choice', 'select'], {
              is: (choice, select) =>
                choice === 'yes' && select === 'nonProliferative',
              then: () =>
                string()
                  .required()
                  .oneOf(['mild', 'moderate', 'severe', 'unspecified', 'na']),
            }),
            proliferative_select: string().when(['choice', 'select'], {
              is: (choice, select) =>
                choice === 'yes' && select === 'proliferative',
              then: () =>
                string()
                  .required()
                  .oneOf([
                    'laserPhotocoagulation',
                    'anti-VEGF',
                    'unspecified',
                    'na',
                  ]),
            }),
            date: mixed().when('choice', {
              is: 'yes',
              then: () => string().required().checkDate(),
            }),
          }),
          retinopathy_right: object({
            choice: string().required().oneOf(['yes', 'no', 'na']),
            select: mixed().when('choice', {
              is: 'yes',
              then: () =>
                string()
                  .required()
                  .oneOf(['nonProliferative', 'proliferative']),
              otherwise: () => string().length(0),
            }),
            nonproliferative_select: string().when(['choice', 'select'], {
              is: (choice, select) =>
                choice === 'yes' && select === 'nonProliferative',
              then: () =>
                string()
                  .required()
                  .oneOf(['mild', 'moderate', 'severe', 'unspecified', 'na']),
            }),
            proliferative_select: string().when(['choice', 'select'], {
              is: (choice, select) =>
                choice === 'yes' && select === 'proliferative',
              then: () =>
                string()
                  .required()
                  .oneOf([
                    'laserPhotocoagulation',
                    'anti-VEGF',
                    'unspecified',
                    'na',
                  ]),
            }),
            date: mixed().when('choice', {
              is: 'yes',
              then: () => string().required().checkDate(),
            }),
          }),
        }),
      otherwise: () =>
        object({
          select: string().length(0),
          nonproliferative_select: string().length(0),
          proliferative_select: string().length(0),
          date: string().length(0),
        }),
    }),
  }),
  unspecifiedStroke: object({
    choice: string().required().oneOf(['yes', 'no', 'na']),
    date: mixed().when('choice', {
      is: 'yes',
      then: () => string().required().checkDate(),
      otherwise: () => string().nullable(),
    }),
  }),
};
