import { roundOff } from "../../utils/commonUtils";

export const labsConfig = [
  [
    {
      name: "fastingPlasmaGlucose.text",
      label: "Fasting Plasma Glucose",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "mmol/\u2113",
          placeholder: "0.0",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 1),
      },
    },
    {
      name: "fastingPlasmaGlucose.date",
      label: "Date of Labs",
      state: "",
      visibility: [
        {
          name: "fastingPlasmaGlucose.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "hba1c.text",
      label: "HbA1c (NGSP)",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "%",
          placeholder: "0.0",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 1),
      },
    },
    {
      name: "hba1c.date",
      label: "Date of HbA1c (NGSP)",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "hba1c.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "totalCholesterol.text",
      label: "Total Cholesterol",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "mmol/\u2113",
          placeholder: "0.00",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 2),
      },
    },
    {
      name: "totalCholesterol.date",
      label: "Date of Total Cholesterol",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "totalCholesterol.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "triglyceride.text",
      label: "Triglyceride",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "mmol/\u2113",
          placeholder: "0.00",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 2),
      },
    },
    {
      name: "triglyceride.date",
      label: "Date of Triglyceride",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "triglyceride.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "hdlCholesterol.text",
      label: "HDL Cholesterol",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "mmol/\u2113",
          placeholder: "0.00",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 2),
      },
    },
    {
      name: "hdlCholesterol.date",
      label: "Date of HDL Cholesterol",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "hdlCholesterol.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "ldlCholesterol.text",
      label: "LDL Cholesterol",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "mmol/\u2113",
          placeholder: "0.00",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 2),
      },
    },
    {
      name: "ldlCholesterol.date",
      label: "Date of LDL cholesterol",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "ldlCholesterol.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "nonHDLCholesterol.text",
      label: "Non-HDL Cholesterol",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        derivation: ({
          totalCholesterol: { text: totalCholesterolText },
          hdlCholesterol: { text: hdlCholesterolText },
        }) => {
          let hdlCholesterolValue = parseFloat(hdlCholesterolText);
          let totalCholesterolValue = parseFloat(totalCholesterolText);
          if (
            Number.isNaN(totalCholesterolValue) ||
            Number.isNaN(hdlCholesterolValue) ||
            hdlCholesterolValue > totalCholesterolValue ||
            hdlCholesterolValue < 0 ||
            totalCholesterolValue < 0
          ) {
            return "-9999";
          } else {
            return roundOff(totalCholesterolValue - hdlCholesterolValue, 2);
          }
        },
        props: {
          inputAdornmentText: "mmol/\u2113",
          placeholder: "0.00",
          disabled: true,
        },
      },
    },
    {
      name: "nonHDLCholesterol.date",
      label: "Date of Non HDL Cholesterol",
      state: "",
      visibility: [
        {
          name: "nonHDLCholesterol.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
          disabled: true,
        },
        derivation: ({ hdlCholesterol, totalCholesterol }) => {
          return totalCholesterol.date || hdlCholesterol.date;
        },
      },
    },
  ],
  [
    {
      name: "serumSodium.text",
      label: "Serum Sodium",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "mmol/\u2113",
          placeholder: "0.0",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 1),
      },
    },
    {
      name: "serumSodium.date",
      label: "Date of Serum Sodium",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "serumSodium.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "serumPotassium.text",
      label: "Serum Potassium",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "mmol/\u2113",
          placeholder: "0.0",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 1),
      },
    },
    {
      name: "serumPotassium.date",
      label: "Date of Serum Potassium",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "serumPotassium.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "serumUrea.text",
      label: "Serum Urea",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "mmol/\u2113",
          placeholder: "0.0",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 1),
      },
    },
    {
      name: "serumUrea.date",
      label: "Date of Serum Urea",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "serumUrea.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "serumCreatinine.text",
      label: "Serum Creatinine (ScR)",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "μmol/\u2113",
          placeholder: "0.0",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 1),
      },
    },
    {
      name: "serumCreatinine.date",
      label: "Date of Serum Creatinine (ScR)",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "serumCreatinine.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "eGFR.text",
      label: "eGFR",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        derivation: (
          { serumCreatinine: { text: serumCreatinine } },
          { demographics }
        ) => {
          if (parseFloat(serumCreatinine) < 0.0) return "-9999";
          if (serumCreatinine === "") return "Serum creatinine not available";

          if (demographics === undefined) {
            return "-9999";
          }

          let { ageAtHospitalVisit: age, sex } = demographics;
          if (!!!age || (sex !== "male" && sex !== "female"))
            return "Age or sex not available";

          let scrValue = parseFloat(serumCreatinine) * 0.0113;
          let constant;
          sex = sex?.toLowerCase();
          const constants = {
            male: {
              larger: { a: 141, b: 0.9, c: -1.209 },
              smallerOrEqual: { a: 141, b: 0.9, c: -0.411 },
            },
            female: {
              larger: { a: 144, b: 0.7, c: -1.209 },
              smallerOrEqual: { a: 144, b: 0.7, c: -0.329 },
            },
          };
          if (sex === "male") {
            if (scrValue > 0.9) {
              constant = constants.male.larger;
            } else {
              constant = constants.male.smallerOrEqual;
            }
          } else if (sex === "female") {
            if (scrValue > 0.7) {
              constant = constants.female.larger;
            } else {
              constant = constants.female.smallerOrEqual;
            }
          }
          return roundOff(
            constant.a *
            Math.pow(scrValue / constant.b, constant.c) *
            Math.pow(0.993, age),
            2
          );
        },
        props: {
          inputAdornmentText: "m\u2113/min/1.73m\u00b2",
          placeholder: "0.00",
          disabled: true,
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 2),
      },
    },
    {
      name: "eGFR.date",
      label: "Date of eGFR",
      state: "",
      visibility: [
        {
          name: "eGFR.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
          disabled: true,
        },
        derivation: ({ serumCreatinine: { date } }) => {
          return date;
        },
      },
    },
  ],
  [
    {
      name: "serumAlbumin.text",
      label: "Serum Albumin",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "g/\u2113",
          placeholder: "0.0",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 1),
      },
    },
    {
      name: "serumAlbumin.date",
      label: "Date of Serum Albumin",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "serumAlbumin.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "serumTotalBilirubin.text",
      label: "Serum Total Bilirubin",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "\u00b5mol/\u2113",
          placeholder: "0.0",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 1),
      },
    },
    {
      name: "serumTotalBilirubin.date",
      label: "Date of Serum Total Bilirubin",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "serumTotalBilirubin.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "serumALP.text",
      label: "Serum ALP",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "U/\u2113",
          placeholder: "0.0",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 1),
      },
    },
    {
      name: "serumALP.date",
      label: "Date of Serum ALP",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "serumALP.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "serumALT.text",
      label: "Serum ALT",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "U/\u2113",
          placeholder: "0.0",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 1),
      },
    },
    {
      name: "serumALT.date",
      label: "Date of Serum ALT",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "serumALT.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "serumAST.text",
      label: "Serum AST",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "U/\u2113",
          placeholder: "0.0",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 1),
      },
    },
    {
      name: "serumAST.date",
      label: "Date of Serum AST",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "serumAST.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "serumGGT.text",
      label: "Serum GGT",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "U/\u2113",
          placeholder: "0.0",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 1),
      },
    },
    {
      name: "serumGGT.date",
      label: "Date of Serum GGT",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "serumGGT.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "urineProtein.select",
      label: "Urine Protein",
      state: "na",
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: [
          { id: "nil", name: "Nil" },
          { id: "trace", name: "trace" },
          { id: "+", name: "+" },
          { id: "2+", name: "2+" },
          { id: "3+", name: "3+" },
          { id: "na", name: "NA" },
        ],
      },
    },
    {
      name: "urineProtein.date",
      label: "Date of Urine Protein",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "urineProtein.select",
          operator: "!==",
          value: "na",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "urineProteinCreatinineIndex.text",
      label: "Urine Protein Creatinine Index",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "mg/mmol",
          placeholder: "0.00",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 2),
      },
    },
    {
      name: "urineProteinCreatinineIndex.date",
      label: "Date of Urine Protein Creatinine Index",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "urineProteinCreatinineIndex.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "urineAlbuminToCreatinineRatio.text",
      label: "Urine Albumin : Creatinine Ratio",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "mg/mmol",
          placeholder: "0.00",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 2),
      },
    },
    {
      name: "urineAlbuminToCreatinineRatio.date",
      label: "Date of Urine Albumin : Creatinine Ratio",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "urineAlbuminToCreatinineRatio.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "haemoglobin.text",
      label: "Haemoglobin",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "g/\u2113",
          placeholder: "0.0",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 1),
      },
    },
    {
      name: "haemoglobin.date",
      label: "Date of Haemoglobin",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "haemoglobin.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "haematocrit.text",
      label: "Haematocrit",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "\u2113/\u2113",
          placeholder: "0.00",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 2),
      },
    },
    {
      name: "haematocrit.date",
      label: "Date of Haematocrit",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "haematocrit.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "totalWhiteBloodCells.text",
      label: "Total White Blood Cells",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "10\u2079/\u2113",
          placeholder: "0.00",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 2),
      },
    },
    {
      name: "totalWhiteBloodCells.date",
      label: "Date of Total White Blood Cells",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "totalWhiteBloodCells.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "neutrophils.text",
      label: "Neutrophils",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "10\u2079/\u2113",
          placeholder: "0.00",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 2),
      },
    },
    {
      name: "neutrophils.date",
      label: "Date of Neutrophils",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "neutrophils.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "lymphocytes.text",
      label: "Lymphocytes",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "10\u2079/\u2113",
          placeholder: "0.00",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 2),
      },
    },
    {
      name: "lymphocytes.date",
      label: "Date of Lymphocytes",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "lymphocytes.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "platelet.text",
      label: "Platelet",
      state: "",
      component: {
        type: "TextFieldWithAdornment",
        props: {
          inputAdornmentText: "10\u2079/\u2113",
          placeholder: "0",
          required: true,
        },
        onBlurAction: (value) => roundOff(value, 0),
      },
    },
    {
      name: "platelet.date",
      label: "Date of Platelet",
      state: { path: "fastingPlasmaGlucose.date" },
      visibility: [
        {
          name: "platelet.text",
          operator: ">=",
          value: "0",
        },
      ],
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
  ],
];
