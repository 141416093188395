import { axiosInstanceNoRefresh, axiosInstance } from "./axios-instance";
import {
  showErrorSnackbar
} from "../../redux/dispatchers";

export const login = async (email, password) => {
  const result = await axiosInstanceNoRefresh.post(
    "/authentication/log-in",
    {
      email,
      password,
    },
  );
  return result.data;
};

export const authentication = async () => {
  const result = await axiosInstance.get("/authentication");
  if (result && result.data) return result.data;
  return null;
};

export const refresh = async () => {
  const result = await axiosInstanceNoRefresh.get("/authentication/refresh");
  return result.data;
};

export const logout = async () => {
  await axiosInstance.post(
    "/authentication/log-out",
    {},
  );
};

export const resetPassword = async (token, newPassword) => {
  const result = await axiosInstanceNoRefresh.post(
    `/authentication/reset`,
    {
      token,
      password: newPassword,
    }
  );

  return result.data;
};

export const verify = async (token) => {
  const url = `authentication/verify?token=${token}`;
  return new Promise((resolve, reject) => {
    axiosInstanceNoRefresh
      .get(url)
      .then((response) => ({
        status: response.status,
        headers: response.headers,
        allData: response.data,
        data: response.data.data,
        total: response.data.total,
        message: response.data.message,
      }))
      .then(({ status, headers, allData, data, total, message }) => {
        if (!!total || total === 0) total = parseInt(total, 10);
        if (status < 200 || status >= 300) {
          return reject("error occurred");
        }
        return resolve({ status, headers, allData, data, total, message });
      })
      .catch((err) => {
        if (!!err.response && !!err.response.data && err.response.data.message)
          return reject(err.response.data.message);
        return reject(err.message);
      });
  });
};

export const getResetPasswordLink = async (email) => {
  email = encodeURIComponent(email);
  const url = `authentication/getpasswordreset?email=${email}`;
  return new Promise((resolve, reject) => {
    axiosInstanceNoRefresh
      .get(url)
      .then((response) => ({
        status: response.status,
        headers: response.headers,
        allData: response.data,
        data: response.data.data,
        total: response.data.total,
        message: response.data.message,
      }))
      .then(({ status, headers, allData, data, total, message }) => {
        if (!!total || total === 0) total = parseInt(total, 10);
        if (status < 200 || status >= 300) {
          return reject("error occurred");
        }
        return resolve({ status, headers, allData, data, total, message });
      })
      .catch((err) => {

        if (!!err.response && !!err.response.data && err.response.data.message) {
          showErrorSnackbar(err.response.data.message);
          return reject(err.response.data.message);
        }
        showErrorSnackbar("Token has not expired.");
        return reject(err.message);
      });
  });
};
