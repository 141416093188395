import {
  SET_AUTH_DETAILS,
  SET_AUTH_TOKEN,
  CLEAR_AUTH_DETAILS,
  SET_PROFILE,
} from "../types";

export const setAuthDetailsAction = (_id, id, role, name, email, countryCode, permissions, facilityCode, history) => {
  return {
    type: SET_AUTH_DETAILS,
    payload: {
      _id,
      id,
      role,
      name,
      email,
      countryCode,
      permissions,
      facilityCode,
      history,
    },
  };
};

export const clearAuthDetailsAction = () => {
  return {
    type: CLEAR_AUTH_DETAILS,
  };
};

export const setAuthTokenAction = (accessToken) => {
  return {
    type: SET_AUTH_TOKEN,
    payload: {
      accessToken,
    },
  };
};

export const setProfileAction = (name, role) => {
  return {
    type: SET_PROFILE,
    payload: {
      name,
      role,
    },
  };
};
