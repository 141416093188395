import { store } from "../store";
import {
  setAuthDetailsAction,
  setAuthTokenAction,
  clearAuthDetailsAction,
  setProfileAction,
} from "../actions";

export const setAuthDetails = (_id, id, role, name, email, countryCode, permissions, facilityCode, history) => {
  store.dispatch(setAuthDetailsAction(_id, id, role, name, email, countryCode, permissions, facilityCode, history));
};

export const clearAuthDetails = () => {
  store.dispatch(clearAuthDetailsAction());
};

export const setAuthToken = (accessToken) => {
  store.dispatch(setAuthTokenAction(accessToken));
};

export const setProfile = (name, role) => {
  store.dispatch(setProfileAction(name, role));
};
