import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, ListItemButton } from "@mui/material";
import { List, ListItemText } from "@material-ui/core";
import { queryList, update } from "../../services/api/common";
import { showErrorSnackbar, showSuccessSnackbar } from "../../redux/dispatchers";
import { Redirect } from "react-router-dom";

const TransferOwnershipDialog = ({ open, handleDismiss, email }) => {
  const [userList, setUserList] = useState([]);
  const [selected, setSelected] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    queryList("users/transfer", `from=${email}`).then((data) => {
      if (!!!data.data || !Array.isArray(data.data)) {
        setUserList([]);
      }
      setUserList(data.data);
    });
  }, [email])

  const handleTransferOwnership = () => {
    if (selected === "" || email === "") {
      return;
    }
    update("users/transfer", {
      from: email,
      to: selected,
    }).then(() => {
      showSuccessSnackbar(`Account is transferred to ${selected}.`)
      setSuccess(true);
    }).catch(() => {
      showErrorSnackbar("Account not transferred. Please try again.")
    }).finally(() => {
      handleDismiss();
    })
  }

  if (success) {
    return <Redirect to="/users" />
  }

  return <Dialog open={open} fullWidth maxWidth="xs" onClose={handleDismiss}>
    <DialogTitle>
      Transfer Ownership
    </DialogTitle>
    <DialogContent>
      {userList.length > 0 ? <List>
        {userList.map((user) => (<ListItemButton dense id={user._id} onClick={() => {
          setSelected(user.email)
        }} selected={selected === user.email}>
          <ListItemText primary={user.name} secondary={user.email} />
        </ListItemButton>))}
      </List> : <Typography variant={"caption"}>No options available. Make sure that this user is disabled and there is at least one active user with the same role, from the same country and facility.</Typography>}
    </DialogContent>
    {selected !== "" && <DialogActions>
      <Button onClick={handleTransferOwnership}>Confirm</Button>
    </DialogActions>}
  </Dialog>
}
export default TransferOwnershipDialog;