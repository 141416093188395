import { useEffect, useState, useRef, useCallback } from "react";
import { query } from "../services/api/common"
import { setDisableSelectedCountry, setSelectedCountry } from "../redux/dispatchers";
import { useSelector } from "react-redux";

const useDisableCountrySelection = (state, countryKey, facilityKey, isFetching) => {
  const [countrySelected, setCountrySelected] = useState(null);
  const [facilitySelected, setFacilitySelected] = useState(null);
  const [hasConflict, setHasConflict] = useState(false);

  const initialCountry = useRef(null);
  const countrySelectedInMainMenu = useSelector((state) => state.selectedCountry.selectedCountry);

  useEffect(() => {
    if (initialCountry.current === null) {
      initialCountry.current = countrySelectedInMainMenu;
    }
  }, [countrySelectedInMainMenu])

  const getSelectedFromState = useCallback((state, searchKey) => {
    if (!!!searchKey) {
      return null;
    }
    if (state === null || state === undefined) {
      return null;
    }
    if (Array.isArray(state)) {
      for (const val of state) {
        const code = getSelectedFromState(val, searchKey);
        if (code !== null) {
          return code;
        }
      }
    }
    if (typeof state === "object") {
      for (var key in state) {
        if (key === searchKey) {
          return state[key];
        }
        const code = getSelectedFromState(state[key], searchKey)
        if (code !== null) {
          return code;
        }
      }
    }
    return null;
  }, []);

  useEffect(() => {
    setCountrySelected(getSelectedFromState(state, countryKey));
  }, [state, countryKey, getSelectedFromState]);

  useEffect(() => {
    setFacilitySelected(getSelectedFromState(state, facilityKey))
  }, [state, facilityKey, getSelectedFromState]);

  useEffect(() => {
    if (isFetching) return;
    if (!!facilitySelected) {
      query(`facility/country/${facilitySelected}`).then((res) => {
        const facility = res.data;
        if (facility?.countryCode !== undefined) {
          if (!!countrySelected) {
            const countryConflict = facility.countryCode !== countrySelected;
            if (countryConflict) {
              setSelectedCountry(countrySelected);
            } else {
              setSelectedCountry(facility.countryCode);
            }
            setHasConflict(countryConflict);
          } else {
            setSelectedCountry(facility.countryCode);
          }
        } else {
          if (!!countrySelected) {
            setSelectedCountry(countrySelected);
          } else {
            setSelectedCountry(countrySelectedInMainMenu);
          }
        }
      }).catch(() => {
        if (!!countrySelected) {
          setSelectedCountry(countrySelected);
        } else {
          setSelectedCountry(countrySelectedInMainMenu);
        }
      })
    } else {
      if (!!countrySelected) {
        setSelectedCountry(countrySelected);
      } else {
        setSelectedCountry(countrySelectedInMainMenu);
      }
    }
  }, [facilitySelected, countrySelected, countrySelectedInMainMenu, isFetching]);

  useEffect(() => {
    if (!!facilitySelected || !!countrySelected) {
      setDisableSelectedCountry(true)
    } else {
      setDisableSelectedCountry(false)
    }
  }, [facilitySelected, countrySelected])

  useEffect(() => {
    return () => {
      setDisableSelectedCountry(false);
      if (!!initialCountry.current) {
        setSelectedCountry(initialCountry.current);
      } else {
        setSelectedCountry('Global');
      }
    };
  }, [])

  return { countrySelected, hasConflict };
}

export default useDisableCountrySelection;