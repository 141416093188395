import { useSelector } from "react-redux";
import { getOne } from "../services/api/common";
import { validateFields } from "../config/validation/yup";
import useFormConfig from "../config/useFormConfig";
import { v4 as uuid } from "uuid"
import { useEffect, useState, useMemo } from "react";
import { clearFormData, setFormData } from "../redux/dispatchers";
import useDisableCountrySelection from "./useDisableCountrySelection";
import { useLocation } from "react-router-dom";

const validationInitialState = { general: false, demographics: false, labs: false, condition: false, history: false, drugs: false };

const useMedicalRecordForm = (medicalRecordId, patientId) => {
  const { search } = useLocation();

  const [currentFormState, setCurrentFormState] = useState({
    general: null,
    demographics: null,
    labs: null,
    history_cvrf: null,
    condition: null,
    drugs: null,
  });

  const storedFormState = useSelector((state) => state.form);
  const storedGeneral = storedFormState?.general;

  const config = useFormConfig();

  const [validationResult, setValidationResult] = useState(validationInitialState);
  const [refetchUUID, setRefetchUUID] = useState(uuid());
  const [hasPatientError, setHasPatientError] = useState(false);
  const [hasMedicalRecordError, setHasMedicalRecordError] = useState(false);

  useEffect(() => {
    return () => { clearFormData() };
  }, [])

  const [patientIdFromMedicalRecord, setPatientIdFromMedicalRecord] = useState(patientId);
  const [medicalRecordIdFromPatient, setMedicalRecordIdFromPatient] = useState(medicalRecordId);
  const [medicalRecordIsArchive, setMedicalRecordIsArchive] = useState(false);
  const [patientIsArchive, setPatientIsArchive] = useState(false);

  const [isFetching, setIsFetching] = useState(false);

  const actualMedicalRecordId = storedGeneral?.record?.recordId ?? medicalRecordIdFromPatient ?? medicalRecordId;
  const actualPatientId = storedGeneral?._id ?? patientIdFromMedicalRecord ?? patientId;

  const isArchive = medicalRecordIsArchive && patientIsArchive;

  const selectedCountry = useSelector((state) => state.selectedCountry.selectedCountry);

  let mode = new URLSearchParams(search).get('mode');

  useEffect(() => {
    setCurrentFormState({
      general: storedFormState?.general ?? null,
      demographics: storedFormState?.demographics ?? null,
      labs: storedFormState?.labs ?? null,
      history_cvrf: storedFormState?.history_cvrf ?? null,
      condition: storedFormState?.condition ?? null,
      drugs: storedFormState?.drugs ?? null,
    })
  }, [storedFormState])

  useEffect(() => {
    let isActive = true;

    if (!!actualMedicalRecordId) {
      setIsFetching(true)
      getOne("medicalRecords", actualMedicalRecordId).then((medicalRecord) => {
        if (isActive) {
          setPatientIdFromMedicalRecord(medicalRecord?.data?.patientId);
          setMedicalRecordIsArchive(medicalRecord?.data?.isArchive);
          const {
            demographics = null,
            history_cvrf = null,
            condition = null,
            labs = null,
            drugs = null,
          } = medicalRecord?.data?.formData ?? {};
          setFormData("demographics", demographics);
          setFormData("history_cvrf", history_cvrf);
          setFormData("condition", condition);
          setFormData("labs", labs);
          setFormData("drugs", drugs);
          setHasMedicalRecordError(false);
        }
      }).catch(() => {
        if (isActive) {
          setHasMedicalRecordError(true);
          setFormData("demographics", undefined);
          setFormData("history_cvrf", undefined);
          setFormData("condition", undefined);
          setFormData("labs", undefined);
          setFormData("drugs", undefined);
          setPatientIdFromMedicalRecord(patientId);
          setMedicalRecordIsArchive(false);
        }
      }).finally(() => {
        setIsFetching(false);
      })
    } else {
      setFormData("demographics", undefined);
      setFormData("history_cvrf", undefined);
      setFormData("condition", undefined);
      setFormData("labs", undefined);
      setFormData("drugs", undefined);
    }

    return () => {
      isActive = false;
    }
  }, [actualMedicalRecordId, patientId, refetchUUID]);

  useEffect(() => {
    let isActive = true;
    if (!!actualPatientId) {
      setIsFetching(true);
      getOne(
        "patients",
        actualPatientId,
      ).then((general) => {
        if (isActive) {
          setFormData("general", general.data);
          setPatientIsArchive(general.data.isArchive);
          setMedicalRecordIdFromPatient(general.data.record.recordId);
          setHasPatientError(false);
        }
      }).catch(() => {
        if (isActive) {
          setFormData("general", undefined);
          setPatientIsArchive(false);
          setMedicalRecordIdFromPatient(medicalRecordId);
          setHasPatientError(true)
        }
      }).finally(() => {
        setIsFetching(false);
      })
    } else {
      setFormData("general", undefined);
    }
  }, [actualPatientId, medicalRecordId, refetchUUID])

  useEffect(() => {
    if (typeof mode === "string") setRefetchUUID(uuid());
  }, [mode]);

  const hasFetchError = hasMedicalRecordError || hasPatientError;
  const isLoading = !hasFetchError && Object.values(storedFormState).some((state) => state === undefined)
  const { hasConflict } = useDisableCountrySelection(currentFormState, null, "facilityName", isFetching);

  useEffect(() => {
    if (isArchive) {
      setValidationResult(validationInitialState);
      return;
    }
    let outdatedValidation = false;
    const validateAllSections = async () => {
      return {
        general: await validateFields(storedFormState.general, config.general.validation, false),
        demographics: await validateFields(storedFormState.demographics, config.demographics.validation, false),
        labs: await validateFields(storedFormState.labs, config.labs.validation, false),
        condition: await validateFields(storedFormState.condition, config.condition.validation, false),
        history_cvrf: await validateFields(storedFormState.history_cvrf, config.history_cvrf.validation, false),
        drugs: await validateFields(storedFormState.drugs, config.drugs.validation, false),
      }
    }
    validateAllSections().then((result) => {
      if (!outdatedValidation) setValidationResult({ general: result.general[0], demographics: result.demographics[0], labs: result.labs[0], condition: result.condition[0], history_cvrf: result.history_cvrf[0], drugs: result.drugs[0] });
    })

    return () => {
      outdatedValidation = true;
    }
  }, [config, storedFormState, isArchive]);

  const actualConfig = useMemo(() => {
    return { ...config, general: { ...config.general, config: config?.general?.config(isArchive, patientId, selectedCountry) } }
  }, [config, isArchive, patientId, selectedCountry]);

  const updateCurrentFormStateForSection = (sectionName, state) => {
    switch (sectionName) {
      case "general":
        setCurrentFormState((currentFormState) => ({ ...currentFormState, general: state }));
        break;
      case "demographics":
        setCurrentFormState((currentFormState) => ({ ...currentFormState, demographics: state }));
        break;
      case "labs":
        setCurrentFormState((currentFormState) => ({ ...currentFormState, labs: state }));
        break;
      case "history_cvrf":
        setCurrentFormState((currentFormState) => ({ ...currentFormState, history_cvrf: state }));
        break;
      case "condition":
        setCurrentFormState((currentFormState) => ({ ...currentFormState, condition: state }));
        break;
      case "drugs":
        setCurrentFormState((currentFormState) => ({ ...currentFormState, drugs: state }));
        break;
      default:
        break;
    }
  }

  const emitFunctions = useMemo(() => ({
    general: (state) => updateCurrentFormStateForSection("general", state),
    demographics: (state) => updateCurrentFormStateForSection("demographics", state),
    labs: (state) => updateCurrentFormStateForSection("labs", state),
    history_cvrf: (state) => updateCurrentFormStateForSection("history_cvrf", state),
    condition: (state) => updateCurrentFormStateForSection("condition", state),
    drugs: (state) => updateCurrentFormStateForSection("drugs", state),
  }), [])

  const queryFunctions = useMemo(() => {
    let funcType = "createFunc";
    if (storedGeneral?._id !== undefined && storedGeneral?.record?.recordId !== undefined) {
      funcType = "updateFunc";
    }
    if (hasConflict) {
      return {};
    }
    return {
      general: (data, id) => config?.general?.[funcType](data, id).then((response) => { setFormData("general", response.data); setRefetchUUID(uuid()); return response; }),
      demographics: (data, id) => config?.demographics?.[funcType](data, id).then((response) => { setFormData("demographics", response.data); setRefetchUUID(uuid()); return response; }),
      labs: (data, id) => config?.labs?.[funcType](data, id).then((response) => { setFormData("labs", response.data); setRefetchUUID(uuid()); return response; }),
      history_cvrf: (data, id) => config?.history_cvrf?.[funcType](data, id).then((response) => { setFormData("history_cvrf", response.data); setRefetchUUID(uuid()); return response; }),
      condition: (data, id) => config?.condition?.[funcType](data, id).then((response) => { setFormData("condition", response.data); setRefetchUUID(uuid()); return response; }),
      drugs: (data, id) => config?.drugs?.[funcType](data, id).then((response) => { setFormData("drugs", response.data); setRefetchUUID(uuid()); return response; }),
    }
  }, [config, storedGeneral?._id, storedGeneral?.record?.recordId, hasConflict])

  return { validationResult, config: actualConfig, queryFunctions, emitFunctions, isFetching, isLoading, hasFetchError, storedFormState, patientId: actualPatientId, medicalRecordId: actualMedicalRecordId, isArchive }
}

export default useMedicalRecordForm;