import { SNACKBAR_SUCCESS, SNACKBAR_ERROR, HIDE_SNACKBAR } from "../types";

export const showSuccessSnackbarAction = (message) => {
  return {
    type: SNACKBAR_SUCCESS,
    payload: {
      open: true,
      message: message,
      alertType: "success",
    },
  };
};

export const showErrorSnackbarAction = (message) => {
  return {
    type: SNACKBAR_ERROR,
    payload: {
      open: true,
      message: message,
      alertType: "error",
    },
  };
};

export const hideSnackbarAction = () => {
  return {
    type: HIDE_SNACKBAR,
  };
};
