import React, { useEffect, useMemo } from "react";
import { CustomScrollMenu } from "../common/custom-scroll-menu";
import { setTitle } from "../../redux/dispatchers";
import { useParams } from "react-router-dom";
import GenericForm from "../medical-record-form/generic-form";
import useDisableForm from "../common/useDisableForm";
import { CustomTable } from "../common/custom-table";
import { useSelector } from "react-redux";
import { Action, Subject } from "../../constants/permissions";
import useCommonAuthorization from "../../hooks/useCommonAuthorization";

import { update } from "../../services/api/common";
import {
  hideModal,
  showErrorSnackbar,
  showModal,
  showSuccessSnackbar,
} from "../../redux/dispatchers";
import useMedicalRecordForm from "../../hooks/useMedicalRecordForm";
import { Typography, Box } from "@material-ui/core";
import useCustomScrollMenuControl from "../../hooks/useCustomScrollMenuControl";
import BackButton from "../common/back-button";
import { FormModeDropdown } from "../common/form-mode-dropdown";

export const PatientTabs = () => {
  const { id } = useParams();

  useEffect(() => {
    setTitle("Patient");
  }, []);


  const { validationResult, isLoading, config, queryFunctions, hasFetchError, storedFormState, patientId, medicalRecordId, emitFunctions } = useMedicalRecordForm(null, id);

  const { disableForm } = useDisableForm(Subject.Record, medicalRecordId);

  const { tabIndex, redirectOnChangePage } = useCustomScrollMenuControl(2);

  const selectedCountry = useSelector((state) => state.selectedCountry.selectedCountry);

  const fetchOptionsArgs = useMemo(() => ({ countryCode: selectedCountry }), [selectedCountry])

  if (hasFetchError) return <Typography>Medical record is not available. Please refresh the page or select another medical record.</Typography>

  return (
    !isLoading && (
      <>
        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <BackButton />
          <FormModeDropdown />
        </Box>
        <CustomScrollMenu
          callback={redirectOnChangePage}
          forceValue={tabIndex}
          components={[
            {
              label: "General",
              showWarning: validationResult.general,
              contents: (
                <GenericForm
                  disableForm={disableForm}
                  formConfig={config?.general?.config}
                  validationSchema={config?.general?.validation}
                  onSaveAction={queryFunctions?.general}
                  storedState={storedFormState?.general}
                  completeStateForDerivation={storedFormState}
                  currentEditingId={patientId}
                  emitCurrentState={emitFunctions?.general}
                  alertUnsaved
                  checkUnsavedChangesMode="partial"
                  fetchOptionsArgs={fetchOptionsArgs}
                />
              ),
            },
            {
              label: "Medical Records",
              // TODO authz
              contents: <PatientMedicalRecordList patientId={id} />,
            },
          ]}
        />
      </>
    )
  );
};

function PatientMedicalRecordList({ patientId }) {
  const checkRole = useCommonAuthorization();
  return (
    <CustomTable
      old
      source={"medicalRecords"}
      noCreate={!checkRole(Subject.Record, Action.Create)}
      noSearch={!checkRole(Subject.Record, Action.Search)}
      noExport={!checkRole(Subject.Record, Action.Export)}
      noSelectAll={!checkRole(Subject.Record, Action.Delete)}
      canSelectRow={(row) => row?.permissions?.[Action.Delete] ?? false}
      onClick={() => { }}
      defaultOrderBy="createdAt"
      defaultOrder={2}
      filters={[
        { filterItem: "patientId", filterValue: patientId },
        { filterItem: "isDraft", filterValue: "false" },
        { filterItem: "isArchive", filterValue: "false" },
      ]}
      fields={[
        {
          id: "facilityName",
          label: "Facility Name",
        },
        {
          id: "patientCode",
          label: "Facility Code",
        },
        {
          id: "createdBy",
          label: "Created By",
        },
        {
          id: "createdAt",
          label: "Created On",
          date: true,
        },
        {
          id: "actions",
          label: "Actions",
          action: true,
          actionConfig: {
            view: {
              destination: "medicalRecords",
              newTab: true,
              getViewId: (row) => {
                if (row?.permissions?.[Action.Read]) {
                  return row.id;
                }
                return undefined;
              },
            },
            edit: {
              destination: "medicalRecords",
              getEditId: (row) => {
                if (row?.permissions?.[Action.Update]) {
                  return row.id;
                }
                return undefined;
              },
              newTab: false,
            },
            yes: {
              showWhen: [{ field: "status", value: "Pending" }],
              getYesAction: (row) => {
                if (row?.status !== "Pending" || !row?.permissions?.[Action.Approve]) return undefined;
                return (action, data, reloadData, setReloadData) => {
                  let title = action === "approve" ? "Approving" : "Denying";
                  showModal({
                    type: "confirmationModal",
                    title: `${title} Medical Record`,
                    content: `Are you sure you want to ${action} this medical record?`,
                    confirm: "Confirm",
                    cancel: "Cancel",
                    onConfirm: () => {
                      if (action === "approve") {
                        data["status"] = "Approved";
                      } else {
                        data["status"] = "Denied";
                      }
                      const { _id, ...rest } = data;
                      update("medicalRecords/status", rest)
                        .then(() => {
                          showSuccessSnackbar("The medical record status has been updated");
                          if (!!setReloadData) setReloadData(!reloadData);
                        })
                        .catch((e) => showErrorSnackbar(e))
                        .finally(() => hideModal());
                    },
                  });
                }
              },
            },
            no: {
              showWhen: [{ field: "status", value: "Pending" }],
              getNoAction: (row) => {
                if (row?.status !== "Pending" || !row?.permissions?.[Action.Deny]) return undefined;
                return (action, data, reloadData, setReloadData) => {
                  let title = action === "approve" ? "Approving" : "Denying";
                  showModal({
                    type: "confirmationModal",
                    title: `${title} Medical Record`,
                    content: `Are you sure you want to ${action} this medical record?`,
                    confirm: "Confirm",
                    cancel: "Cancel",
                    onConfirm: () => {
                      if (action === "approve") {
                        data["status"] = "Approved";
                      } else {
                        data["status"] = "Denied";
                      }
                      const { _id, ...rest } = data;
                      update("medicalRecords/status", rest)
                        .then(() => {
                          showSuccessSnackbar("The medical record status has been updated");
                          if (!!setReloadData) setReloadData(!reloadData);
                        })
                        .catch((e) => showErrorSnackbar(e))
                        .finally(() => hideModal());
                    },
                  });
                }
              },
            },
          }
        },
      ]}
    // createAction={clearFormData}
    />
  );
}
