import {
  EDUCATION_LEVEL_CHOICES,
  ETHNIC_CHOICES,
  GDM_CHOICES,
  NUMBER_OF_PREGNANCY,
  PAYMENT_STATUS_CHOICES,
  PREV_GDM,
  SEX_CHOICES,
  VIETNAM_EDUCATION_LEVEL_CHOICES,
  VIETNAM_ETHNIC_CHOICES,
  VIETNAM_PAYMENT_STATUS_CHOICES
} from '../../../constants/demographics-choices';
import { string } from '../../yup';

export const globalDemographicsValidationSchema = {
  dateOfHospitalVisit: string().required().checkDate(),
  ageAtHospitalVisit: string()
    .required()
    .numberRangeCheck()
    .decimalPointCheck(3),
  sex: string()
    .required()
    .oneOf(SEX_CHOICES.map((choice) => choice.id)),
  otherSex: string().when('sex', {
    is: 'others',
    then: () => string().required(),
    otherwise: () => string().length(0),
  }),
  dateOfBirth: string().required().checkDate(),
  ethnicity: string()
    .required(),
  paymentStatus: string()
    .required(),
  educationLevel: string()
    .required(),
  GDMHistory: string().when('sex', {
    is: 'female',
    then: () =>
      string()
        .required()
        .oneOf(GDM_CHOICES.map((choice) => choice.id)),
    otherwise: () => string().length(0),
  }),
  prevGDMHistory: string().when('GDMHistory', {
    is: 'yes',
    then: () =>
      string()
        .required()
        .oneOf(PREV_GDM.map((choice) => choice.id)),
    otherwise: () => string().length(0),
  }),
  insulinTreatment: string().when('GDMHistory', {
    is: 'yes',
    then: () => string().required().oneOf(['yes', 'no', 'na']),
    otherwise: () => string().length(0),
  }),
  numberOfPregnancy: string().when('GDMHistory', {
    is: 'yes',
    then: () =>
      string()
        .required()
        .oneOf(NUMBER_OF_PREGNANCY.map((choice) => choice.id)),
    otherwise: () => string().length(0),
  })
};

export const malaysiaDemographicsValidationSchema = {
  dateOfHospitalVisit: string().required().checkDate(),
  ageAtHospitalVisit: string()
    .required()
    .numberRangeCheck()
    .decimalPointCheck(3),
  sex: string()
    .required()
    .oneOf(SEX_CHOICES.map((choice) => choice.id)),
  dateOfBirth: string().required().checkDate(),
  ethnicity: string()
    .required()
    .oneOf(ETHNIC_CHOICES.map((choice) => choice.id)),
  paymentStatus: string()
    .required()
    .oneOf(PAYMENT_STATUS_CHOICES.map((choice) => choice.id)),
  educationLevel: string()
    .required()
    .oneOf(EDUCATION_LEVEL_CHOICES.map((choice) => choice.id)),
  GDMHistory: string().when('sex', {
    is: 'female',
    then: () =>
      string()
        .required()
        .oneOf(GDM_CHOICES.map((choice) => choice.id)),
    otherwise: () => string().length(0),
  }),
  prevGDMHistory: string().when('GDMHistory', {
    is: 'yes',
    then: () =>
      string()
        .required()
        .oneOf(PREV_GDM.map((choice) => choice.id)),
    otherwise: () => string().length(0),
  }),
  insulinTreatment: string().when('GDMHistory', {
    is: 'yes',
    then: () => string().required().oneOf(['yes', 'no', 'na']),
    otherwise: () => string().length(0),
  }),
  numberOfPregnancy: string().when('GDMHistory', {
    is: 'yes',
    then: () =>
      string()
        .required()
        .oneOf(NUMBER_OF_PREGNANCY.map((choice) => choice.id)),
    otherwise: () => string().length(0),
  }),
  otherSex: string().when('sex', {
    is: 'others',
    then: () => string().required(),
    otherwise: () => string().length(0),
  }),
  otherEthnicity: string().when('ethnicity', {
    is: 'others',
    then: () => string().required(),
    otherwise: () => string().length(0),
  }),
  otherEducationLevel: string().when('educationLevel', {
    is: 'others',
    then: () => string().required(),
    otherwise: () => string().length(0),
  }),
};

export const vietnamDemographicsValidationSchema = {
  dateOfHospitalVisit: string().required().checkDate(),
  ageAtHospitalVisit: string()
    .required()
    .numberRangeCheck()
    .decimalPointCheck(3),
  sex: string()
    .required()
    .oneOf(SEX_CHOICES.map((choice) => choice.id)),
  dateOfBirth: string().required().checkDate(),
  ethnicity: string()
    .required()
    .oneOf(VIETNAM_ETHNIC_CHOICES.map((choice) => choice.id)),
  paymentStatus: string()
    .required()
    .oneOf(VIETNAM_PAYMENT_STATUS_CHOICES.map((choice) => choice.id)),
  educationLevel: string()
    .required()
    .oneOf(VIETNAM_EDUCATION_LEVEL_CHOICES.map((choice) => choice.id)),
  GDMHistory: string().when('sex', {
    is: 'female',
    then: () =>
      string()
        .required()
        .oneOf(GDM_CHOICES.map((choice) => choice.id)),
    otherwise: () => string().length(0),
  }),
  prevGDMHistory: string().when('GDMHistory', {
    is: 'yes',
    then: () =>
      string()
        .required()
        .oneOf(PREV_GDM.map((choice) => choice.id)),
    otherwise: () => string().length(0),
  }),
  insulinTreatment: string().when('GDMHistory', {
    is: 'yes',
    then: () => string().required().oneOf(['yes', 'no', 'na']),
    otherwise: () => string().length(0),
  }),
  numberOfPregnancy: string().when('GDMHistory', {
    is: 'yes',
    then: () =>
      string()
        .required()
        .oneOf(NUMBER_OF_PREGNANCY.map((choice) => choice.id)),
    otherwise: () => string().length(0),
  }),
  otherSex: string().when('sex', {
    is: 'others',
    then: () => string().required(),
    otherwise: () => string().length(0),
  }),
  otherEthnicity: string().when('ethnicity', {
    is: 'others',
    then: () => string().required(),
    otherwise: () => string().length(0),
  }),
  otherEducationLevel: string().when('educationLevel', {
    is: 'others',
    then: () => string().required(),
    otherwise: () => string().length(0),
  }),
};
