import DefaultIcon from "@material-ui/icons/ViewList";
import PeopleIcon from "@material-ui/icons/People";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Business from "@material-ui/icons/Business";
import React from "react";
import ListAltIcon from "@material-ui/icons/ListAlt";
import FlagIcon from '@material-ui/icons/Flag';
import { Subject } from "../../constants/permissions";

export const mainMenuListItem = [
  {
    id: "1",
    title: "Dashboard",
    pathname: "/",
    icon: <DashboardIcon />,
    enabled: process.env.REACT_APP_DASHBOARD_ENABLED,
  },
  {
    id: "2",
    title: "Country",
    pathname: "/country",
    icon: <FlagIcon />,
    subject: Subject.Country,
    enabled: process.env.REACT_APP_FACILITIES_ENABLED,
  },
  {
    id: "3",
    title: "Facility",
    pathname: "/facility",
    icon: <Business />,
    subject: Subject.Facility,
    enabled: process.env.REACT_APP_FACILITIES_ENABLED,
  },
  {
    id: "4",
    title: "Users",
    pathname: "/users",
    icon: <DefaultIcon />,
    subject: Subject.User,
    enabled: process.env.REACT_APP_USERS_ENABLED,
  },
  {
    id: "5",
    title: "Patients",
    pathname: "/patients",
    icon: <PeopleIcon />,
    subject: Subject.Patient,
    enabled: process.env.REACT_APP_PATIENTS_ENABLED,
  },
  {
    id: "6",
    collapsible: true,
    title: "Medical Record",
    icon: <InsertDriveFile />,
    subject: Subject.Record,
    enabled: process.env.REACT_APP_MEDICAL_RECORDS_ENABLED,
    children: [
      {
        id: "1",
        pathname: "/medicalrecord",
        title: "Completed",
        icon: <ArrowRightAltIcon />,
        subject: Subject.Record,
        enabled: process.env.REACT_APP_MEDICAL_RECORDS_ENABLED,
      },
      {
        id: "2",
        pathname: "/medicalrecord/drafts",
        title: "My Drafts",
        icon: <ArrowRightAltIcon />,
        subject: Subject.Record,
        enabled: process.env.REACT_APP_MEDICAL_RECORDS_ENABLED,
      },
      {
        id: "3",
        pathname: "/medicalrecord/archives",
        title: "All Archives",
        icon: <ArrowRightAltIcon />,
        subject: Subject.Record,
        enabled: process.env.REACT_APP_ARCHIVES_ENABLED,
      },
    ],
  },
  {
    id: "7",
    title: "Requests",
    pathname: "/requests",
    icon: <ListAltIcon />,
    subject: Subject.Request,
    enabled: process.env.REACT_APP_REQUESTS_ENABLED,
  },
];
