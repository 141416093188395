import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { parseDate } from "../../utils/commonUtils";

const useStyles = makeStyles((theme) => ({
  input: {
    "&::placeholder": {
      opacity: "1",
    },
  },
}));

export default function CustomCalendar(props) {
  const [date, setDate] = useState(null);
  const [changeByUser, setChangeByUser] = useState(false);
  const { name, label, value, onChange, onBlur, variant, ...rest } = props;
  const classes = useStyles();

  useEffect(() => {
    if (!!value) setDate(parseDate(value));
  }, [value]);

  useEffect(() => {
    if (changeByUser) {
      let temp = null;
      if (date !== null) {
        temp = new Date(date).toLocaleDateString("en-GB");
      }
      onChange({
        target: {
          name: name,
          value: temp,
        },
      });
      setChangeByUser(false);
    }
  }, [changeByUser, date, name, onChange]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        label={label}
        value={date}
        inputVariant={variant}
        onChange={(selectedDate) => {
          setChangeByUser(true);
          setDate(selectedDate);
        }}
        placeholder="dd/mm/yyyy"
        format="dd/MM/yyyy"
        autoOk
        InputProps={{ classes: { input: classes.input } }}
        InputLabelProps={{
          shrink: true,
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
}
