import {
  addMethod,
  setLocale,
  string,
  object
} from "yup";
import { isValid, parse } from "date-fns";

setLocale({
  mixed: {
    notType: function notType(_ref) {
      switch (_ref.type) {
        case "number":
          return "Value should be a number.";
        case "string":
          return "Value should be a string.";
        case "date":
          return "Value should be a date.";
        default:
          return "Value is of wrong type.";
      }
    },
    required: "This field cannot be empty",
    oneOf: "This is not a valid option",
  },
  number: {
    integer: "This should be a positive integer",
    positive: "This should be a positive integer",
    // eslint-disable-next-line no-template-curly-in-string
    max: "This should be equal to or less than ${max}",
  },
  string: {
    matches: "This does not match the format",
  },
});

const buildValidationSchemaShape = (response, defaultValidationSchemaShape) => {
  if (!response || !defaultValidationSchemaShape) return;
  let schemaShape = {};
  Object.keys(response).forEach(
    (key) =>
    (schemaShape = {
      ...schemaShape,
      [key]: defaultValidationSchemaShape[key],
    })
  );
  return schemaShape;
};

const buildValidationSchema = (validationSchemaShape) =>
  object(validationSchemaShape).required().noUnknown(true);

export const validateFields = async (
  response,
  defaultValidationSchemaShape,
  filterSchemaShape = false,
  validationSchemaBuilder = buildValidationSchema
) => {
  let schemaShape;
  if (filterSchemaShape)
    schemaShape = buildValidationSchemaShape(
      response,
      defaultValidationSchemaShape
    );
  else schemaShape = defaultValidationSchemaShape;
  const validationSchema = validationSchemaBuilder(schemaShape);
  return validationSchema
    .validate(response, { abortEarly: false, stripUnknown: false })
    .then(() => [false])
    .catch((err) => {
      const { inner } = err;
      let result = {};
      inner.forEach((innerError) => {
        const path = innerError.path;
        let target = result;
        const pathToken = path.split(".");
        for (var i = 0; i < pathToken.length - 1; i++) {
          const key = pathToken[i];
          if (!target.hasOwnProperty(key)) {
            target[key] = {};
          }
          target = target[key];
        }
        target[pathToken[i]] = innerError.errors;
      });
      return [true, result];
    });
};

export const isValidResponse = async (
  response,
  defaultValidationSchemaShape,
  validationSchemaBuilder = buildValidationSchema
) => {
  const schemaShape = buildValidationSchemaShape(
    response,
    defaultValidationSchemaShape
  );
  const validationSchema = validationSchemaBuilder(schemaShape);
  return validationSchema.isValid(response).then((res) => res);
};

addMethod(string, 'checkDate', function (dateFormat = 'dd/MM/yyyy') {
  return this.test('date', 'Invalid Date', (date) => {
    if (!date) return true;
    return isValid(parse(date, dateFormat, new Date()));
  });
});

addMethod(string, 'decimalPointCheck', function (decimalPoint) {
  return this.test(
    'decimalPlace',
    `Value should have exactly ${decimalPoint} decimal place.`,
    (num) => {
      if (!num || num === '-9999') return true;
      const strAfterDecimal = num.split('.')[1] ?? '';
      return strAfterDecimal.length === decimalPoint;
    },
  );
});

addMethod(string, 'numberRangeCheck', function () {
  return this.test(
    'numberRange',
    `Only positive values / -9999 are allowed`,
    (num) => {
      if (!num) return true;
      return Number(num) >= 0 || Number(num) === -9999;
    },
  );
});

export {
  addMethod,
  array,
  boolean,
  lazy,
  mixed,
  number,
  object,
  string,
} from 'yup';
