import { Accordion, AccordionSummary, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { useState, useEffect } from "react";

/**
 *
 * @param {string} headerTitle - title to show on the collapsible header
 * @param {boolean} defaultExpanded - decide whether the panel is expanded by default
 * @param {JSX.Element} children
 * @returns {JSX.Element} a collapsible header with its content
 */
function CollapsiblePanel({ headerTitle, children, searching }) {
  const [expandedState, setExpandedState] = useState(searching);

  useEffect(() => {
    setExpandedState(searching);
  }, [searching]);

  return (
    <Accordion
      expanded={expandedState}
      onChange={() => setExpandedState(!expandedState)}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{headerTitle}</Typography>
      </AccordionSummary>
      {children}
    </Accordion>
  );
}

export default CollapsiblePanel;
