import React, { useEffect, useState } from "react";
import { Grid, makeStyles, MenuItem, TextField, InputAdornment, IconButton, Box, Button, Paper } from "@material-ui/core";
import { facilityValidationSchema } from "../../config/validation/schemas";
import { validateFields } from "../../config/validation/yup";
import { useParams, useHistory } from "react-router-dom";
import FormWrapper from "../common/form-wrapper";
import {
  setTitle,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../redux/dispatchers";
import { create, getOne, update, queryList } from "../../services/api/common";
import { Subject } from "../../constants/permissions";
import useDisableForm from "../common/useDisableForm";
import { useSelector } from "react-redux";
import useDisableCountrySelection from "../../hooks/useDisableCountrySelection";
import ClearIcon from "@material-ui/icons/Clear"
import BackButton from "../common/back-button";
import { FormModeDropdown } from "../common/form-mode-dropdown";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
  },
  paper: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 5,
    marginRight: 5,
    padding: 50,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    marginBottom: 20,
  },
  button: {
    width: 200,
  },
}));

const initialState = {
  facilityCode: "",
  facilityName: "",
  countryCode: "",
}

export const FacilityForm = ({ isCreate, profileId }) => {
  const { id } = useParams();
  const history = useHistory();

  const classes = useStyles();

  const { disableForm } = useDisableForm(Subject.Facility, id, isCreate)

  const [state, setState] = useState(initialState);

  const [countries, setCountries] = useState([]);

  const [error, setError] = useState({});

  const selectedCountryCode = useSelector((state) => state.selectedCountry.selectedCountry);

  const { hasConflict } = useDisableCountrySelection(state, "countryCode", null);

  useEffect(() => {
    queryList("country/options", `countryCode=${selectedCountryCode}`).then((data) => {
      setCountries(data.data);
    })
  }, [selectedCountryCode])


  useEffect(() => {
    async function fetchData() {
      if (id) {
        getOne(
          "facility",
          id,
        ).then((resp) => resp.allData.data).then((data) => {
          const { facilityCode, facilityName, countryCode } = data;
          setState({ facilityCode, facilityName, countryCode });

          setTitle(facilityName);
        }).catch((e) => {
          showErrorSnackbar(e);
          setState(initialState);
        });
      }
    }
    fetchData();
  }, [id, disableForm]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [hasError, error] = await validateFields(
      state,
      facilityValidationSchema,
      false
    );
    if (isCreate) {
      if (!hasConflict && !hasError) {
        create("facility", {
          facilityName: state.facilityName,
          countryCode: state.countryCode,
        })
          .then((d) => {
            showSuccessSnackbar("Facility is saved successfully!");
            history.push("/facility");
          })
          .catch((e) => {
            showErrorSnackbar(e);
          });
      }
      setError(error);
    } else {
      if (!hasConflict && !hasError) {
        update("facility", { id, ...state })
          .then(() => {
            showSuccessSnackbar("Facility updated successfully");
            history.push("/facility");
          })
          .catch((e) => showErrorSnackbar(e));
      }
      setError(error);
    }
  };

  // press Enter to trigger submit
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  const clearCountry = () => {
    setState({ ...state, countryCode: "" });
  }

  return (
    <div className={classes.root}>
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <BackButton />
        {!isCreate && <FormModeDropdown />}
      </Box>
      <FormWrapper disableForm={disableForm}>
        <Paper className={classes.paper}>
          <form className={classes.form}>
            <Grid container spacing={3} style={{ marginBottom: 4 }}>
              {!isCreate && (
                <Grid item xs={12}>
                  <TextField
                    name="facilityCode"
                    label="Facility Code"
                    variant="outlined"
                    fullWidth
                    value={state.facilityCode}
                    onChange={handleChange}
                    error={!!error?.facilityCode}
                    helperText={error?.facilityCode}
                    disabled
                    onKeyUp={handleKeyPress}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  name="facilityName"
                  label="Facility Name"
                  variant="outlined"
                  fullWidth
                  value={state.facilityName}
                  onChange={handleChange}
                  error={!!error?.facilityName}
                  helperText={error?.facilityName}
                  onKeyUp={handleKeyPress}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  name="countryCode"
                  label="Country"
                  variant="outlined"
                  fullWidth
                  value={state.countryCode}
                  onChange={handleChange}
                  error={!!error?.countryCode}
                  helperText={error?.countryCode}
                  onKeyUp={handleKeyPress}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton onClick={() => clearCountry()}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  }}
                >
                  {countries.map((country, index) => (<MenuItem key={`${index}-${country.countryName}`} value={country.countryCode}>{country.countryName}</MenuItem>))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Button
                  name="save"
                  variant="outlined"
                  onClick={handleSubmit}
                  color="primary"
                  className={classes.button}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </FormWrapper>
    </div>
  );
};
