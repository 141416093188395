import { SET_FORM_DATA, CLEAR_FORM_DATA } from "../types";

export const initialState = {
  general: undefined,
  demographics: undefined,
  history_cvrf: undefined,
  condition: undefined,
  labs: undefined,
  drugs: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_DATA:
      return {
        ...state,
        [action.payload.tabName]: action.payload.dataObj,
      };
    case CLEAR_FORM_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
