import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles({
  link: {
    textDecoration: "none",
  },
  icon: {
    width: "0.5em",
    paddingLeft: 2,
  },
});

export const ReferenceField = ({ source, id }) => {
  const classes = useStyles();

  return (
    <Link href={`/${source}/${id}`} className={classes.link}>
      {id}
    </Link>
  );
};
