import { store } from "../store";
import { setFormDataAction, clearFormDataAction } from "../actions";

export const setFormData = async (tabName, dataObj) => {
  store.dispatch(setFormDataAction(tabName, dataObj));
};

export const clearFormData = () => {
  store.dispatch(clearFormDataAction());
};
