import { CustomTable } from "../common/custom-table";
import React, { useEffect } from "react";
import { clearFormData, setTitle } from "../../redux/dispatchers";
import { useSelector } from "react-redux";
import useCommonAuthorization from "../../hooks/useCommonAuthorization";
import { Action, Subject } from "../../constants/permissions";

import { update } from "../../services/api/common";
import {
  hideModal,
  showErrorSnackbar,
  showModal,
  showSuccessSnackbar,
} from "../../redux/dispatchers";

export default function ArchiveList() {
  const countryCode = useSelector((state) => state.selectedCountry.selectedCountry);
  useEffect(() => {
    setTitle("Archives");
  }, []);
  const checkRole = useCommonAuthorization();
  return (
    <CustomTable
      source="medicalRecords"
      old
      noCreate={!checkRole(Subject.Record, Action.Create)}
      noSearch={!checkRole(Subject.Record, Action.Search)}
      noExport={!checkRole(Subject.Record, Action.Export)}
      noSelectAll={!checkRole(Subject.Record, Action.Delete)}
      canSelectRow={(row) => row?.permissions?.[Action.Delete] ?? false}
      onClick={() => { }}
      defaultOrderBy="createdAt"
      defaultOrder={2}
      searchBarPlaceholder="Patient Code/Patient IC/Facility Name/Facility Code/Created By"
      customUrl="archives"
      filters={[
        {
          filterItem: "isArchive",
          filterValue: true,
        },
        {
          filterItem: "isDraft",
          filterValue: false,
        },
        ...(countryCode !== "Global" ? [{
          filterItem: "countryCode",
          filterValue: countryCode,
        }] : [])
      ]}
      fields={[
        {
          id: "patientCode",
          label: "Patient Code",
        },
        {
          id: "facilityName",
          label: "Facility Name",
        },
        {
          id: "clinicType",
          label: "Clinic Type",
        },
        {
          id: "facilityCode",
          label: "Facility Code",
        },
        {
          id: "createdAt",
          label: "Created On",
          date: true,
        },
        {
          id: "actions",
          label: "Actions",
          action: true,
          actionConfig: {
            view: {
              destination: "/medicalRecords",
              newTab: true,
              getViewId: (row) => {
                if (row?.permissions?.[Action.Read]) {
                  return row.id;
                }
                return undefined;
              },
            },
            edit: {
              destination: "/medicalRecords",
              getEditId: (row) => {
                if (row?.permissions?.[Action.Update]) {
                  return row.id;
                }
                return undefined;
              },
              newTab: false,
            },
            yes: {
              showWhen: [{ field: "status", value: "Pending" }],
              getYesAction: (row) => {
                if (row?.status !== "Pending" || !row?.permissions?.[Action.Approve]) return undefined;
                return (action, data, reloadData, setReloadData) => {
                  let title = action === "approve" ? "Approving" : "Denying";
                  showModal({
                    type: "confirmationModal",
                    title: `${title} Medical Record`,
                    content: `Are you sure you want to ${action} this medical record?`,
                    confirm: "Confirm",
                    cancel: "Cancel",
                    onConfirm: () => {
                      if (action === "approve") {
                        data["status"] = "Approved";
                      } else {
                        data["status"] = "Denied";
                      }
                      const { _id, ...rest } = data;
                      update("medicalRecords/status", rest)
                        .then(() => {
                          showSuccessSnackbar("The medical record status has been updated");
                          if (!!setReloadData) setReloadData(!reloadData);
                        })
                        .catch((e) => showErrorSnackbar(e))
                        .finally(() => hideModal());
                    },
                  });
                }
              },
            },
            no: {
              showWhen: [{ field: "status", value: "Pending" }],
              getNoAction: (row) => {
                if (row?.status !== "Pending" || !row?.permissions?.[Action.Deny]) return undefined;
                return (action, data, reloadData, setReloadData) => {
                  let title = action === "approve" ? "Approving" : "Denying";
                  showModal({
                    type: "confirmationModal",
                    title: `${title} Medical Record`,
                    content: `Are you sure you want to ${action} this medical record?`,
                    confirm: "Confirm",
                    cancel: "Cancel",
                    onConfirm: () => {
                      if (action === "approve") {
                        data["status"] = "Approved";
                      } else {
                        data["status"] = "Denied";
                      }
                      const { _id, ...rest } = data;
                      update("medicalRecords/status", rest)
                        .then(() => {
                          showSuccessSnackbar("The medical record status has been updated");
                          if (!!setReloadData) setReloadData(!reloadData);
                        })
                        .catch((e) => showErrorSnackbar(e))
                        .finally(() => hideModal());
                    },
                  });
                }
              },
            },
          }
        }
      ]}
      createAction={clearFormData}
    />
  );
}
