import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Grid, makeStyles, MenuItem, TextField, InputAdornment, IconButton, Box } from "@material-ui/core";
import { validateFields } from "../../config/validation/yup";
import { useParams, useHistory } from "react-router-dom";
import FormWrapper from "../common/form-wrapper";
import {
  setTitle,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../redux/dispatchers";
import { create, getOne, update } from "../../services/api/common";
import { countryValidationSchema } from "../../config/validation/schemas";
import { COUNTRY_CHOICES } from "../../constants/choices";
import { Subject } from "../../constants/permissions";
import useDisableForm from "../common/useDisableForm";
import useDisableCountrySelection from "../../hooks/useDisableCountrySelection";
import ClearIcon from "@material-ui/icons/Clear"
import { useSelector } from "react-redux";
import BackButton from "../common/back-button";
import { FormModeDropdown } from "../common/form-mode-dropdown";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
  },
  paper: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 5,
    marginRight: 5,
    padding: 50,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    marginBottom: 20,
  },
  button: {
    width: 200,
  },
}));

export const CountryForm = ({ isCreate }) => {
  const { id } = useParams();
  const history = useHistory();

  const classes = useStyles();

  const { disableForm } = useDisableForm(Subject.Country, id, isCreate);

  const [state, setState] = useState({
    countryCode: "",
    countryName: "",
    countryPrefix: "",
  });

  const [error, setError] = useState({});

  const { hasConflict } = useDisableCountrySelection(state, "countryCode", null);

  const countrySelectedInMainMenu = useSelector((state) => state.selectedCountry.selectedCountry);

  useEffect(() => {
    async function fetchData() {
      if (id) {
        getOne(
          "country",
          id,
        ).then((resp) => resp.allData.data).then((data) => {
          const { countryCode, countryName, countryPrefix } = data;
          setState({ countryCode, countryName, countryPrefix });

          setTitle(countryName);
        });
      }
    }
    fetchData();
  }, [id, disableForm]);

  useEffect(() => {
    if (!isCreate) return;
    const selectedCountry = COUNTRY_CHOICES.find((choice) => choice.id === state.countryCode);
    if (selectedCountry !== undefined)
      setState((state) => ({ ...state, countryName: selectedCountry.name }));
  }, [state.countryCode, isCreate]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setState({ ...state, [name]: value });
  };

  const clearCountry = () => {
    setState({ ...state, countryCode: "" });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [hasError, error] = await validateFields(
      state,
      countryValidationSchema,
      false
    );
    if (isCreate) {
      if (!hasConflict && !hasError) {
        create("country", {
          countryName: state.countryName,
          countryCode: state.countryCode,
          countryPrefix: state.countryPrefix,
        })
          .then((d) => {
            showSuccessSnackbar("Country is saved successfully!");
            history.push("/country");
          })
          .catch((e) => {
            showErrorSnackbar(e);
          });
      }
      setError(error);
    } else {
      if (!hasConflict && !hasError) {
        update("country", {
          id: id,
          countryName: state.countryName
        })
          .then(() => {
            showSuccessSnackbar("Country updated successfully");
            history.push("/country");
          })
          .catch((e) => showErrorSnackbar(e));
      }
      setError(error);
    }
  };

  // press Enter to trigger submit
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  const handleCountryPrefixBlur = (event) => {
    if (event.target.value === "") {
      return;
    }
    let value = Number(event.target.value);
    if (isNaN(value) || value === Infinity) {
      setState((state) => ({ ...state, countryPrefix: event.target.value }));
    } else {
      setState(state => ({ ...state, countryPrefix: String(value) }))
    }
  }

  const disableCountryField = !isCreate;

  return (
    <div className={classes.root}>
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <BackButton />
        {!isCreate && <FormModeDropdown />}
      </Box>
      <FormWrapper disableForm={disableForm}>
        <Paper className={classes.paper}>
          <form className={classes.form}>
            <Grid container spacing={3} style={{ marginBottom: 4 }}>
              <Grid item xs={12}>
                <TextField
                  name="countryCode"
                  label="Country"
                  variant="outlined"
                  fullWidth
                  value={state.countryCode}
                  onChange={handleChange}
                  error={!!error?.countryCode}
                  helperText={error?.countryCode}
                  select
                  disabled={disableCountryField}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton onClick={() => clearCountry()} disabled={disableCountryField}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  }}
                >
                  {COUNTRY_CHOICES.filter((country) => countrySelectedInMainMenu === "Global" || country.id === countrySelectedInMainMenu).map((choice) => (
                    <MenuItem key={choice.id} value={choice.id}>{choice.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="countryPrefix"
                  label="Country Prefix"
                  variant="outlined"
                  fullWidth
                  value={state.countryPrefix}
                  onChange={handleChange}
                  error={!!error?.countryPrefix}
                  helperText={error?.countryPrefix}
                  disabled={!isCreate}
                  onKeyUp={handleKeyPress}
                  onBlur={handleCountryPrefixBlur}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                name="save"
                variant="outlined"
                onClick={handleSubmit}
                color="primary"
                className={classes.button}
              >
                Save
              </Button>
            </Grid>
            {/* </Grid> */}
          </form>
        </Paper>
      </FormWrapper>
    </div>
  );
};
