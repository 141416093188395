import { SET_CHANGES_UNSAVED, SET_SHOW_UNSAVED_ALERT } from "../types";

const initialState = {
  unsaved: false,
  next: '',
}

const unsavedAlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHANGES_UNSAVED:
      return { ...state, unsaved: action.payload.unsaved };
    case SET_SHOW_UNSAVED_ALERT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default unsavedAlertReducer;