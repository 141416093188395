import { useEffect, useState, useCallback } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
  Chip,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { create, query } from "../../services/api/common";
import { useSelector } from "react-redux";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../redux/dispatchers";
import { useParams } from "react-router";
import { validateFields } from "../../config/validation/yup";
import { requestValidationSchema } from "../../config/validation/schemas";
import CustomCalendar from "../common/custom-calendar";
import { addDays } from "date-fns";
import RequestDetailsDialog from "./request-details-dialog";

export function RequestDialog({ initialAssignee, viewOnly }) {
  const user = useSelector((state) => state.authDetails);
  const patientCode = useSelector((state) => state.form?.general?.patientCode);
  const { id: recordId } = useParams();
  const [error, setError] = useState({});
  const [open, setOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [request, setRequest] = useState(null);
  const [canSendRequestToRA, setCanSendRequestToRA] = useState(false);
  const [canSendRequestToSC, setCanSendRequestToSC] = useState(false);

  const initialState = {
    reason: "",
    ...(canSendRequestToRA
      ? {
        assignee: "",
        accessExpiry: "",
        reminders: [],
        requestType: "Edit",
      }
      : { assignee: "", requestType: "" }),
  };

  const [state, setState] = useState(initialState);

  const fetchRequest = useCallback(() => {
    query(`/requests/record/${recordId}`)
      .then((resp) => {
        let index;
        for (index = 0; index < resp.data?.length; index++) {
          let request = resp.data[index];
          if (request.status === "Pending" || request.status === "Approved") {
            setRequest(request);
            break;
          }
        }
      })
      .catch((e) => { showErrorSnackbar(e); setRequest(null); });
  }, [recordId]);

  useEffect(() => {
    if (recordId !== undefined && userList.length === 0 && request === null) {
      query(`/users/options/requests?recordId=${recordId}`)
        .then((resp) => {
          let list = [];
          resp.data.forEach((userData) => {
            if (userData.role !== user.role) {
              list.push({ id: userData._id, label: userData.name });
              if (canSendRequestToRA && userData._id === initialAssignee) {
                setState(state => ({ ...state, assignee: initialAssignee }));
              }
            }
          });
          setUserList(list);
        })
        .catch((e) => { showErrorSnackbar(e); setUserList([]) });
    }
  }, [request, initialAssignee, canSendRequestToRA, recordId, userList.length, user.role]);

  useEffect(() => {
    if (recordId === undefined || recordId === "") {
      return;
    }
    query(`/requests/canirequest?recordId=${recordId}`).then((data) => {
      setCanSendRequestToRA(data.data.canSendRequestToRA);
      setCanSendRequestToSC(data.data.canSendRequestToSC);
    }).catch(() => {
      setCanSendRequestToRA(false);
      setCanSendRequestToSC(false);
    })
  }, [recordId])

  useEffect(() => {
    if (recordId !== undefined) {
      fetchRequest();
    }
  }, [recordId, fetchRequest]);

  useEffect(() => {
    for (var i = 0; i < state?.reminders?.length; i++) {
      if (error?.[`reminders[${i}]`]) {
        setError({ ...error, reminders: error[`reminders[${i}]`] });
        return;
      }
    }
  }, [state?.reminders, error]);

  const handleDismiss = () => {
    setOpen(!open);
    setState(initialState);
    setError({});
  };

  const handleSubmit = async () => {
    const [hasError, error] = await validateFields(
      state,
      {
        ...requestValidationSchema,
        ...(canSendRequestToSC ? { reminders: null, accessExpiry: null } : {}),
      },
      false
    );
    if (!hasError) {
      create("requests", {
        recordId: recordId,
        patientCode: patientCode,
        requestBy: user._id,
        ...state,
        ...(canSendRequestToRA ? { status: "Approved" } : {}),
      })
        .then(() => {
          handleDismiss();
          fetchRequest();
          showSuccessSnackbar("Your request has been submitted");
        })
        .catch((e) => showErrorSnackbar(e));
    }
    setError(error);
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setState(canSendRequestToRA ? {
      assignee: "",
      accessExpiry: "",
      reminders: [],
      requestType: "Edit",
    }
      : { assignee: "", requestType: "" })
  }, [canSendRequestToRA]);

  return (
    <>
      {(canSendRequestToRA || canSendRequestToSC || request !== null) && <RequestButton
        open={open}
        setOpen={setOpen}
        user={user}
        request={request}
        canSendRequestToSC={canSendRequestToSC}
        viewOnly={viewOnly}
      />}
      {!!request && (
        <RequestDetailsDialog
          open={open}
          setOpen={setOpen}
          data={request}
          fetchRequest={fetchRequest}
          viewOnly={viewOnly}
        />
      )}
      {!!!request && !viewOnly && (
        <Dialog open={open} fullWidth maxWidth="md" onClose={handleDismiss}>
          <DialogTitle disableTypography>
            <Typography variant="h5" color="textPrimary">
              {canSendRequestToSC ? "New Request" : "New Edit Request"}
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={3}>
              {canSendRequestToSC && (
                <Grid item xs={6}>
                  <SelectField
                    label="Request Type"
                    name="requestType"
                    value={state.requestType}
                    onChange={handleChange}
                    items={[
                      { id: "Edit", label: "Edit" },
                      { id: "Delete", label: "Delete" },
                    ]}
                    error={error}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <SelectField
                  label={
                    canSendRequestToRA
                      ? "Assignee"
                      : "Site PI"
                  }
                  name="assignee"
                  value={state.assignee}
                  onChange={handleChange}
                  items={userList}
                  error={error}
                />
              </Grid>
              {canSendRequestToRA && (
                <>
                  <Grid item xs={6}>
                    <CustomCalendar
                      label="Access expires on"
                      variant="outlined"
                      fullWidth
                      minDate={addDays(new Date(), 1)}
                      name="accessExpiry"
                      onChange={handleChange}
                      error={!!error?.accessExpiry}
                      helperText={error?.accessExpiry}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={["1", "2", "3"]}
                      defaultValue={[]}
                      onChange={(event, value) =>
                        setState({ ...state, reminders: value })
                      }
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option + " day" + (parseInt(option) === 1 ? "" : "s")}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="reminders"
                          variant="outlined"
                          label="Reminders"
                          placeholder="remind before"
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  minRows={12}
                  required
                  multiline
                  fullWidth
                  variant="outlined"
                  placeholder="State your reason here..."
                  value={state.reason}
                  name="reason"
                  onChange={handleChange}
                  error={!!error?.reason}
                  helperText={error?.reason}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

function SelectField({ label, name, value, onChange, items, error }) {
  return (
    <TextField
      label={label}
      name={name}
      variant="outlined"
      select
      required
      fullWidth
      value={value}
      onChange={onChange}
      error={!!error?.[name]}
      helperText={error?.[name]}
    >
      {items.map((item) => (
        <MenuItem value={item.id} key={item.id}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

function RequestButton(props) {
  const { open, setOpen, request, canSendRequestToSC, viewOnly } = props;

  return (
    <Button
      color="primary"
      variant="contained"
      style={{ margin: 10 }}
      onClick={() => setOpen(!open)}
      disabled={!!!request && viewOnly}
    >
      {request
        ? "View Request"
        : canSendRequestToSC
          ? "Request for Edit/Delete"
          : "Send request to RA"}
    </Button>
  );
}
