import { parse, format } from "date-fns";

export const compare = (a, b, field) => {
  let x = a[field];
  let y = b[field];
  if (isWholeNum(x) && isWholeNum(y)) {
    x = parseFloat(x);
    y = parseFloat(y);
  }
  if (isDate(x) && isDate(y)) {
    x = parse(x, "dd/MM/yyyy", new Date());
    y = parse(y, "dd/MM/yyyy", new Date());
  }
  if (x < y) {
    return -1;
  }
  if (x > y) {
    return 1;
  }
  return 0;
};

export const isWholeNum = (value) => {
  return /^\d+$/.test(value);
};

function isDate(value) {
  return /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value);
}

/**
 * setDefaultValues sets default value for the form fields, it only works for TOP-DOWN condition order!
 * @param value form value
 * @param conditions conditions, e.g. [{"match": "", "parent": "parentField",
 * "children": ["child1", "child2"], "defaultValue": "", "inverse": true, "defaultValueFunction": (value)=>{}]
 */
export const setDefaultValues = (value, conditions) => {
  conditions.forEach((condition) => {
    // if parent value is same as match
    if (
      condition.inverse
        ? value[condition.parent] !== condition.match
        : value[condition.parent] === condition.match
    ) {
      condition.children.forEach((c) => {
        if (!!condition.defaultValueFunction) {
          if (value.hasOwnProperty(c)) {
            if (typeof value[c] === "object") {
              value[c] = {
                ...value[c],
                text: condition.defaultValueFunction(value),
              };
            } else value[c] = condition.defaultValueFunction(value);
          }
        } else value[c] = condition.defaultValue;
      });
    }
  });
};

export const parseDate = (date) => {
  return parse(date, "dd/MM/yyyy", new Date());
};

export const castDate = (date) => {
  return format(parseDate(date), "yyyy-MM-dd");
};

export const applyCamelCase = (str) => {
  let splitStr = str.split(/\W+/g);
  if (splitStr.length < 1) return;
  let result = splitStr[0].toLowerCase();
  for (var i = 1; i < splitStr.length; i++) {
    let part = splitStr[i];
    if (part.length < 1) continue;
    result = result + part.charAt(0).toUpperCase() + part.substring(1);
  }
  return result;
};

export const roundOff = (value, decimalPoint, allowNegative = false) => {
  let floatNum = parseFloat(value);
  if (Number.isNaN(floatNum)) return value;
  if (!allowNegative && floatNum < 0) return floatNum.toString();
  floatNum = Math.round(floatNum + "e" + decimalPoint);
  return Number(floatNum + "e" + -decimalPoint)
    .toFixed(decimalPoint)
    .toString();
};

// this function will only check if all items in the state exists in storedState
export const allChangesSaved = (state, storedState) => {
  if (typeof state !== typeof storedState) {
    return false;
  }
  if (Array.isArray(state) && Array.isArray(storedState)) {
    if (state.length !== storedState.length) {
      return false;
    }
    let allMatch = true
    for (let index in state) {
      allMatch = allMatch && allChangesSaved(state[index], storedState[index]);
    }
    return allMatch;
  }

  if (typeof state === "object" && typeof storedState === "object") {
    if (storedState === undefined) {
      return !!!state;
    }
    if (state === undefined) {
      return storedState === undefined;
    }

    if (storedState === null) {
      return state === null;
    }
    if (state === null) {
      return storedState === null;
    }

    let allMatch = true;
    for (var key in state) {
      allMatch = allMatch && allChangesSaved(state[key], storedState[key]);
    }
    return allMatch;
  }
  return state === storedState; // skip empty values in state
};

export const getFacilityCodeFromState = (state) => {
  if (Array.isArray(state)) {
    for (const val of state) {
      const code = getFacilityCodeFromState(val);
      if (code !== undefined) {
        return code;
      }
    }
  }
  if (typeof state === "object") {
    for (var key in state) {
      if (state.hasOwnProperty("facilityName") && typeof state["facilityName"] === "string") {
        return state["facilityName"];
      }
      return getFacilityCodeFromState(state[key])
    }
  }
  return;
};
