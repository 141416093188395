import { setTitle, clearFormData } from "../../redux/dispatchers";
import { CustomTable } from "../common/custom-table";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useCommonAuthorization from "../../hooks/useCommonAuthorization";
import { Action, Subject } from "../../constants/permissions";

export function CountryList() {
  useEffect(() => setTitle("Country"));
  const countryCode = useSelector((state) => state.selectedCountry.selectedCountry);

  const checkRole = useCommonAuthorization();

  return (
    <CustomTable
      old
      source="country"
      defaultOrderBy="countryCode"
      noCreate={!checkRole(Subject.Country, Action.Create)}
      noSelectAll={!checkRole(Subject.Country, Action.Delete)}
      noExport={!checkRole(Subject.Country, Action.Export)}
      noSearch={!checkRole(Subject.Country, Action.Search)}
      canSelectRow={(row) => row?.permissions?.[Action.Delete] ?? false}
      getRowId={(row) => row?.countryCode}
      onClick={() => { }}
      searchBarPlaceholder="Country Code/Country Name"
      // expandFields={"expanded"}
      filters={[{ filterItem: "isDisabled", filterValue: false }, ...(countryCode !== "Global" ? [{ filterItem: "countryCode", filterValue: countryCode }] : [])]}
      fields={[
        {
          id: "countryCode",
          label: "Country Code",
        },
        {
          id: "countryName",
          label: "Country Name",
        },
        {
          id: "countryPrefix",
          label: "Country Prefix",
        },
        {
          id: "actions",
          label: "Actions",
          action: true,
          actionConfig: {
            view: {
              destination: "country",
              newTab: false,
              getViewId: (row) => {
                if (row?.permissions?.[Action.Read]) {
                  return row.countryCode;
                }
                return undefined;
              },
            },
            edit: {
              destination: "country",
              getEditId: (row) => {
                if (row?.permissions?.[Action.Update]) {
                  return row.countryCode;
                }
                return undefined;
              },
              newTab: false,
            },
            yes: {},
            no: {}
          }
        },
      ]}
      createAction={clearFormData}
    />
  );
}
