import React, { useEffect } from "react";
import { CustomScrollMenu } from "../common/custom-scroll-menu";
import { CustomTable } from "../common/custom-table";
import { ROLE_CHOICES } from "../../constants/choices";
import { setTitle } from "../../redux/dispatchers";
import { query, update, deleteOne } from "../../services/api/common";
import { showSuccessSnackbar, showErrorSnackbar } from "../../redux/dispatchers/snackbarDispatcher";
import { showModal, hideModal } from "../../redux/dispatchers/modalDispatcher";
import { useSelector } from "react-redux";
import useCommonAuthorization from "../../hooks/useCommonAuthorization";
import { Action, Subject } from "../../constants/permissions";
import { useHistory, useLocation } from "react-router-dom";

export const UserList = (props) => {

  useEffect(() => {
    setTitle("Users");
  }, []);

  const countryCode = useSelector((state) => state.selectedCountry.selectedCountry);
  const checkRole = useCommonAuthorization();

  const history = useHistory();
  const location = useLocation();

  const sectionFromURL = Number(new URLSearchParams(location.search).get("section"))
  const currentSection = isNaN(sectionFromURL) ? 0 : sectionFromURL;

  const renderComponents = (props) => {
    return ROLE_CHOICES.map((c) => ({
      label: c.name,
      contents: (
        <CustomTable
          noExport={
            !checkRole(Subject.User, Action.Export)
          }
          noCreate={!checkRole(Subject.User, Action.Create)}
          noSearch={!checkRole(Subject.User, Action.Search)}
          noSelectAll={!checkRole(Subject.User, Action.Delete)}
          canSelectRow={(row) => row?.permissions?.[Action.Delete] ?? false}
          source="users"
          defaultOrderBy="name"
          defaultOrder={1}
          onClick={() => { }}
          searchBarPlaceholder="Name"
          old
          filters={[
            {
              filterItem: "role",
              filterValue: c.id,
            },
            ...(c.id !== "sa" && countryCode !== "Global" ? [{
              filterItem: "countryCode",
              filterValue: countryCode,
            }] : [])
          ]}
          fields={[
            {
              id: "name",
              label: "Name",
            },
            {
              id: "email",
              label: "Email",
            },
            {
              id: "isDisabled",
              label: "Status",
              status: true,
              colorScheme: {
                true: "grey",
                false: "green",
              },
              tooltipText: {
                true: "Inactive",
                false: "Active",
              }
            },
            {
              id: "actions",
              action: true,
              label: "Actions",
              actionConfig: {
                view: {
                  destination: "users",
                  getViewId: (row) => {
                    if (!row?.permissions?.[Action.Read]) {
                      return undefined;
                    }
                    return row.id;
                  },
                  newTab: false
                },
                edit: {
                  destination: "users",
                  getEditId: (row) => {
                    if (!row?.permissions?.[Action.Update]) {
                      return undefined;
                    }
                    return row.id;
                  },
                  newTab: false,
                },
                yes: {
                  showWhen: [{ field: "isDisabled", value: true }],
                  getYesAction: (row) => {
                    if (!row.isDisabled || !row?.permissions?.[Action.Enable]) return undefined;
                    return (action, data, reloadData, setReloadData) => {
                      let verb = action === "approve" ? "enable" : "disable";
                      showModal({
                        type: "confirmationModal",
                        title: `${verb.toUpperCase()} User`,
                        content: `Are you sure you want to ${verb} this user? It will affect his/her access.`,
                        confirm: "Confirm",
                        cancel: "Cancel",
                        onConfirm: () => {
                          const id = data?._id ?? data?.id;
                          update(`users/${verb}`, { id })
                            .then(() => {
                              showSuccessSnackbar(`The user has been ${verb}d`);
                              if (!!setReloadData) setReloadData(!reloadData);
                            })
                            .catch((e) => showErrorSnackbar(e))
                            .finally(() => hideModal());
                        },
                      });
                    }
                  },
                  tooltip: "Enable",
                },
                no: {
                  showWhen: [{ field: "isDisabled", value: false }],
                  getNoAction: (row) => {
                    if (row.isDisabled || !row?.permissions?.[Action.Disable]) return undefined;
                    return (action, data, reloadData, setReloadData) => {
                      let verb = action === "approve" ? "enable" : "disable";
                      showModal({
                        type: "confirmationModal",
                        title: `${verb.toUpperCase()} User`,
                        content: `Are you sure you want to ${verb} this user? It will affect his/her access.`,
                        confirm: "Confirm",
                        cancel: "Cancel",
                        onConfirm: () => {
                          const id = data?._id ?? data?.id;
                          update(`users/${verb}`, { id })
                            .then(() => {
                              showSuccessSnackbar(`The user has been ${verb}d`);
                              if (!!setReloadData) setReloadData(!reloadData);
                            })
                            .catch((e) => showErrorSnackbar(e))
                            .finally(() => hideModal());
                        },
                      });
                    }
                  },
                  tooltip: "Disable",
                },
              }
            },
          ]}
          customDeleteModalAction={(selected, setSelected, setReloadData) => {
            const deleteSelectedData = () => {
              if (selected.length === 0) return;
              if (selected.length === 1) {
                deleteOne("users", selected[0])
                  .then(() => {
                    setReloadData((reloadData) => !reloadData);
                    setSelected([]);
                    showSuccessSnackbar("Successfully deleted user!");
                  })
                  .catch((e) => {
                    showErrorSnackbar(e);
                  });
              } else {
                const promises = [];
                for (let i = 0; i < selected.length; i++) {
                  promises.push(deleteOne("users", selected[i]));
                }
                Promise.all(promises)
                  .then(() => {
                    setReloadData((reloadData) => !reloadData);
                    setSelected([]);
                    showSuccessSnackbar("Successfully deleted user(s)!");
                  })
                  .catch((e) => {
                    showErrorSnackbar(e);
                  });
              }
            }
            const disableSelectedData = () => {
              if (selected.length === 0) return;
              if (selected.length === 1) {
                update("users/disable", { id: selected[0] })
                  .then(() => {
                    setReloadData((reloadData) => !reloadData);
                    setSelected([]);
                    showSuccessSnackbar("Successfully disabled user!");
                  })
                  .catch((e) => {
                    showErrorSnackbar(e);
                  });
              } else {
                const promises = [];
                for (let i = 0; i < selected.length; i++) {
                  promises.push(update("users/disable", { id: selected[i] }));
                }
                Promise.all(promises)
                  .then(() => {
                    setReloadData((reloadData) => !reloadData);
                    setSelected([]);
                    showSuccessSnackbar("Successfully disabled user(s)!");
                  })
                  .catch((e) => {
                    showErrorSnackbar(e);
                  });
              }
            }
            if (!Array.isArray(selected)) {
              showModal({
                type: "confirmationModal",
                title: "Delete Items",
                content: "Are you sure you want to delete the selected item(s)?",
                confirm: "Delete",
                cancel: "Cancel",
                onConfirm: () => {
                  deleteSelectedData();
                  hideModal();
                },
              })
            } else {
              if (selected.some((item) => typeof item === 'object')) {
                return;
              }
              const allRequests = Promise.all(selected.map((item) =>
                query(`users/delete/check/${item}`)
              ));
              allRequests.then((responses) => {
                const canDelete = responses.every((response) => response?.data?.canDelete ?? false);
                const canDisable = responses.every((response) => response?.data?.canDisable ?? false);
                if (canDelete) {
                  showModal({
                    type: "confirmationModal",
                    title: "Delete Items",
                    content: "Are you sure you want to delete the selected item(s)?",
                    confirm: "Delete",
                    cancel: "Cancel",
                    onConfirm: () => {
                      deleteSelectedData();
                      hideModal();
                    },
                  })
                } else if (canDisable) {
                  showModal({
                    type: "confirmationModal",
                    title: "Disable Users Only",
                    content: "Some of the users selected might have requests or medical records that stop you from deleting. Would you like to disable the users instead?",
                    confirm: "Disable Users",
                    cancel: "Cancel",
                    onConfirm: () => {
                      disableSelectedData();
                      hideModal();
                    },
                  })
                } else {
                  showErrorSnackbar("You are not allowed to delete this user with patients or requests.")
                }
              });
            }
          }
          }
          {...props}
        />
      ),
    }));
  };

  return <>
    <CustomScrollMenu components={renderComponents(props)} forceValue={currentSection} callback={(section) => {
      const url = new URL(window.location.href);
      url.searchParams.set("section", section);
      history.push(url.pathname + url.search);
    }} />
  </>;
};
