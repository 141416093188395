import React, { useEffect, useState } from "react";
import { setTitle } from "../../redux/dispatchers";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import Typography from "@material-ui/core/Typography";
import InfoCard from "./card-contents/info-card";
import Chart from "./card-contents/chart";
import FacilityTable from "./card-contents/facility-table";
import { query } from "../../services/api/common";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: 30,
  },
  card: {
    marginBottom: 10,
    marginTop: 10,
  },
  content: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
}));

const initialBigNumberData = {
  total: 0,
  today: 0,
  totalUntilYesterday: 0,
}

const initialAreaChartData = []

const initialTableData = []

export const Dashboard = () => {
  const classes = useStyles();

  const countryCode = useSelector((state) => state.selectedCountry.selectedCountry);

  const [patientAreaChartData, setPatientAreaChartData] = useState(initialAreaChartData);
  const [patientBigNumberData, setPatientBigNumberData] = useState(initialBigNumberData);
  const [draftAreaChartData, setDraftAreaChartData] = useState(initialAreaChartData);
  const [draftBigNumberData, setDraftBigNumberData] = useState(initialBigNumberData);
  const [facilityTableData, setFacilityTableData] = useState(initialTableData);

  useEffect(() => {
    setTitle("Dashboard");
  });

  useEffect(() => {
    let isActive = true;
    query(`patients/statistics/completed?${countryCode !== "Global" ? `countryCode=${countryCode}` : ""}`).then((data) => {
      if (isActive) setPatientBigNumberData(data.data);
    }).catch(() => {
      setPatientBigNumberData(initialBigNumberData);
    })
    query(`patients/statistics/timeseries?frequency=day&num=2&cummulative=true${countryCode !== "Global" ? `&countryCode=${countryCode}` : ""}`).then((data) => {
      if (isActive) setPatientAreaChartData(data.data);
    }).catch(() => {
      setPatientAreaChartData(initialAreaChartData);
    })
    query(`medicalrecords/statistics/draft?${countryCode !== "Global" ? `countryCode=${countryCode}` : ""}`).then((data) => {
      if (isActive) setDraftBigNumberData(data.data);
    }).catch(() => {
      setDraftBigNumberData(initialBigNumberData);
    })
    query(`medicalrecords/statistics/timeseries?frequency=day&num=2&cummulative=true${countryCode !== "Global" ? `&countryCode=${countryCode}` : ""}`).then((data) => {
      if (isActive) setDraftAreaChartData(data.data);
    }).catch(() => {
      setDraftAreaChartData(initialAreaChartData);
    })
    query(`facility/statistics?${countryCode !== "Global" ? `countryCode=${countryCode}` : ""}`).then((data) => {
      if (isActive) setFacilityTableData(data.data);
    }).catch(() => {
      setFacilityTableData(initialTableData);
    })
    return () => {
      isActive = false;
    }
  }, [countryCode])

  if (process.env.REACT_APP_DASHBOARD_CHARTS_ENABLED !== "true") {
    return (
      <div style={{ display: "flex" }}>
        <ReportProblemIcon style={{ color: "red", marginRight: 10 }} />
        <Typography>Dashboard Under Construction</Typography>
        <ReportProblemIcon style={{ color: "red", marginLeft: 10 }} />
      </div>
    );
  }

  return (
    <Grid container spacing={2} className={classes.container}>
      <InfoCard
        bigNumberData={patientBigNumberData}
        areaChartData={patientAreaChartData}
        title={"Total Number of Patients"}
        areaChartConfig={{ xKey: "date", yKey: "completed" }}
      />
      <InfoCard
        bigNumberData={draftBigNumberData}
        areaChartData={draftAreaChartData}
        title={"Total Number of Draft"}
        areaChartConfig={{ xKey: "date", yKey: "draft" }}
      />
      <Chart />
      <FacilityTable data={facilityTableData} />
    </Grid>
  );
};
