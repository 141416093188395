import { useRef, useLayoutEffect, useEffect, useState, useCallback } from "react";
import { Box } from "@mui/material";
import { useHistory, Prompt } from "react-router-dom";
import AlertDialog from "../common/alert-dialog";

export default function FormWrapper({ children, disableForm, hasUnsavedChanges = false, resetForm }) {
  const ref = useRef(null);

  const confirmedNavigation = useRef(false);
  // this state is to get the next path for the router navigation
  // we will block navigation to check for unsaved changes, therefore a need to save the desired route
  const [nextNavPath, setNextNavPath] = useState("");
  const history = useHistory();
  const [openUnsavedDialog, setOpenUnsavedAlert] = useState(false);


  const handleBlockedNavigation = (location) => {
    if (!confirmedNavigation.current) {
      setNextNavPath(location.pathname + location.search);
      setOpenUnsavedAlert(true);
      return false;
    }
    return true;
  };

  const handleAlert = useCallback((action) => {
    setOpenUnsavedAlert(false);
    // for now we have two actions enabled, discard changes and return to edit
    if (action === "discard") {
      if (nextNavPath) {
        if (typeof resetForm === 'function') resetForm();
        confirmedNavigation.current = true; // this line is very important to clear up the block and to make navigation possible
        history.replace(nextNavPath);
      }
    }
    setNextNavPath("")
    confirmedNavigation.current = false;
  }, [history, nextNavPath, resetForm]);

  // block refreshing browser or closing tabs and check for unsaved changes
  useEffect(() => {
    if (hasUnsavedChanges) {
      window.onbeforeunload = function () {
        var dialogText = "You have unsaved changes. Wish to close this page?";
        return dialogText;
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    if (!hasUnsavedChanges) {
      if (nextNavPath) {
        confirmedNavigation.current = true; // this line is very important to clear up the block and to make navigation possible
        history.push(nextNavPath);
      }
      setOpenUnsavedAlert(false);
    }
  }, [hasUnsavedChanges, history, nextNavPath])


  useLayoutEffect(() => {
    if (ref.current === null) {
      return;
    }
    let elements = ref.current.querySelectorAll(
      "input,.MuiFormGroup-root,.MuiSelect-root,.MuiTextField-root,button,.MuiButtonBase-root"
    );
    for (let element of elements) {
      if (element.tagName === "BUTTON") {
        element.style.display = disableForm ? "none" : "block";
      } else {
        element.style.pointerEvents = disableForm ? "none" : "auto";
      }
      if (disableForm) {
        if (element.tabIndex !== -1) element.setAttribute("data-tabindex", element.tabIndex);
        element.tabIndex = -1;
      } else {
        if (element.dataset.tabindex) element.tabIndex = element.dataset.tabindex;
        element.removeAttribute("data-tabindex");
      }
    }
  })

  return (
    <Box
      ref={ref}
      sx={{ width: "100%" }}
    >
      {children}
      <Prompt when={hasUnsavedChanges} message={handleBlockedNavigation} />
      <AlertDialog
        open={openUnsavedDialog}
        alertTitle="You have unsaved changes for this section!"
        alertContent="Changes will be lost if you exit this page without saving. Would you like to proceed?"
        alertActions={[
          { name: "Return to edit", callback: () => handleAlert("return") },
          {
            name: "Discard changes",
            callback: () => handleAlert("discard"),
          },
        ]}
      />
    </Box>
  );
}
