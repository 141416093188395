import { store } from "../store";
import {
  showSuccessSnackbarAction,
  showErrorSnackbarAction,
  hideSnackbarAction,
} from "../actions";

export const showSuccessSnackbar = (message) => {
  store.dispatch(showSuccessSnackbarAction(message));
};

export const showErrorSnackbar = (message) => {
  store.dispatch(showErrorSnackbarAction(message));
};

export const hideSnackbar = () => {
  store.dispatch(hideSnackbarAction());
};
