export const SET_AUTH_DETAILS = "SET_AUTH_DETAILS";
export const SET_PROFILE = "SET_PROFILE";
export const CLEAR_AUTH_DETAILS = "CLEAR_AUTH_DETAILS";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const SET_TITLE = "SET_TITLE";
export const SNACKBAR_SUCCESS = "SNACKBAR_SUCCESS";
export const SNACKBAR_ERROR = "SNACKBAR_ERROR";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";
export const SET_FORM_DATA = "SET_FORM_DATA";
export const CLEAR_FORM_DATA = "CLEAR_FORM_DATA";
export const SET_CHANGES_UNSAVED = "SET_CHANGES_UNSAVED";
export const SET_SHOW_UNSAVED_ALERT = "SET_SHOW_UNSAVED_ALERT";
export const SET_FACILITY = "SET_FACILITY";
export const SET_IS_FETCHING_FACILITY = "SET_IS_FETCHING_FACILITY";
export const CLEAR_FACILITY = "CLEAR_FACILITY";
export const SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY";
export const SET_DISABLE_SELECT_COUNTRY = "SET_DISABLE_SELECT_COUNTRY";
export const SET_FACILITY_COUNTRY = "SET_FACILITY_COUNTRY";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_MEDICAL_FORM_FACILITY = "SET_MEDICAL_FORM_FACILITY";
