import { store } from "../store";
import { hideLoadingAction, showLoadingAction } from "../actions";

export const showLoading = (message = "Loading") => {
  store.dispatch(showLoadingAction(message));
};

export const hideLoading = () => {
  store.dispatch(hideLoadingAction());
};
