import isValid from "date-fns/isValid";
import {
  SEX_CHOICES,
  EDUCATION_LEVEL_CHOICES,
  NUMBER_OF_PREGNANCY,
  ETHNIC_CHOICES,
  PAYMENT_STATUS_CHOICES,
  PREV_GDM,
  VIETNAM_ETHNIC_CHOICES,
  VIETNAM_PAYMENT_STATUS_CHOICES,
  VIETNAM_EDUCATION_LEVEL_CHOICES,
} from "../constants/demographics-choices.js";
import { parseDate, roundOff } from "../../utils/commonUtils.js";

export const globalDemographicFormConfig = [
  [
    {
      name: "dateOfHospitalVisit",
      state: "",
      label: "Date of Hospital Visit",
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
    {
      name: "dateOfBirth",
      state: "",
      label: "Date of birth",
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
    {
      name: "ageAtHospitalVisit",
      state: "",
      label: "Age at hospital visit",
      component: {
        type: "TextField",
        derivation: ({ dateOfHospitalVisit, dateOfBirth }) => {
          // calculateAge calculates the age in three decimal places
          // simple validation
          let DHV = parseDate(dateOfHospitalVisit);
          let DOB = parseDate(dateOfBirth);
          if (!isValid(DHV) || !isValid(DOB) || DHV < DOB) return -9999;

          const diff = DHV - DOB;

          // convert difference to years with decimal places
          const diffYear = diff / (1000 * 60 * 60 * 24 * 365);
          return roundOff(diffYear, 3);
        },
        props: {
          disabled: true,
        },
      },
    },
  ],
  [
    {
      name: "sex",
      state: SEX_CHOICES[0]?.id,
      label: "Sex",
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: SEX_CHOICES,
      },
    },
    {
      name: "otherSex",
      state: "",
      label: "Other Sex",
      visibility: [
        {
          name: "sex",
          value: "others",
        },
      ],
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "ethnicity",
      state: "",
      label: "Ethnicity",
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "paymentStatus",
      state: "",
      label: "Payment Status",
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "educationLevel",
      state: "",
      label: "Education level",
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "GDMHistory",
      state: "na",
      label: "GDM History",
      visibility: [
        {
          name: "sex",
          value: "female",
        },
      ],
      component: {
        type: "Radio",
        props: {
          row: true,
          required: true,
        },
        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
  ],
  [
    {
      name: "numberOfPregnancy",
      state: NUMBER_OF_PREGNANCY[0]?.id,
      label: "Number of pregnancy with GDM History",
      visibility: [
        {
          name: "GDMHistory",
          value: "yes",
        },
        {
          name: "sex",
          value: "female",
        },
      ],
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: NUMBER_OF_PREGNANCY,
      },
    },
  ],
  [
    {
      name: "prevGDMHistory",
      state: PREV_GDM[0]?.id,
      label: "How long since last GDM history?",
      visibility: [
        {
          name: "GDMHistory",
          value: "yes",
        },
        {
          name: "sex",
          value: "female",
        },
      ],
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: PREV_GDM,
      },
    },
  ],
  [
    {
      name: "insulinTreatment",
      label: "Ever treated with insulin?",
      state: "na",
      visibility: [
        {
          name: "GDMHistory",
          value: "yes",
        },
        {
          name: "sex",
          value: "female",
        },
      ],
      component: {
        type: "Radio",
        props: {
          row: true,
          required: true,
        },
        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
  ],
];

export const malaysiaDemographicFormConfig = [
  [
    {
      name: "dateOfHospitalVisit",
      state: "",
      label: "Date of Hospital Visit",
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
    {
      name: "dateOfBirth",
      state: "",
      label: "Date of birth",
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
    {
      name: "ageAtHospitalVisit",
      state: "",
      label: "Age at hospital visit",
      component: {
        type: "TextField",
        derivation: ({ dateOfHospitalVisit, dateOfBirth }) => {
          // calculateAge calculates the age in three decimal places
          // simple validation
          let DHV = parseDate(dateOfHospitalVisit);
          let DOB = parseDate(dateOfBirth);
          if (!isValid(DHV) || !isValid(DOB) || DHV < DOB) return -9999;

          const diff = DHV - DOB;

          // convert difference to years with decimal places
          const diffYear = diff / (1000 * 60 * 60 * 24 * 365);
          return roundOff(diffYear, 3);
        },
        props: {
          disabled: true,
        },
      },
    },
  ],
  [
    {
      name: "sex",
      state: SEX_CHOICES[0]?.id,
      label: "Sex",
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: SEX_CHOICES,
      },
    },
    {
      name: "otherSex",
      state: "",
      label: "Other Sex",
      visibility: [
        {
          name: "sex",
          value: "others",
        },
      ],
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "ethnicity",
      state: ETHNIC_CHOICES[0]?.id,
      label: "Ethnicity",
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: ETHNIC_CHOICES,
      },
    },
    {
      name: "otherEthnicity",
      state: "",
      label: "Other Ethnicity",
      visibility: [
        {
          name: "ethnicity",
          value: "others",
        },
      ],
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "paymentStatus",
      state: PAYMENT_STATUS_CHOICES[0]?.id,
      label: "Payment Status",
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: PAYMENT_STATUS_CHOICES,
      },
    },
  ],
  [
    {
      name: "educationLevel",
      state: EDUCATION_LEVEL_CHOICES[0]?.id,
      label: "Education level",
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: EDUCATION_LEVEL_CHOICES,
      },
    },
    {
      name: "otherEducationLevel",
      state: "",
      label: "Other Education Level",
      visibility: [
        {
          name: "educationLevel",
          value: "others",
        },
      ],
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "GDMHistory",
      state: "na",
      label: "GDM History",
      visibility: [
        {
          name: "sex",
          value: "female",
        },
      ],
      component: {
        type: "Radio",
        props: {
          row: true,
          required: true,
        },
        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
  ],
  [
    {
      name: "numberOfPregnancy",
      state: NUMBER_OF_PREGNANCY[0]?.id,
      label: "Number of pregnancy with GDM History",
      visibility: [
        {
          name: "GDMHistory",
          value: "yes",
        },
        {
          name: "sex",
          value: "female",
        },
      ],
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: NUMBER_OF_PREGNANCY,
      },
    },
  ],
  [
    {
      name: "prevGDMHistory",
      state: PREV_GDM[0]?.id,
      label: "How long since last GDM history?",
      visibility: [
        {
          name: "GDMHistory",
          value: "yes",
        },
        {
          name: "sex",
          value: "female",
        },
      ],
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: PREV_GDM,
      },
    },
  ],
  [
    {
      name: "insulinTreatment",
      label: "Ever treated with insulin?",
      state: "na",
      visibility: [
        {
          name: "GDMHistory",
          value: "yes",
        },
        {
          name: "sex",
          value: "female",
        },
      ],
      component: {
        type: "Radio",
        props: {
          row: true,
          required: true,
        },
        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
  ],
];

// VIETNAM CONFIG
export const vietnamDemographicFormConfig = [
  [
    {
      name: "dateOfHospitalVisit",
      state: "",
      label: "Date of Hospital Visit",
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
    {
      name: "dateOfBirth",
      state: "",
      label: "Date of birth",
      component: {
        type: "DateField",
        props: {
          required: true,
        },
      },
    },
    {
      name: "ageAtHospitalVisit",
      state: "",
      label: "Age at hospital visit",
      component: {
        type: "TextField",
        derivation: ({ dateOfHospitalVisit, dateOfBirth }) => {
          // calculateAge calculates the age in three decimal places
          // simple validation
          let DHV = parseDate(dateOfHospitalVisit);
          let DOB = parseDate(dateOfBirth);
          if (!isValid(DHV) || !isValid(DOB) || DHV < DOB) return -9999;

          const diff = DHV - DOB;

          // convert difference to years with decimal places
          const diffYear = diff / (1000 * 60 * 60 * 24 * 365);
          return roundOff(diffYear, 3);
        },
        props: {
          disabled: true,
        },
      },
    },
  ],
  [
    {
      name: "sex",
      state: SEX_CHOICES[0]?.id,
      label: "Sex",
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: SEX_CHOICES,
      },
    },
    {
      name: "otherSex",
      state: "",
      label: "Other Sex",
      visibility: [
        {
          name: "sex",
          value: "others",
        },
      ],
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "ethnicity",
      state: VIETNAM_ETHNIC_CHOICES[0]?.id,
      label: "Ethnicity",
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: VIETNAM_ETHNIC_CHOICES,
      },
    },
    {
      name: "otherEthnicity",
      state: "",
      label: "Other Ethnicity",
      visibility: [
        {
          name: "ethnicity",
          value: "others",
        },
      ],
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "paymentStatus",
      state: VIETNAM_PAYMENT_STATUS_CHOICES[0]?.id,
      label: "Payment Status",
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: VIETNAM_PAYMENT_STATUS_CHOICES,
      },
    },
  ],
  [
    {
      name: "educationLevel",
      state: VIETNAM_EDUCATION_LEVEL_CHOICES[0]?.id,
      label: "Education level",
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: VIETNAM_EDUCATION_LEVEL_CHOICES,
      },
    },
    {
      name: "otherEducationLevel",
      state: "",
      label: "Other Education Level",
      visibility: [
        {
          name: "educationLevel",
          value: "others",
        },
      ],
      component: {
        type: "TextField",
        props: {
          required: true,
        },
      },
    },
  ],
  [
    {
      name: "GDMHistory",
      state: "na",
      label: "GDM History",
      visibility: [
        {
          name: "sex",
          value: "female",
        },
      ],
      component: {
        type: "Radio",
        props: {
          row: true,
          required: true,
        },
        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
  ],
  [
    {
      name: "numberOfPregnancy",
      state: NUMBER_OF_PREGNANCY[0]?.id,
      label: "Number of pregnancy with GDM History",
      visibility: [
        {
          name: "GDMHistory",
          value: "yes",
        },
        {
          name: "sex",
          value: "female",
        },
      ],
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: NUMBER_OF_PREGNANCY,
      },
    },
  ],
  [
    {
      name: "prevGDMHistory",
      state: PREV_GDM[0]?.id,
      label: "How long since last GDM history?",
      visibility: [
        {
          name: "GDMHistory",
          value: "yes",
        },
        {
          name: "sex",
          value: "female",
        },
      ],
      component: {
        type: "SelectField",
        props: {
          required: true,
        },
        choices: PREV_GDM,
      },
    },
  ],
  [
    {
      name: "insulinTreatment",
      label: "Ever treated with insulin?",
      state: "na",
      visibility: [
        {
          name: "GDMHistory",
          value: "yes",
        },
        {
          name: "sex",
          value: "female",
        },
      ],
      component: {
        type: "Radio",
        props: {
          row: true,
          required: true,
        },
        choices: [
          {
            id: "yes",
            name: "Yes",
          },
          {
            id: "no",
            name: "No",
          },
          {
            id: "na",
            name: "NA",
          },
        ],
      },
    },
  ],
];
