import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 600,
    color: "#efefef",
  },
  row: {
    background: "#fcfcfc",
    "&:hover": {
      background: "#efefef",
    },
  },
}));

function FacilityTable({ data }) {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <div
            style={{
              margin: "7px",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Number of medical records by facility names
          </div>
          <TableContainer style={{ maxHeight: 400 }}>
            <Table>
              <TableHead
                style={{
                  backgroundColor: "#385aaa",
                  position: "sticky",
                  top: 0,
                }}
              >
                <TableRow>
                  <TableCell className={classes.header}>
                    Facility Name
                  </TableCell>
                  <TableCell align="center" className={classes.header}>
                    Number of Completed Medical Record(s)
                  </TableCell>
                  <TableCell align="center" className={classes.header}>
                    Number of Draft(s)
                  </TableCell>
                  <TableCell align="center" className={classes.header}>
                    Number of Archive(s)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((n) => {
                  return (
                    <TableRow key={n} className={classes.row}>
                      <TableCell>{n.facilityName}</TableCell>
                      <TableCell align="center">
                        {n["completedMedicalRecord"]}
                      </TableCell>
                      <TableCell align="center">{n["draftTotal"]}</TableCell>
                      <TableCell align="center">{n["archiveTotal"]}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default FacilityTable;
