import { SET_DISABLE_SELECT_COUNTRY, SET_FACILITY_COUNTRY, SET_SELECTED_COUNTRY } from "../types";

export const setSelectedCountryAction = (selectedCountry) => {
  return {
    type: SET_SELECTED_COUNTRY,
    payload: {
      selectedCountry
    },
  };
};

export const setDisableSelectCountryAction = (disable) => {
  return {
    type: SET_DISABLE_SELECT_COUNTRY,
    payload: {
      disable
    },
  };
};

export const setFacilityCountryAction = (facilityCountry) => {
  return {
    type: SET_FACILITY_COUNTRY,
    payload: {
      facilityCountry
    },
  };
};