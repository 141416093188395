import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { hideModal } from "../../redux/dispatchers";
import { updateRequestStatus } from "./requests-list";
import ApproveRequestDialog from "./approve-request";
import { Action } from "../../constants/permissions";
import { query } from "../../services/api/common"

const RequestDetailsDialog = ({
  data: dataFromMR,
  open,
  setOpen,
  fetchRequest,
  viewOnly,
}) => {
  const {
    type,
    visible,
    data: dataFromList,
  } = useSelector((state) => state.modal);
  const [data, setData] = useState(null);
  const [canApproveAndReject, setCanApproveAndReject] = useState(false);

  useEffect(() => {
    if (visible && type === "requestDetailsModal" && dataFromList) {
      setData(dataFromList);
    } else if (open && dataFromMR) {
      setData(dataFromMR);
    }
  }, [visible, type, dataFromList, open, dataFromMR]);

  useEffect(() => {
    if (data?._id === undefined) {
      setCanApproveAndReject(false);
      return;
    };
    query(`requests/cani/${data._id}`).then(data => data.data).then(permissions => {
      setCanApproveAndReject(permissions?.[Action.Approve] && permissions?.[Action.Deny])
    }).catch(() => {
      setCanApproveAndReject(false);
    })
  }, [data?._id])

  const handleClose = () => {
    if (visible && type === "requestDetailsModal" && dataFromList) {
      hideModal();
    } else if (open && dataFromMR) {
      setOpen(!open);
    }
  };

  const handleAction = (action) => {
    if (visible && type === "requestDetailsModal" && dataFromList) {
      const { reloadData, setReloadData, ...rest } = dataFromList;
      handleClose();
      updateRequestStatus(action, rest, reloadData, setReloadData);
    } else if (open && dataFromMR) {
      handleClose();
      updateRequestStatus(action, data, null, fetchRequest);
    }
  };

  return (
    <>
      {visible && type === "approveRequestModal" && <ApproveRequestDialog />}
      <Dialog
        open={open || (visible && type === "requestDetailsModal")}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Request</DialogTitle>
        <DialogContent dividers>
          {data && (
            <p>
              <span>
                Request Id: {data._id}
                <br />
                <br />
              </span>
              <span>
                Record Id: {data.recordId}
                <br />
                <br />
              </span>
              <span>
                Patient Code: {data.patientCode}
                <br />
                <br />
              </span>
              <span>
                Request By: {data.requestBy.name ?? data.requestBy}
                <br />
                <br />
              </span>
              <span>
                Assignee/Reviewer: {data.assignee.name ?? data.assignee}
                <br />
                <br />
              </span>
              <span>
                Request On: {new Date(data.createdAt).toLocaleString()}
                <br />
                <br />
              </span>
              {/* ! accessExpiry is not provided anymore */}
              {/* {data.status === "Approved" && (
                <span>
                  Access Expiry:{" "}
                  {new Date(data.accessExpiry).toLocaleDateString()}
                  <br />
                  <br />
                </span>
              )} */}
              <span>
                Request Type: {data.requestType}
                <br />
                <br />
              </span>
              <span>
                Status: {data.status}
                <br />
                <br />
              </span>
              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Reason</span>
              <p style={{ whiteSpace: "pre-line" }}>{data.reason}</p>
            </p>
          )}
        </DialogContent>
        {canApproveAndReject && !viewOnly && (
          <DialogActions>
            <Button onClick={() => handleAction("approve")}>Approve</Button>
            <Button onClick={() => handleAction("deny")}>Reject</Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default RequestDetailsDialog;
