import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./report-web-vitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Routers from "./components/routers/routers";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import NotoSans from "./fonts/NotoSans-Regular.ttf";
import CssBaseline from "@material-ui/core/CssBaseline";

// eslint-disable-next-line no-undef
const THEME = createTheme({
  typography: {
    fontFamily: "NotoSans, Roboto, Arial",
    fontSize: 12,
    input: {
      fontSize: 12,
    }
  },
  spacing: 4,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "NotoSans";
          src: local("NotoSans"), url(${NotoSans}) format("truetype");
        }
      `,
    },
  },
  palette: {
    primary: {
      main: "rgb(0, 92, 179)",
    },
    secondary: {
      main: "rgb(254, 0, 0)",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={THEME}>
      <CssBaseline />
      <Provider store={store}>
        <Routers />
      </Provider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// (() => {
//   query("facility").then((resp) => {
//     const facilityList = resp.data.map((facility) => {
//       if (facility.isDisabled === false) {
//         return {
//           id: facility.facilityCode,
//           name: facility.facilityName,
//         };
//       }
//     });
//     setFacility(facilityList);
//   });
// })();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
