import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { UserForm } from "../users/user-form";
import { SignIn } from "../signin/signin";
import { MainMenu } from "../main-menu/main-menu";
import { ConfirmationModal } from "../common/confirmation-modal";
import { SnackbarModal } from "../common/snackbar-modal";
import { UserList } from "../users/user-list";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";
import { ResetPassword } from "../password-reset/reset-password";
import { Dashboard } from "../dashboard/dashboard";
import { MedicalRecordForm } from "../medical-record-form/medical-record-form";
import RestrictAccess from "../restrict-access-page/RestrictAccess";
import { PatientList } from "../patients/patient-list";
import { PatientTabs } from "../patients/patient-tabs";
import MedicalRecordList from "../medical-record/medical-record-list";
import { FacilityList } from "../facility/facility-list";
import { FacilityForm } from "../facility/facility-form";
import RequestsList from "../requests/requests-list";
import ArchiveList from "../archive/archive-list";
import DraftList from "../medical-record/draft-list";
import { CountryList } from "../country/country-list";
import { CountryForm } from "../country/country-form";

import { ForgotPassword } from "../signin/forgot-password";
import { Action, Subject } from "../../constants/permissions";
import useCommonAuthorization from "../../hooks/useCommonAuthorization";
import SignOut from "../signin/signout";

function PrivateRoute({
  component: Component,
  authenticated,
  action,
  subject,
  ...rest
}) {

  const checkRole = useCommonAuthorization();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authenticated) {
          return subject === undefined || action === undefined || checkRole(subject, action) ? (
            <Component {...props} />
          ) : (
            <RestrictAccess />
          );
        } else {
          return (
            <Redirect
              to={{ pathname: "/signin", state: { from: props.location } }}
              push
            />
          );
        }
      }}
    />
  );
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        !authenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}

const Routers = () => {

  const id = useSelector((state) => state.authDetails._id);

  const authenticated = id === undefined || !!id;

  // useLocation cannot be used outside of the react router
  const isResetPassword = window.location.pathname.includes("reset");

  return (
    <Router>
      <ConfirmationModal />
      <SnackbarModal />
      <LoadingOverlay />
      <MainMenu
        showMenu={authenticated && !isResetPassword}
        component={
          <Switch>
            <PublicRoute
              path="/signin"
              component={SignIn}
              noLayout
              authenticated={authenticated}
              exact
            />
            <PublicRoute
              path="/forgot"
              component={ForgotPassword}
              noLayout
              authenticated={authenticated}
              exact
            />
            <PrivateRoute
              path="/"
              component={Dashboard}
              action="home"
              exact
              authenticated={authenticated}
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/profile"
              subject={Subject.User}
              action={Action.Read}
              component={() => <UserForm profileId={id} />}
              exact
            />
            <PublicRoute
              path="/auth/reset"
              component={ResetPassword}
              noLayout
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/users"
              component={UserList}
              subject={Subject.User}
              action={Action.Read}
              exact
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/users/create/:role"
              component={(props) => <UserForm isCreate {...props} />}
              subject={Subject.User}
              action={Action.Create}
              exact
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/users/:id"
              subject={Subject.User}
              action={Action.Read}
              component={UserForm}
              exact
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/facility"
              subject={Subject.Facility}
              action={Action.Read}
              component={FacilityList}
              exact
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/country"
              subject={Subject.Country}
              action={Action.Read}
              component={CountryList}
              exact
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/country/create"
              subject={Subject.Country}
              action={Action.Create}
              component={(props) => <CountryForm isCreate {...props} />}
              exact
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/country/:id"
              subject={Subject.Country}
              action={Action.Read}
              component={CountryForm}
              exact
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/facility/create"
              subject={Subject.Facility}
              action={Action.Create}
              component={(props) => <FacilityForm isCreate {...props} />}
              exact
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/facility/:id"
              subject={Subject.Facility}
              action={Action.Read}
              component={FacilityForm}
              exact
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/patients"
              subject={Subject.Patient}
              action={Action.Read}
              component={PatientList}
              exact
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/medicalrecord"
              component={MedicalRecordList}
              subject={Subject.Record}
              action={Action.Read}
              exact
              noLayout
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/medicalRecords/create"
              component={(props) => <MedicalRecordForm isCreate {...props} />}
              subject={Subject.Record}
              action={Action.Create}
              exact
              noLayout
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/medicalRecords/create/:id"
              component={MedicalRecordForm}
              subject={Subject.Record}
              action={Action.Create}
              exact
              noLayout
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/medicalrecord/drafts"
              component={DraftList}
              subject={Subject.Record}
              action={Action.Read}
              noLayout
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/patients/create"
              subject={Subject.Patient}
              action={Action.Create}
              component={(props) => <MedicalRecordForm isCreate {...props} />}
              noLayout
            />
            <PrivateRoute
              authenticated={authenticated}
              subject={Subject.Patient}
              action={Action.Read}
              path="/patients/:id"
              component={PatientTabs}
              exact
            />
            <PrivateRoute
              authenticated={authenticated}
              subject={Subject.Record}
              action={Action.Read}
              path="/medicalRecords/:id"
              component={MedicalRecordForm}
              exact
              noLayout
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/patients/medicalRecords/:id"
              component={(props) => <MedicalRecordForm excludeGeneral {...props} />}
              subject={Subject.Record}
              action={Action.Read}
              noLayout
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/requests"
              component={RequestsList}
              exact
              subject={Subject.Request}
              action={Action.Read}
              noLayout
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/medicalrecord/archives"
              component={ArchiveList}
              exact
              subject={Subject.Record}
              action={Action.Read}
              noLayout
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/archives/:id"
              component={MedicalRecordForm}
              exact
              subject={Subject.Record}
              action={Action.Read}
              noLayout
            />
            <PrivateRoute
              authenticated={authenticated}
              path="/signout"
              component={SignOut}
              exact
              noLayout
            />
          </Switch>
        }
      />
    </Router>
  );
}

const mapStateToProps = (state) => ({
  id: state.authDetails._id,
  role: state.authDetails.role,
});

export default connect(mapStateToProps)(Routers);
