import React from "react";
import components from "../constants/components";
import { fetchDataFromDatabase } from "../config/dynamic-config/general";

export const getFormFieldsByConfig = (
  config,
  state,
  options,
  changeHandler,
  blurEventHandler,
  clearEventHandler,
  error,
  extraContainerProps,
) => {
  return React.createElement(
    components["Grid"](),
    {
      container: true,
      spacing: 3,
      style: { marginBottom: 4, marginTop: 4 },
      ...extraContainerProps,
    },
    config.map((fieldRow) => {
      fieldRow = fieldRow.filter(
        (field) =>
          Array.isArray(field) || getVisibility(field.visibility, state)
      );
      let width = 12 / fieldRow.filter((field) => !Array.isArray(field)).length;
      return fieldRow
        .map((field) => {
          if (Array.isArray(field)) {
            return getFormFieldsByConfig(
              field,
              state,
              options,
              changeHandler,
              blurEventHandler,
              clearEventHandler,
              error,
              {
                style: { marginBottom: 1, marginLeft: 50 },
              },
            );
          }
          let { name, label, component } = field;
          component = {
            ...component,
            onChange: changeHandler,
            onBlur: blurEventHandler,
            onClear: clearEventHandler,
          };
          return React.createElement(
            components["Grid"](),
            {
              item: true,
              sm: width,
            },
            getFormComponent(name, label, component, state, options, error)
          );
        })
        .flat();
    })
  );
};

export const clearHiddenState = (config, state) => {
  config.flat().forEach((field) => {
    if (Array.isArray(field)) {
      state = clearHiddenState(field, state);
      return state;
    }
    const { name, visibility: visibilityRules } = field;
    if (!getVisibility(visibilityRules, state)) {
      state = changeStateByPath(
        name,
        state,
        ""
        // getStateByPath(name, defaultValues)
      );
    }
  });
  return state;
};

export const setDerivation = (config, state, storedState) => {
  config.flat().forEach((field) => {
    if (Array.isArray(field)) {
      state = setDerivation(field, state, storedState);
      return state;
    }
    const { name, component: { derivation } } = field;
    if (derivation !== undefined) {
      state = changeStateByPath(name, state, derivation(state, storedState));
    }
  });
  return state;
}

export const setDefaultValues = (config, state) => {
  config.flat().forEach((field) => {
    if (Array.isArray(field)) {
      state = setDefaultValues(field, state);
      return state;
    }
    const { name, visibility: visibilityRules, state: defaultValue } = field;
    if (
      getVisibility(visibilityRules, state) &&
      (getStateByPath(name, state) === undefined ||
        getStateByPath(name, state) === "")
    ) {
      let temp = defaultValue;
      if (defaultValue?.path ?? false) {
        temp = getStateByPath(defaultValue.path, state);
      }
      state = changeStateByPath(name, state, temp);
    }
  });
  return state;
};

export const getVisibility = (visibilityRules, state) => {
  if (!visibilityRules) return true;
  let result = true;
  visibilityRules.forEach((rule) => {
    let { name, operator, value } = rule;
    if (Array.isArray(value)) {
      result = result && value.includes(getStateByPath(name, state));
    } else {
      if (operator) {
        if (operator === "!==") {
          result = result && getStateByPath(name, state) !== value;
        } else if (operator === ">") {
          result =
            result && parseInt(getStateByPath(name, state)) > parseInt(value);
        } else if (operator === "<") {
          result =
            result && parseInt(getStateByPath(name, state)) < parseInt(value);
        } else if (operator === "<=") {
          result =
            result && parseInt(getStateByPath(name, state)) <= parseInt(value);
        } else if (operator === ">=") {
          result =
            result && parseInt(getStateByPath(name, state)) >= parseInt(value);
        }
      } else {
        result = result && getStateByPath(name, state) === value;
      }
    }
  });
  return result;
};

const getFormComponent = (name, label, componentMetadata, state, options, error) => {
  let { props, choices, checkboxChoices, onChange, onBlur, onBlurAction, onClear, clearable } = componentMetadata;
  if (!!choices && !Array.isArray(choices)) {
    choices = options[name] ?? []
  }
  return components[componentMetadata.type]({
    name,
    label,
    choices,
    checkboxChoices,
    onChange,
    onBlur: !!onBlur ? (event) => onBlur(event, onBlurAction) : () => { },
    onClear,
    clearable,
    value: getStateByPath(name, state) ?? "",
    error: !!getStateByPath(name, error),
    helperText: getStateByPath(name, error),
    ...props,
  });
};

export const getUseEffectObject = (
  config,
  state,
  storedState,
  useEffectObject = {},
  requireChange = false
) => {
  config.flat().forEach((field) => {
    if (Array.isArray(field)) {
      let nestedUseEffectObject = getUseEffectObject(
        field,
        state,
        storedState,
        useEffectObject,
        requireChange
      );
      requireChange = requireChange || nestedUseEffectObject;

      return;
    }
    const { component = {}, name, visibility: visibilityRules } = field;
    const { derivation } = component;
    if (!derivation) return;
    if (!getVisibility(visibilityRules, state)) return;
    const result = derivation(state, storedState) || "";
    requireChange = requireChange || result !== getStateByPath(name, state);
    useEffectObject = {
      ...useEffectObject,
      [name]: result,
    };
  });

  if (requireChange) return useEffectObject;
  return;
};

export const getStateObjectByConfig = (config, originalState = {}, storedState = {}, state = {}) => {
  let finalState = copyState(state);
  config.flat().forEach((field) => {
    if (Array.isArray(field)) {
      finalState = getStateObjectByConfig(field, originalState, storedState, finalState);
      return finalState;
    }
    let { name: statePath, state: stateValue } = field;
    let finalStateValue = getStateByPath(statePath, originalState);
    if (finalStateValue === undefined || stateValue === fetchDataFromDatabase()) finalStateValue = getStateByPath(statePath, storedState);
    if (finalStateValue === undefined) finalStateValue = stateValue;
    finalState = changeStateByPath(statePath, finalState, finalStateValue);
  });
  return finalState;
};

const copyState = (state) => {
  return JSON.parse(JSON.stringify(state));
};

export const changeStateByPath = (path, state, newValue, copy = true) => {
  const statePathTokens = path.split(".");
  let resultState;
  if (copy) resultState = copyState(state);
  else resultState = state;
  // let resultState = state;
  let targetState = resultState;
  var i;
  for (i = 0; i < statePathTokens.length - 1; i++) {
    const key = statePathTokens[i];
    if (!targetState.hasOwnProperty(key)) {
      targetState[key] = {};
    }
    targetState = targetState[key];
  }
  let oldValue = targetState[statePathTokens[i]];
  if (oldValue && typeof oldValue === "object") {
    if (newValue === "") {
      targetState[statePathTokens[i]] = newValue;
    } else {
      targetState[statePathTokens[i]][newValue] =
        !targetState[statePathTokens[i]][newValue];
    }
  } else {
    targetState[statePathTokens[i]] = newValue;
  }
  return resultState;
};

export const getStateByPath = (path, state) => {
  if (!state) return;
  const statePathTokens = path.split(".");
  let targetState = state;
  var i;
  for (i = 0; i < statePathTokens.length; i++) {
    if (targetState === undefined) {
      return;
    }
    const key = statePathTokens[i];
    if (!targetState.hasOwnProperty(key)) {
      return;
    }
    targetState = targetState[key];
  }
  return targetState;
};

export const getValidationSchemaFromConfig = (config, schema = {}) => {
  config.flat().forEach((field) => {
    if (Array.isArray(field)) {
      schema = getValidationSchemaFromConfig(field, schema);
    } else {
      const { name: statePath, validation } = field;
      if (!validation) return;
      schema = changeStateByPath(statePath, schema, validation, false);
    }
  });
  return schema;
};

export const getValidationSchemaOneLayer = (config) => {
  let schema = {};
  config
    .flat()
    .forEach(
      ({ name: statePath, validation }) => (schema[statePath] = validation)
    );
  return schema;
};
