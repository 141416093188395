import { mixed, object, string } from '../../yup';

const LAB_SELECTION = ['nil', 'trace', '+', '2+', '3+', 'na'];

function dateWithTextFieldValidation(decimalPoint) {
  return object({
    text: string()
      .required()
      .numberRangeCheck()
      .decimalPointCheck(decimalPoint),
    date: mixed().when('text', {
      is: (text) => parseFloat(text) >= 0,
      then: () => string().required().checkDate(),
      otherwise: () => string().length(0),
    }),
  });
}

export const labsValidationSchema = {
  fastingPlasmaGlucose: dateWithTextFieldValidation(1),
  hba1c: object({
    text: string()
      .required()
      .test(
        'negativeValue',
        `Only value between 0 and 100 / -9999 are allowed`,
        (num) =>
          (Number(num) >= 0 && Number(num) <= 100) || Number(num) === -9999,
      )
      .decimalPointCheck(1),
    date: mixed().when('text', {
      is: (text) => parseFloat(text) >= 0,
      then: () => string().required().checkDate(),
      otherwise: () => string().length(0),
    }),
  }),
  totalCholesterol: dateWithTextFieldValidation(2),
  triglyceride: dateWithTextFieldValidation(2),
  hdlCholesterol: dateWithTextFieldValidation(2),
  ldlCholesterol: dateWithTextFieldValidation(2),
  nonHDLCholesterol: dateWithTextFieldValidation(2),
  serumSodium: dateWithTextFieldValidation(1),
  serumPotassium: dateWithTextFieldValidation(1),
  serumUrea: dateWithTextFieldValidation(1),
  serumCreatinine: dateWithTextFieldValidation(1),
  eGFR: dateWithTextFieldValidation(2),
  serumAlbumin: dateWithTextFieldValidation(1),
  serumTotalBilirubin: dateWithTextFieldValidation(1),
  serumALP: dateWithTextFieldValidation(1),
  serumALT: dateWithTextFieldValidation(1),
  serumAST: dateWithTextFieldValidation(1),
  serumGGT: dateWithTextFieldValidation(1),
  urineProtein: object({
    select: string().required().oneOf(LAB_SELECTION),
    date: mixed().when('select', {
      is: (select) => select !== 'na',
      then: () => string().required().checkDate(),
      otherwise: () => string().length(0),
    }),
  }),
  urineProteinCreatinineIndex: dateWithTextFieldValidation(2),
  urineAlbuminToCreatinineRatio: dateWithTextFieldValidation(2),
  haemoglobin: dateWithTextFieldValidation(1),
  haematocrit: dateWithTextFieldValidation(2),
  totalWhiteBloodCells: dateWithTextFieldValidation(2),
  neutrophils: dateWithTextFieldValidation(2),
  lymphocytes: dateWithTextFieldValidation(2),
  platelet: dateWithTextFieldValidation(0),
};
