import { SET_FORM_DATA, CLEAR_FORM_DATA } from "../types";

export const setFormDataAction = (tabName, dataObj) => {
  return {
    type: SET_FORM_DATA,
    payload: {
      tabName,
      dataObj,
    },
  };
};

export const clearFormDataAction = () => {
  return {
    type: CLEAR_FORM_DATA,
  };
};
